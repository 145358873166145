import {createContext, PropsWithChildren, useContext} from 'react'
import {LibraryStore} from './LibraryStore'


const LibraryContext = createContext<LibraryStore>({} as LibraryStore)

interface LibraryProviderProps extends PropsWithChildren<{}> {
    value: LibraryStore
}

const LibraryProvider = ({children, value}: LibraryProviderProps) => {
    return (
        <LibraryContext.Provider value={value}>
            {children}
        </LibraryContext.Provider>
    )
}

export const useLibraryStore = () => useContext(LibraryContext)

export default LibraryProvider
