import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import Modal from 'react-bootstrap/Modal'
import HourlyMaxSensitivity from './HourlyMaxSensitivity'
import Button from 'react-bootstrap/Button'
import SensitivityVarIcon from 'components/project/pages/EnergyMarket/SensitivityVarIcon'
import { SensitivityVar } from 'components/project/model/SensitivityVar'
import NumberInput from 'components/helper/NumberInput'
import { Validation } from 'components/helper/validation'


interface HourlyMaxViewProps {
    model: SensitivityVar
    id: string
    useCommaAsDecimalPoint: boolean
    allocationType: AllocationType
}


const HourlyMaxView = observer(({ id, model, useCommaAsDecimalPoint, allocationType }: HourlyMaxViewProps) => {
    const [show, setShow] = useState(false)
    const handleOpen = () => setShow(true)
    const handleClose = () => setShow(false)
    const label = allocationType == 'Storage' ? 'Maximum Storage Commitment (%)' : allocationType == 'Wind' ? 'Wind Turbine Allocation (%)' : 'PV Allocation (%)'

    return (<>
        <div className='form-group row align-items-center'>
            <div className='col-5'>{label}</div>
            <div className='col-3'>
                <NumberInput useCommaAsDecimalPoint={useCommaAsDecimalPoint} value={model.values[0].value}
                    onChange={x => model.values[0].setValue(x)} validator={Validation.percent}/>
            </div>
            <div className='col-2'>
                <SensitivityVarIcon count={model.values.length} onClick={handleOpen} />
            </div>
        </div>
        <Modal id={id} show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header>
                <Modal.Title>{`${allocationType} Hourly Max Sensitivity`}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='k-overflow-x-auto'>
                <HourlyMaxSensitivity model={model} allocationType={allocationType} />
            </Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={handleClose}>Save &amp; Close</Button>
            </Modal.Footer>
        </Modal>
    </>)
})


export default HourlyMaxView

export type AllocationType = 'Storage' | 'Wind' | 'PV'
