import { observer } from "mobx-react-lite"
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ContextHelp from "../helper/ContextHelp";
import NumberInput from "../helper/NumberInput";
import StringInput from "../helper/StringInput";
import { Validation } from "../helper/validation";
import { UserStorageClass } from "./UserStorage"
import { TemperatureCapacityChart } from "./UserStorageChart";
import StorageCurveTable from "./UserStorageCurveTable";

interface StorageFuncModelTabProps {
    model: UserStorageClass
    useCommaAsDecimalPoint: boolean
}

const StorageLibraryTempCapacityTab = observer(({ model, useCommaAsDecimalPoint }: StorageFuncModelTabProps) => {
    const [ignoreCapacityChangesWithTemp, setIgnoreCapacityChangesWithTemp] = useState(model.ignoreCapacityVsTemperatureInModel)
    return (
        <>
            <Row>
                <Form.Check className='col-4 ml-3 mt-2' custom label='Ignore Capacity Changes with Temperature' id='ignoreCapacityChangesWithTemp'
                    checked={model.ignoreCapacityVsTemperatureInModel}
                    onChange={ev => { model.setUserStorageIgnoreCapacityVsTemperatureInModel(ev.target.checked); setIgnoreCapacityChangesWithTemp(ev.target.checked) }} />
            </Row>
            <Row className="mt-2 d-flex flex-column align-items-center justify-content-center">
                <h4><b>Relative Capacity versus Temperature</b></h4>
            </Row>
            <Row>
                <Col xs={3}>
                    <StorageCurveTable disabled={ignoreCapacityChangesWithTemp} model={model.capacityTemperatureCurve} useCommaAsDecimalPoint={useCommaAsDecimalPoint} setItem={model.setUserStorageCapacityTemperatureCurveItem} deleteItem={model.userStorageCapacityTemperatureCurveDeleteItem} addItem={model.userStorageCapacityTemperatureCurveAddRow} columnHead1="Capacity (% of Nominal)" columnHead2="Temperature (C)" percentage={true} />
                </Col>
                <Col xs={5}>
                    <TemperatureCapacityChart model={model.capacityTemperatureCurve} />
                </Col>
                <Col xs={4}>
                    <p><b>Parameters Fit from Data:</b></p>
                    <p>Capacity(T) = NominalCapacity * (d0 + d1*T + d2*T^2)</p>
                    <Row>
                        <label className="col-7 mt-2">Fitted d0:</label> 
                        <NumberInput disabled={ignoreCapacityChangesWithTemp} value={model.fittedD0}
                            onChange={model.setUserStorageFittedD0}
                            className="mt-1 col-2 mr-4"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)} />
                        <ContextHelp helpId='USERLIBRARYTEMPCAPACITY' />
                    </Row>
                    <Row>
                        <label className="col-7 mt-2">Fitted d1:</label>
                        <NumberInput disabled={ignoreCapacityChangesWithTemp} value={model.fittedD1}
                            onChange={model.setUserStorageFittedD1}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)}/>
                    </Row>
                    <Row>
                        <label className="col-7 mt-2">Fitted d2:</label>
                        <NumberInput disabled={ignoreCapacityChangesWithTemp} value={model.fittedD2}
                            onChange={model.setUserStorageFittedD2}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)}/>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-1 d-flex">

            </Row>
        </>
    )
})

export default StorageLibraryTempCapacityTab
