import {observer} from 'mobx-react-lite'
import Form from 'react-bootstrap/Form'
import NumberInput from 'components/helper/NumberInput'
import MultiYearView from 'components/project/pages/SharedComponents/MultiYearView'
import {ColorRect} from 'components/helper/ColorRect'
import ScheduleTimeGrid, {EditMode} from 'components/project/capacityMarket/ScheduleGridView'
import TimeSeriesView from 'components/helper/TimeSeriesView'
import React, {useState} from 'react'
import ContextHelp from 'components/helper/ContextHelp'
import ButtonAdd from 'components/helper/ButtonAdd'
import ButtonDelete from 'components/helper/ButtonDelete'
import TimeSeriesImport from 'components/helper/TimeSeriesImport'
import {useProjectStore} from 'components/project/ProjectProvider'
import {Contract} from 'components/project/model/Contract'
import ToolTipInfo from '../../../helper/ToolTipInfo'
import { RateSchedule } from '../../model/Schedule'
import RateScheduleEditor from './RateScheduleEditor'
import { DeleteComponentState } from '../equipment/shared/DeleteConfirmationModal'
import { GoX } from 'react-icons/go'


interface ContractPriceScheduleProps {
    model: Contract
    onDelete: () => void
}

const ContractPriceSchedule = observer(({model, onDelete}: ContractPriceScheduleProps) => {
    const store = useProjectStore()
    const intlCulture = store.project!.useCommaAsDecimalPoint ? 'es' : 'en'
    const handleImport = async (data: number[], year: number) => {
        model.setScheduleData(data, year)
        await store.saveProject()
    }

    return (
        <div className='border rounded p-2 mb-2'>
        <div className='row'>
            <div className='col-12'>
                <div className='d-flex mb-2 justify-content-between'>
                    <h5>Energy Price Schedule</h5>
                    <ContextHelp helpId='PRICESCHEDULE' />
                        <button className='btn btn-outline-danger pillButton' onClick={() => onDelete()}><GoX /></button>
                </div>
            </div>

            <div className='col-md-12 col-xl-3'>
                <div className='row'>
                    <div className='col-md-3 col-xl-12 mb-4'>
                        <Form.Check type='radio' id={`energy-price-schedule-${model.id}`} custom
                                    checked={model.energyPriceKind === 'schedule'}
                                    onChange={ev => ev.target.checked && model.setEnergyPriceKind('schedule')}
                                    label='Define 12×24 energy price'/>
                        <Form.Check type='radio' id={`energy-price-import-${model.id}`} custom
                                    checked={model.energyPriceKind === 'import'}
                                    onChange={ev => ev.target.checked && model.setEnergyPriceKind('import')}
                                    label='Import annual price profile'/>
                        <div className='ml-4 mt-2'>
                            <div className='d-flex mb-2'>
                                <TimeSeriesImport onSelect={handleImport}/>
                                <div className='pl-1 mt-2'>
                                    <ContextHelp helpId='ANNUALPRICEPROFILE'/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-9 col-xl-12'>
                        <MultiYearView model={model.energyPriceEscalator}
                                       label='Price Escalator (%/yr)'
                                       id={`contractPriceEscalator-${model.id}`}/>
                    </div>
                </div>
                <div className='d-flex mt-4'>
                <Form.Check type='checkbox' id={`tod-energy-market-alternative`} custom
                checked={model.competeWithWholesaleMarket}
                onChange={ev => model.setCompeteWithWholesaleMarket(ev.target.checked)}
                label='Time of Delivery may be elected if more advantageous than the last market'
                disabled={model.obligationKind !== 'none'} />
            {model.obligationKind !== 'none' && <ToolTipInfo label={'TOD can only compete with last market if Percent contracted capacity is selected'} />}  
                </div>
                </div>

            <div className='col-md-12 col-xl-9'>
                <div className='border rounded p-2 mb-2'>
                    {model.energyPriceKind === 'schedule' &&
                        <RateScheduleEditor model={model.energyPriceSchedule} isPriceSchedule={true} />}
                    {model.energyPriceKind === 'import' &&
                        <TimeSeriesView model={model.energyPriceTimeSeries} intlCulture={intlCulture} />}
                </div>
            </div>
            </div>
        </div>
    )
})


export default ContractPriceSchedule
