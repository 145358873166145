import {RouteComponentProps} from '@reach/router'
import ResultSolarEnergyProductionChart from 'components/project/pages/Results/ResultSolarEnergyProductionChart'
import ResultSolarEnergyTable from './ResultSolarEnergyTable'
import {observer} from 'mobx-react-lite'
import {SolarResult} from 'components/project/results-details'
import {Project} from 'components/project/model'
import ResultSolarEnergyMetricTable from 'components/project/pages/Results/ResultSolarEnergyMetricTable'
import ResultSolarEnergyProductionByMonthChart from 'components/project/pages/Results/ResultSolarEnergyProductionByMonthChart'
import ContextHelp from 'components/helper/ContextHelp'
import DropDownSelector from '../SharedComponents/DropDownSelector'
import { useState } from 'react'

interface ResultSolarEnergyProps extends RouteComponentProps {
    result: SolarResult
    model: Project
    showBaseYearAsYearZero: boolean
}

const ResultSolarEnergy = observer(({ result, model, showBaseYearAsYearZero }: ResultSolarEnergyProps) => {
    const intlCulture = model.useCommaAsDecimalPoint ? 'es' : 'en'
    const dateFormat = model.dateFormat

    const [index, setIndex] = useState<number>(0)
    return (<>
        <div className='mx-auto' />
        <div className='d-flex flex-row align-items-center m-1 ml-4 mr-4 mt-20' >
            <h3 className='mr-4'>{model.solar[index].customName != undefined && model.solar[index].customName.length > 0 ? model.solar[index].customName : 'Solar PV'}</h3>
            <DropDownSelector max={model.solar.length} value={index} onValueChange={setIndex} showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={model.baseYear} />
        </div>
        <div className='row m-2'/>
        <div className='row m-2'>
            <div className='col-6'>
                <div className='row'>
                    <div className='col-12'>
                        <ResultSolarEnergyProductionChart items={result.pvs[index].items} showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={model.baseYear} />
                    </div>
                    <div className='col-12'>
                        <ResultSolarEnergyProductionByMonthChart values={result.pvs[index].totalProductionByMonth}/>
                    </div>
                </div>
            </div>
            <div className='col-6'>
                <ResultSolarEnergyMetricTable intlCulture={intlCulture} result={result.pvs[index]} model={model} dateFormat={dateFormat} />
            </div>
        </div>
        <div className='row m-2'>
            <div className='col-1 offset-11 mb-1'>
                <ContextHelp helpId='SOLARTABLE' />
            </div>
            <div className='col-12'>
                <ResultSolarEnergyTable intlCulture={intlCulture} items={result.pvs[index].items} showBaseYearAsYearZero={showBaseYearAsYearZero} project={model} />
            </div>
        </div>
    </>)
})

export default ResultSolarEnergy
