import NumberInput from 'components/helper/NumberInput'
import {ValidationFunc} from 'components/helper/validation'


interface NumberFieldProps {
    value: number
    onChange: (x: number) => void
    label: string
    validator?: ValidationFunc
    disabled?: boolean
    id?: string
    className?: string
    useCommaAsDecimalPoint: boolean
}


const NumberField = ({ label, value, onChange, id, disabled, className, validator, useCommaAsDecimalPoint }: NumberFieldProps) => {
    return (
        <div className='form-group row align-items-center'>
            <div className='col-5'>{label}</div>
            <div className='col-3'>
                <NumberInput value={value}
                    onChange={onChange}
                    validator={validator}
                    disabled={disabled}
                    id={id} className={className}
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            </div>
        </div>
    )
}


export default NumberField
