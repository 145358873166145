import {observer} from 'mobx-react-lite'
import {CostSensitivityItem, SimpleCostTable} from '../../../model/CostTable'
import './sensitivityView.scss'
import NumberInput from 'components/helper/NumberInput'
import ButtonDelete from 'components/helper/ButtonDelete'
import ButtonAdd from 'components/helper/ButtonAdd'
import { useProjectStore } from 'components/project/ProjectProvider'


interface SensitivityViewProps {
    model: SimpleCostTable

    showCapital?: boolean
    showOperating?: boolean
    showReplacement?: boolean
    showAugmentation?: boolean

    capitalLabel?: string
    operatingLabel?: string
    replacementLabel?: string
    augmentationLabel?: string

    capitalUnits?: string
    operatingUnits?: string
    replacementUnits?: string
    augmentationUnits?: string

    useCommaAsDecimalPoint: boolean
}


const SensitivityView = observer(({
                                      model,
                                      showCapital, showOperating, showReplacement, showAugmentation,
                                      capitalLabel, operatingLabel, replacementLabel, augmentationLabel,
                                      capitalUnits, operatingUnits, replacementUnits, augmentationUnits,
                                      useCommaAsDecimalPoint
                                  }: SensitivityViewProps) => {
    const handleAdd = () => model.addNewItem()
    const handleDelete = (id: string) => model.removeItem(id)
    const currency = useProjectStore().project!.currencySymbol ?? "$"

    return (
        <table className='table-bordered table-hover table-sm p-2'>
            <thead>
                <tr>
                    <th/>
                    {model.items.map(item =>
                        <th key={item.id}>
                            <ButtonDelete onClick={() => handleDelete(item.id)}/>
                        </th>)}
                    <th><ButtonAdd onClick={handleAdd}/></th>
                </tr>

                <CostRow useCommaAsDecimalPoint={useCommaAsDecimalPoint} show={showCapital ?? true} items={model.items}
                         label={`${capitalLabel ?? 'Capital Expenses'} (${capitalUnits ?? currency + '/kW'})`}
                         getter={item => item.capital}
                         setter={item => item.setCapital.bind(item)}/>

                <CostRow useCommaAsDecimalPoint={useCommaAsDecimalPoint} show={showOperating ?? true} items={model.items}
                    label={`${operatingLabel ?? 'Operating Expenses'} (${operatingUnits ?? currency + '/kW-yr'})`}
                         getter={item => item.operating}
                         setter={item => item.setOperating.bind(item)}/>

                <CostRow useCommaAsDecimalPoint={useCommaAsDecimalPoint} show={showReplacement ?? false} items={model.items}
                    label={`${replacementLabel ?? 'Replacement'} (${replacementUnits ?? currency + '/kW'})`}
                         getter={item => item.replacement}
                         setter={item => item.setReplacement.bind(item)}/>

                <CostRow useCommaAsDecimalPoint={useCommaAsDecimalPoint} show={showAugmentation ?? false} items={model.items}
                    label={`${augmentationLabel ?? 'Augmentation'} (${augmentationUnits ?? currency + '/kW'})`}
                         getter={item => item.augmentation}
                         setter={item => item.setAugmentation.bind(item)}/>
            </thead>
        </table>
    )
})

export default SensitivityView


interface CostRowProps {
    show: boolean
    label: string
    items: CostSensitivityItem[]
    getter: (item: CostSensitivityItem) => number
    setter: (item: CostSensitivityItem) => ((x: number) => void)
    useCommaAsDecimalPoint: boolean
}

const CostRow = observer(({ show, label, items, getter, setter, useCommaAsDecimalPoint }: CostRowProps) => {
    if (!show) return null
    return (
        <tr>
            <td>{label}</td>
            {items.map(item =>
                <td key={item.id} className='table-w-1 p-0 position-relative'>
                    <NumberInput useCommaAsDecimalPoint={useCommaAsDecimalPoint} value={getter(item)}
                                 onChange={setter(item)}
                                 className='h-100 border-0 rounded-0'/>
                </td>)}
        </tr>
    )
})
