export interface Range {
    min: number
    max: number
}

export const makeRange = (min?: number, max?: number): Range => ({min: min ?? 0, max: max ?? 0})

export const defaultRange = (): Range => makeRange(0, 1)

export const ensureBounds = (r: Range, bounds: Range): Range => {
    const l = r.max - r.min
    if (l > bounds.max - bounds.min)
        return {...bounds}
    if (r.min < bounds.min)
        return makeRange(bounds.min, bounds.min + l)
    if (r.max > bounds.max)
        return makeRange(bounds.max - l, bounds.max)
    return {...r}
}

export const ensureMinLength = (r: Range, minLength: number): Range => {
    const length = r.max - r.min
    const center = (r.min + r.max) / 2
    return length < minLength ?
        makeRange(center - minLength / 2, center + minLength / 2) :
        {...r}
}

export const round = (r: Range): Range => {
    return makeRange(Math.round(r.min), Math.round(r.max))
}

export const zoomIn = (r: Range): Range => {
    const length = r.max - r.min
    const center = (r.min + r.max) / 2
    const newLength = length / 2
    return makeRange(center - newLength / 2, center + newLength / 2)
}

export const zoomOut = (r: Range): Range => {
    const length = r.max - r.min
    const center = (r.min + r.max) / 2
    const newLength = length * 2
    return makeRange(center - newLength / 2, center + newLength / 2)
}

export const panLeft = (r: Range): Range => {
    const length = r.max - r.min
    return makeRange(r.min - length, r.max - length)
}

export const panRight = (r: Range): Range => {
    const length = r.max - r.min
    return makeRange(r.min + length, r.max + length)
}
