import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartSeries, ChartSeriesItem, ChartSeriesLabels, ChartValueAxis, ChartValueAxisItem } from '@progress/kendo-react-charts';
import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { OstDimension, OstPlane } from '../../results';

interface ResultOstModalProps {
    show: boolean
    onCancel?: () => void
    xPosition: number
    yPosition: number
    planes: OstPlane[]
    dimensions: OstDimension[]
}

interface NpvItem {
    label: string
    color: string
    data: number
}

interface SensitivityItem {
    label: string[]
    component: string
    data: string[]
}

const ResultOstModal = ({ show, onCancel, xPosition, yPosition, planes, dimensions }: ResultOstModalProps) => {
    //divide x by 2 because plane is 800x400 but data is 400x400
    var x = (xPosition / 2) | 0
    var y = yPosition | 0
    var npvs: NpvItem[] = []
    var sensitivities: SensitivityItem[] = []

    const formatNumber = (x: number): string => {
        return Number.isInteger(x) ? x.toLocaleString().toString() : x.toLocaleString(undefined, { minimumFractionDigits: 0, maximumFractionDigits: 1 })
    }

    planes.map(item => {
        const npv: NpvItem = { label: item.name, data: Math.round(item.data[x][y] * 100) / 100, color: item.color }
        npvs.push(npv)
    })
    dimensions.map(item => {
        if (item.isXAxis || item.isYAxis) {
            var points: string[] = []
            var names: string[] = []
            var owner = item.components[0]
            for (let i = 0; i < item.points[0].length; i++) {
                var clickedValue = item.isXAxis ? x : y
                var minPoint = item.points[0][i]
                var maxPoint = item.points[item.points.length - 1][i]
                var interpolatedPoint = item.isXAxis ? (((maxPoint - minPoint) * clickedValue) / 400) + minPoint : (((maxPoint - minPoint) * clickedValue) / 400) + minPoint
                points.push(formatNumber(interpolatedPoint))

                names.push(`${item.displayNames[i]} (${item.units[i]})`)
            }
            sensitivities.push({ label: names, component: owner, data: points })
        }
    })

    return (
        <Modal show={show} onHide={onCancel} size='lg'>
            <Modal.Header closeButton onHide={onCancel}>
                <Modal.Title>Interpolated Values</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='form-group row align-items-center flex'>
                    <div className='col-12 align-center'>
                        {sensitivities.map(i =>
                            <div>{`${i.component != "" ? i.component + " - " : ""}${i.label.join(', ')}: ${i.data.join(', ')}`}</div>
                        )}
                        <hr className='bg-dark' />
                        <div className='d-flex flex-row'>
                            <div className='flex-grow-1'>
                                <Chart style={{ height: 300, width: 780 }} axisDefaults={{ labels: { format: "{C2}" } }}>
                                    <ChartValueAxis>
                                        <ChartValueAxisItem visible={false} majorGridLines={{ visible: false }} />
                                    </ChartValueAxis>
                                    <ChartCategoryAxis >
                                        <ChartCategoryAxisItem labels={{ visible: false }} majorGridLines={{ visible: false }} line={{ visible: true }} majorTicks={{ visible: true }} />
                                    </ChartCategoryAxis>
                                    <ChartSeriesLabels/>
                                    <ChartSeries>
                                        <ChartSeriesItem type='bar' name='' data={npvs} labels={{ visible: true, format: "{0:N}", position: "insideBase" }} markers={{ visible: true }} categoryField='label' field='data' />
                                    </ChartSeries>
                                </Chart>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
};

export default ResultOstModal;
