import {observer} from 'mobx-react-lite'
import {useProjectStore} from 'components/project/ProjectProvider'
import ContractExportObligations from 'components/project/pages/revenue/ContractExportObligations'
import {Contract} from 'components/project/model/Contract'
import { RateSchedule } from '../../model/Schedule'
import ContractPriceSchedule from './ContractEnergyPrice'
import { DeleteComponentState } from '../equipment/shared/DeleteConfirmationModal'

interface CapacityMarketCollectionProps {
    onDelete: (componentToDelete: DeleteComponentState) => void
}

const ContractCollection = observer(({ onDelete }: CapacityMarketCollectionProps) => {
    const project = useProjectStore().project!
    const onDeleteContract = () => {
        onDelete({ kind: 'TOD Contract', id: '' })
    }
    return (<>
        {project.contracts.map(x =>
            <ContractView key={x.id} model={x} schedule={x.energyPriceSchedule} onDelete={onDeleteContract} />)}
    </>)
})

export default ContractCollection


interface ContractViewProps {
    model: Contract
    schedule: RateSchedule
    onDelete: () => void
}


const ContractView = observer(({model, schedule, onDelete}: ContractViewProps) => {
    return (<>
        <ContractPriceSchedule model={model} onDelete={onDelete} />
        <div className='my-3'/>
        <ContractExportObligations model={model}/>
    </>)
})
