import React, { useEffect, useState } from 'react'
import { Button, Modal, Table, Spinner, Form } from 'react-bootstrap'
import api from 'services/api'
import { GoX } from 'react-icons/all'
import MultiFilePicker from './MultiFilePicker'
import { useProjectStore } from 'components/project/ProjectProvider'
import { MultiYearImportStatus } from '../project/model/MultiYearTimeSeries'


interface MultiYearTimeSeriesImportProps {
    onSelect?: (sourceList: string[], data: number[][], projectYearsValid: boolean[][], year: number) => Promise<MultiYearImportStatus>
    label?: string
    disabled?: boolean
    visible?: boolean
    show: boolean
    setShow: (x: boolean) => void
    state: MultiYearImportState
    setState: (state: MultiYearImportState) => void
    priceData: MultiYearPriceData[]
    setPriceData: (priceData: MultiYearPriceData[]) => void
    handleDeleteFile: (id: number) => void
    handleEdit: (projectYearsValid: boolean[][]) => void
    showBaseYearAsYearZero: boolean
}

const MultiYearTimeSeriesImport = ({ onSelect, label, disabled, show, setShow, state, setState, priceData, setPriceData, handleDeleteFile, handleEdit, showBaseYearAsYearZero, visible = true }: MultiYearTimeSeriesImportProps) => {
    const open = () => setShow(true)
    const close = () => {
        setShow(false)
        setPriceData([])
        setState({ kind: 'blank' })
    }
    const handleSelect = async (sourceList: string[], data: number[][], projectYearsValid: boolean[][], year: number): Promise<MultiYearImportStatus> => {
        const status = await onSelect?.(sourceList, data, projectYearsValid, year)
        if (status && status.success === true)
            close()
        return status ?? { success: false, message: '' }
    }

    return (<>
        {visible && <button className={`btn btn-file btn-primary pillButton ${disabled ? 'disabled' : ''} ${visible ? 'visible="true"' : ''}`}
            onClick={open}>
            {label ?? 'Import...'}
        </button>}
        <MultiTimeSeriesImportModal show={show} state={state} setState={setState} onSelect={handleSelect} priceData={priceData} setPriceData={setPriceData} onCancel={close} handleDeleteFile={handleDeleteFile} handleEdit={handleEdit} showBaseYearAsYearZero={showBaseYearAsYearZero} />
    </>)
}

export default MultiYearTimeSeriesImport

export type MultiYearImportState
    = { kind: 'blank' }
    | { kind: 'selected', year?: number }
    | { kind: 'importing', year?: number }
    | { kind: 'editing', year?: number }
    | { kind: 'error', message: string, year?: number }

export type MultiYearPriceData = { source: string, content: string, priceMultiplier?: number, projectYearsValid: boolean[] }

interface MultiTimeSeriesImportModalProps {
    show: boolean
    onSelect: (sourceList: string[], data: number[][], projectYearsValid: boolean[][], year: number) => Promise<MultiYearImportStatus>
    onCancel: () => void
    state: MultiYearImportState
    setState: (state: MultiYearImportState) => void
    priceData: MultiYearPriceData[]
    setPriceData: (priceData: MultiYearPriceData[]) => void
    handleDeleteFile: (id: number) => void
    handleEdit: (projectYearValid: boolean[][]) => void
    showBaseYearAsYearZero: boolean
}

const MultiTimeSeriesImportModal = ({show, onSelect, state, setState, priceData, setPriceData, onCancel, handleDeleteFile, showBaseYearAsYearZero, handleEdit }: MultiTimeSeriesImportModalProps) => {
    const store = useProjectStore()
    const project = store.project!
    const lifetime = project.lifetime
    var yearMap: number[] = []
    for (var i = 0; i < lifetime; i++) {
        yearMap.push(i)
    }
    //useEffect(() => setState({ kind: 'blank' }), [show])
    useEffect(() => setPageRender(!pageRender), [show])

    const [pageRender, setPageRender] = useState<boolean>(false)

    const handleSelect = (contentList: string[], data: File[], keepExistingFiles?: boolean) => {
        if (state.kind != 'editing')
            setState({ kind: 'selected' })

        var tempPriceData: MultiYearPriceData[] = []

        //keep existing files
        if (keepExistingFiles)
            tempPriceData = priceData

        //inserting new files
        if (data != undefined && data.length > 0) {
            for (let i = 0; i < data.length; i++) {
                var source = data[i].name
                var content = contentList[i]
                var projectYearsValid: boolean[] = []
                for (var z = 0; z < lifetime; z++) {
                    projectYearsValid.push(false)
                }
                var file: MultiYearPriceData = { source, content, projectYearsValid }
                tempPriceData.push(file)
            }
        }
        setPriceData(tempPriceData)
        setPageRender(!pageRender)
    }
    const handleChecked = (checked: boolean, fileIndex: number, yearIndex: number) => {
        var tempPriceData = priceData

        if (checked) {
            if (priceData != undefined) {
                for (var i = 0; i < priceData.length; i++) {
                    tempPriceData[i].projectYearsValid[yearIndex] = false
                }
                tempPriceData[fileIndex].projectYearsValid[yearIndex] = true
            }
        }
        else
            tempPriceData[fileIndex].projectYearsValid[yearIndex] = false
        setPriceData(tempPriceData)
        setPageRender(!pageRender)
    }

    const onDeleteFile = (id: number) => {
        handleDeleteFile(id)
        setPageRender(!pageRender)
    }
    const handleImport = async () => {
        if ((state.kind == 'error' && priceData.length < 1) || state.kind == 'blank') return
        try {
            const currentYear = new Date().getFullYear();
            setState({ kind: 'importing' })
            if (priceData != undefined && priceData.length > 0) {
                var checkedYears = 0
                for (let i = 0; i < priceData.length; i++) {
                    checkedYears += priceData[i].projectYearsValid.filter(Boolean).length
                }
                if (checkedYears != lifetime) {
                    setState({ kind: 'error', message: 'one or more years have not being assigned a price strip file.' })
                    return
                }

                var sourceList: string[] = []
                var content: number[][] = []
                var projectYearsValid: boolean[][] = []
                var numErrors: number = 0
                for (let i = 0; i < priceData.length; i++) {
                    var r = await api.import(priceData[i].content, state.year)
                    if (!r.success) {
                        console.error(r.message)
                        numErrors += 1
                    }
                    else {
                        sourceList.push(priceData[i].source)
                        content.push(r.data)
                        projectYearsValid.push(priceData[i].projectYearsValid)
                        setState({ kind: 'importing', year: r.year })
                    }
                }
                if (numErrors > 0) {
                    var newPriceData = priceData.filter(item => sourceList.includes(item.source));
                    setState({ kind: 'error', message: 'one or more files were not in the correct format and were removed from the list.', year: state.year })
                    setPriceData(newPriceData)
                    return
                }
                if (sourceList.length > 0)
                    onSelect(sourceList, content, projectYearsValid, state.year != undefined ? state.year : currentYear)
            }
            else {
                setState({ kind: 'blank' })
            }
        } catch (error) {
            console.error(error)
            setState({ kind: 'error', message: `Service unavailable` })
            return
        }
    }

    //const handleSetYear = (x?: number) => {
    //    if (state.kind !== 'selected') return
    //    setState({ ...state, year: x })
    //}

    return (
        <Modal scrollable={true} show={show} onHide={onCancel} size='lg'>
            <Modal.Header closeButton onHide={onCancel}>
                <Modal.Title>Import File</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <div className='d-flex flex-row align-items-center'>
                    {priceData.length < 1 &&
                        <MultiFilePicker onSelect={handleSelect} keepExistingFiles={false} label='Open...' />
                    }
                </div>
                {priceData.length < 1 &&
                    <div className='mx-2' />
                }
                {(state.kind === 'selected' || state.kind === 'importing' || state.kind === 'error' || state.kind === 'editing') && priceData != undefined && priceData.length > 0 &&
                    <div className='d-flex flex-row align-items-center'>
                        <Table borderless={true}>
                            <thead>
                                <tr>
                                    <th text-align="right">Years</th>
                                {(state.kind === 'selected' || state.kind === 'importing' || state.kind === 'error' || state.kind === 'editing') && priceData != undefined && priceData.length > 0 &&
                                        yearMap.map((x, index) => <React.Fragment key={index}>
                                            <th><strong>{showBaseYearAsYearZero ? project.baseYear + x : x + 1}</strong></th>
                                        </React.Fragment>)}
                                    <th>Delete File</th>
                                </tr>
                            </thead>
                        {(state.kind === 'selected' || state.kind === 'importing' || state.kind === 'error' || state.kind === 'editing') && priceData != undefined && priceData.length > 0 &&
                                priceData.map((x, fileIndex) => <React.Fragment key={fileIndex}>
                                    <tbody>
                                        <tr>
                                            <td><strong>{x.source}</strong></td>
                                            {priceData != undefined && priceData.length > 0 && x.projectYearsValid != undefined &&
                                                x.projectYearsValid.map((y, yearIndex) => <React.Fragment key={yearIndex}>
                                                    <td><strong><Form.Check className='pt-1'
                                                        type='checkbox'
                                                        checked={y}
                                                        onChange={ev => handleChecked(ev.target.checked, fileIndex, yearIndex)} /></strong></td>
                                                </React.Fragment>)}
                                            <td><button className='btn p-0 pillButton' onClick={() => onDeleteFile(fileIndex)}><GoX /></button></td>
                                        </tr>
                                    </tbody>
                                </React.Fragment>)}
                        </Table>
                    </div>}
                {/*{(state.kind === 'selected' || state.kind === 'importing' || state.kind === 'editing') &&*/}
                {/*    <div className='mt-2'>*/}
                {/*        <YearSelector label='Year (optional)' value={state.year}*/}
                {/*            onValueChanged={handleSetYear} min={2000} max={2050} />*/}
                {/*    </div>}*/}
                {state.kind === 'error' &&
                    <div className='mt-2 text-danger'>Import error: {state.message}</div>}
            </Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={onCancel} variant='outline-primary'>Cancel</Button>
                {priceData.length > 0 && <React.Fragment>
                    <MultiFilePicker onSelect={handleSelect} keepExistingFiles={false} label='Cancel and Import Again' />
                    <MultiFilePicker onSelect={handleSelect} keepExistingFiles={true} label='Add File' />
                    </React.Fragment>
                }
                <Spinner hidden={state.kind !== 'importing'} animation='border' size='sm' className='text-primary' />
                <Button className='pillButton' onClick={handleImport} disabled={state.kind !== 'selected' && priceData.length < 1}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}
