import { observer } from 'mobx-react-lite'
import { useProjectStore } from 'components/project/ProjectProvider'
import MultiYearView from 'components/project/pages/SharedComponents/MultiYearView'
import React, { useEffect, useState } from 'react'
import { Dropdown, Modal, Button, Alert, Form } from 'react-bootstrap'
import TimeSeriesView from 'components/helper/TimeSeriesView'
import { GoX } from 'react-icons/all'
import { IntlService } from 'Kendo-Intl-5'
import ContextHelp from 'components/helper/ContextHelp'
import { EnergyMarket, EnergyMarketKind, getEnergyMarketKindLabel } from 'components/project/model/EnergyMarket'
import HourlyMaxView from 'components/project/pages/equipment/shared/HourlyMaxView'
import MultiYearTimeSeriesImport, { MultiYearImportState, MultiYearPriceData } from 'components/helper/MultiYearTimeSeriesImport'
import ToolTipInfo from 'components/helper/ToolTipInfo'
import TimeSeriesImport from 'components/helper/TimeSeriesImport'
import DropDownSelector from 'components/project/pages/SharedComponents/DropDownSelector'
import { MultiYearImportStatus, MultiYearTimeSeries } from 'components/project/model/MultiYearTimeSeries'
import { TimeSeries } from '../../model/TimeSeries'
import api from '../../../../services/api'
import { DeleteComponentState } from '../equipment/shared/DeleteConfirmationModal'
import NumberInput from '../../../helper/NumberInput'
import { Validation } from '../../../helper/validation'
//import SuccessImportModal from '../equipment/shared/ImportSuccessModal'
import { getMarketType } from '../../../../services/inputs'

interface EnergyMarketCollectionProps {
    onDelete: (componentToDelete: DeleteComponentState) => void
}

const EnergyMarketCollection = observer(({ onDelete }: EnergyMarketCollectionProps) => {
    const project = useProjectStore().project!
    const showBaseYearAsYearZero = api.showBaseYearAsYearZero
    const single = project.energyMarkets.length <= 1

    const [showModel, setShowModel] = useState(false)
    const handleAdd = (key: string | null) => {
        if (!key) return
        project.addNewEnergyMarket(key as EnergyMarketKind)
        project.addCommitments(`${getEnergyMarketKindLabel(key as EnergyMarketKind)} energy market`)
    }
    const deleteEnergyMarket = (x: EnergyMarket) => {
        if (project.useCapacityMarket && project.capacityMarkets[0].energyMarket?.name && x.kind === project.capacityMarkets[0].energyMarket?.kind) {
            setShowModel(true)
        }
        else {
            onDelete({ kind: 'Energy Market', id: x.id })
        }
    }

    return (
        <div className='d-flex'>
            <div className={single ? 'w-100' : 'w-75'}>
                {project.energyMarkets.map((x, index) => <React.Fragment key={x.id}>
                    {index > 0 && <hr className='bg-dark' />}
                    <div className='my-2' id={x.id}>
                        <EnergyMarketView model={x} onDelete={() => { deleteEnergyMarket(x as EnergyMarket); project.removeCommitments(`${getEnergyMarketKindLabel(x.kind)} energy market`) }} showBaseYearAsYearZero={showBaseYearAsYearZero} />
                    </div>
                </React.Fragment>)}

                {project.canAddEnergyMarket &&
                    <>
                        <hr className='bg-dark' />
                        <div className='d-flex flex-column align-items-center my-4'>
                            <Dropdown onSelect={handleAdd}>
                                <Dropdown.Toggle className='pillButton' variant='outline-primary' id='new-incentive'>Add another energy market</Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {project.availableEnergyMarkets.map(x =>
                                        <Dropdown.Item key={x} eventKey={x}>{getEnergyMarketKindLabel(x)}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </>}
            </div>
            <CapacityReferenceErrorModal show={showModel} onCancel={() => { setShowModel(false) }} />
        </div>
    )
})


export default EnergyMarketCollection


interface EnergyMarketProps {
    model: EnergyMarket
    onDelete?: () => void
    showBaseYearAsYearZero: boolean
}


const EnergyMarketView = observer(({ model, onDelete, showBaseYearAsYearZero }: EnergyMarketProps) => {
    const store = useProjectStore()
    const project = store.project!
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    const intl = new IntlService(intlCulture)
    const currency = store.project?.currencySymbol ?? "$"
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint

    const [priceData, setPriceData] = useState<any[]>([])
    const [state, setState] = useState<MultiYearImportState>({ kind: 'blank' })
    const [show, setShow] = useState<boolean>(false)
    const [index, setIndex] = useState<number>(0)

    const handleMultiSelect = async (sourceList: string[], data: number[][], projectYearsValid: boolean[][], year: number): Promise<MultiYearImportStatus> => {
        model.multiYearPrice = []
        let marketType = getMarketType(model.kind)
        await api.deleteInputTimeSeries(store.project!.id, marketType)
        for (let i = 0; i < data.length; i++) {
            const saveTimeseries = await api.addInputTimeSeries(store.project!.id, marketType, i, data[i])
            if (saveTimeseries.success) {
                var priceData = new MultiYearTimeSeries()
                priceData.setData(data[i], year, sourceList[i], projectYearsValid[i], i)
                model.multiYearPrice.push(priceData)
                model.multiYearPriceReady = true
                //model.singleFileMultiYearPriceReady = false
                //model.singleFileMultiYearPrice = []
            }
            else {
                model.multiYearPriceReady = false
                return { success: false,  message: 'Error saving price files' }
            }
        }
        await store.saveProject()
        return { success: true, message: '' }
    }
    const handleSelect = async (data: number[], year: number, fileName: string): Promise<MultiYearImportStatus> => {
        model.setData(data, year)
        model.setPriceName(fileName)
        await store.saveProject()
        return { success: true, message: '' }
    }
    const handleDeleteFile = (id: number) => {
        var newPriceData = priceData
        newPriceData.splice(id, 1)
        setPriceData(newPriceData)
    }
    const handleEdit = () => {
        setState({ kind: 'editing', year: model.multiYearPrice[0].price.year })
        var tempPriceData: MultiYearPriceData[] = []
        model.multiYearPrice.forEach(item => {
            const source = item.timeSeriesFileBaseName
            var content = ''
            item.price.data.forEach(value => { content += value.toString() + '\n' })
            const projectYearsValid: boolean[] = []
            item.projectYearsValid.forEach(item => {
                projectYearsValid.push(item)
            })
            var file: MultiYearPriceData = { source, content, projectYearsValid }
            tempPriceData.push(file)
        })
        setPriceData(tempPriceData)
        setShow(true)
    }
    const handleEditYears = async (projectYearsValid: boolean[][]) => {
        for (let i = 0; i < projectYearsValid.length; i++)
            model.multiYearPrice[i].projectYearsValid = projectYearsValid[i]
        await store.saveProject()
    }

    const handleMultiImportSelect = async (sourceList: string[], data: number[][], projectYearsValid: boolean[][], year: number) => {
        model.multiYearImportPrice = []
        for (let i = 0; i < data.length; i++) {
            var priceData = new MultiYearTimeSeries()
            priceData.setData(data[i], year, sourceList[i], projectYearsValid[i])
            model.multiYearImportPrice.push(priceData)
        }
        await store.saveProject()
    }
    const handleImportSelect = async (data: number[], year: number, fileName: string) => {
        model.setImportData(data, year)
        model.setImportPriceName(fileName)
        await store.saveProject()
    }

    return (<>
        <div className='row p-2 ml-1 mr-1 mt-1 mb-2 border rounded'>
            <div className='d-flex align-items-center col-12 pb-2'>
                <h5 className='my-0'>{model.name}</h5>
                <div className='mx-1' />
                <ContextHelp helpId='ENERGY_MARKET' />
                <div className='m-auto' />
                <button className='btn btn-outline-danger pillButton' onClick={() => onDelete?.()}><GoX /></button>
            </div>
            <div className='col-12 pb-2'>{`Energy Price (${currency}/MWh)`}</div>
            <div className='form-group row align-items-center no-gutters ml-3'>
                <div className='mr-3'>
                    <Form.Check custom label='Single Price Strip' id={`singleStrip-${model.kind}`} type='radio'
                        disabled={project.earlyAnalysis}
                        checked={!model.useMultiYearPrice}
                        onChange={ev => { model.setUseMultiYearPrice(ev.target.checked ? false : true) }} />
                </div>
                <div className='mr-3'>
                    <Form.Check custom label='Multiple Price Strips' id={`multiStrip-${model.kind}`} type='radio'
                        disabled={project.earlyAnalysis}
                        checked={model.useMultiYearPrice}
                        onChange={ev => { model.setUseMultiYearPrice(ev.target.checked ? true : false) }} />
                </div>
                {project.earlyAnalysis && <ToolTipInfo label={'Disabled for early-stage analysis, because only a single year is simulated'} />}
            </div>
            <div className='d-flex align-items-center col-12 pb-2'>
                <TimeSeriesImport onSelect={handleSelect} visible={!model.useMultiYearPrice} />
                <MultiYearTimeSeriesImport onSelect={handleMultiSelect} state={state} visible={model.useMultiYearPrice} show={show} setShow={setShow} setState={setState} priceData={priceData} setPriceData={setPriceData} handleDeleteFile={handleDeleteFile} handleEdit={handleEditYears} showBaseYearAsYearZero={showBaseYearAsYearZero} />
                <div className='mx-1' />
                <ContextHelp helpId='TIME_SERIES_IMPORT' />
                <div className='d-flex align-items-center m-1 ml-5 mr-4 mt-20' >
                    <label hidden={model.useMultiYearPrice === false || model.multiYearPrice === undefined || model.multiYearPrice.length < 2} className="ml-1 mr-2">Price Strip</label>
                    <DropDownSelector max={model.multiYearPrice.length} value={index} onValueChange={setIndex} hidden={!model.useMultiYearPrice} showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={project.baseYear} />
                    <button className={`btn btn-file btn-outline-primary ml-3 pillButton`}
                        hidden={model.multiYearPrice === undefined || model.multiYearPrice.length < 1 || !model.useMultiYearPrice}
                        onClick={handleEdit}>Edit Import
                    </button>
                </div>
            </div>
            <div className='row' />
            <div className='col-6 pb-2 d-flex flex-column align-items-center'>
                <TimeSeriesView intlCulture={intlCulture} model={model.useMultiYearPrice ? model.multiYearPrice.length > 0 && model.multiYearPrice[index].price !== undefined && model.multiYearPrice[index].price.data.length > 0 ? model.multiYearPrice[index].price : new TimeSeries() : model.price !== undefined && model.price.data !== undefined && model.price.data.length > 0 ? model.price : new TimeSeries()} chartTitle={(model.useMultiYearPrice ? model.multiYearPrice.length >= index + 1 && model.multiYearPrice[index] !== undefined && model.multiYearPrice[index].timeSeriesFileBaseName !== undefined && model.multiYearPrice[index].timeSeriesFileBaseName !== '' ? model.multiYearPrice[index].timeSeriesFileBaseName : 'Energy Price ' + index : model.priceName)} yAxisTitle={`${currency}/MWh`} />
                <div className='d-flex flex-row mt-2'>
                    <span className='font-weight-bold'>{`Average Price (${currency}/MWh): ${intl.formatNumber(model.priceAverage.singleValue, 'n2')}`}</span>
                    <div className='mx-1' />
                    <ContextHelp helpId='ENERGY_MARKET_PRICE_AVERAGE' />
                </div>
            </div>
            <div className='col-6 pb-2'>
                <MultiYearView model={model.priceEscalator} label={`Energy Price Escalator (%/yr)`}
                    id={'price-escalator-' + model.id} helpId='ENERGY_MARKET_PRICE_ESCALATOR' />
                <HourlyMaxView model={model.pvAllocationSensitivity} id={`${model.id}_pv`} useCommaAsDecimalPoint={useCommaAsDecimalPoint} allocationType={'PV'} />
                <HourlyMaxView model={model.windAllocationSensitivity} id={`${model.id}_wind`} useCommaAsDecimalPoint={useCommaAsDecimalPoint} allocationType={'Wind'} />
                <HourlyMaxView model={model.maxStorageCommitmentSensitivity} id={`${model.id}_storage`} useCommaAsDecimalPoint={useCommaAsDecimalPoint} allocationType={'Storage'} />
            </div>
        </div>
        <div className='row p-2 ml-1 mr-1 mb-2 border rounded'>
            <div className='d-flex mb-2 ml-1 p-2 justify-content-start'>
                <Form.Check className='mr-2 ml-1' custom label='Include additional charges for Energy Imports' id={`use_separate_prices_for_${model.id}`} type='switch'
                    checked={model.hasSeparateImportPrice}
                    onChange={ev => { model.setHasSeparateImportPrice(ev.target.checked); }} />
                <ContextHelp helpId={'IMPORTPRICE'} />
            </div>
            {model.hasSeparateImportPrice && <>
                {model.useImportPriceMultiplier && <div className='d-flex align-items-center col-12 pb-2'>
                    <div className='d-flex flex-column align-items-center m-2'>
                        <div className='d-flex flex-row align-items-center'>
                            <div className='mr-2'>Import Energy Charge (%)</div>
                            <div style={{ width: '80px' }}>
                                <NumberInput id={model.id + '-import_multiplier'} value={model.importPriceMultiplier} validator={Validation.min(0, true)} onChange={x => model.setImportPriceMultiplier(x)}
                                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </div>
                        </div>
                    </div></div>}
            </>}
        </div>
    </>
    )
})

type OperationType = 'import' | 'export' | 'both'

interface CapacityReferenceErrorModalProps {
    show: boolean
    onCancel?: () => void
}


const CapacityReferenceErrorModal = ({ show, onCancel }: CapacityReferenceErrorModalProps) => {
    const [visible, setVisible] = useState(show)
    useEffect(() => { setVisible(show) }, [show])

    const handleCancel = () => {
        setVisible(false)
        onCancel?.()
    }
    return (
        <Modal show={visible} onHide={handleCancel} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body><Alert variant="danger">Capacity market references an energy market which does not exist</Alert></Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' variant='secondary' onClick={handleCancel}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
}
