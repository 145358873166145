import {action, makeObservable, observable, runInAction} from 'mobx'
import api, {ProjectInfo} from '../../services/api'

export class LibraryStore {
    constructor() { makeObservable(this) }

    @observable projects: ProjectInfo[] = []

    @action
    async fetchProjects() {
        this.projects = []
        const rv = await api.list()
        runInAction(() => {
            this.projects = rv
        })
    }
}
