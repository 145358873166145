import {Form} from 'react-bootstrap'
import {useProjectStore} from 'components/project/ProjectProvider'
import SensitivityVarView from 'components/project/pages/EnergyMarket/SensitivityVarView'
import {observer} from 'mobx-react-lite'
import TimeSeriesView from 'components/helper/TimeSeriesView'
import TimeSeriesImport from 'components/helper/TimeSeriesImport'


const ResourceView = observer(() => {
    const store = useProjectStore()
    const project = store.project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'

    const handleSelectSolar = async (data: number[], year: number, fileName: string) => {
        project.solarResource.setData(data, year, true)
        project.setSolarResourceName(fileName)
        await store.saveProject()
    }

    const handleSelectTemperature = async (data: number[], year: number, fileName: string) => {
        project.temperatureResource.setData(data, year, false)
        project.setTemperatureResourceName(fileName)
        await store.saveProject()
    }

    return (
        <div className='d-flex flex-column'>
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column col-4'>
                    <h6>GHI Data Selection:</h6>
                    <div>
                        <Form.Check custom label='Use NREL/NASA monthly GHI averages' id='solar-library' type='radio'
                                    checked={project.solarResource.kind === 'library'}
                                    onChange={ev => ev.target.checked && project.solarResource.setKind('library')}/>
                    </div>
                    <div>
                        <Form.Check custom label='Upload annual GHI timeseries' id='solar-timeseries' type='radio'
                                    checked={project.solarResource.kind === 'timeseries'}
                                    onChange={ev => ev.target.checked && project.solarResource.setKind('timeseries')}/>
                    </div>
                </div>

                {project.solarResource.kind === 'timeseries' && <>
                    <div className='col-2'>
                        <TimeSeriesImport onSelect={handleSelectSolar}/>
                    </div>
                    <div className='col-6'>
                        <SensitivityVarView model={project.solarResource.scale} label='Annual Average Solar GHI (kWh/m2/day) ' units='kWh/m2/day' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>
                </>}
            </div>
            {project.solarResource.data.state === 'ready' && project.solarResource.kind === 'timeseries' && <div className='col-12'>
                <TimeSeriesView model={project.solarResource.data} chartTitle={project.solarResourceName} intlCulture={intlCulture} />
            </div>}
            <div className='my-1'/>

            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column col-4'>
                    <h6>Temperature Data Selection:</h6>
                    <div>
                        <Form.Check custom label='Use NASA monthly temperature averages' id='temperature-library' type='radio'
                                    checked={project.temperatureResource.kind === 'library'}
                                    onChange={ev => ev.target.checked && project.temperatureResource.setKind('library')}/>
                    </div>
                    <div>
                        <Form.Check custom label='Upload annual temperature timeseries' id='temperature-timeseries' type='radio'
                                    checked={project.temperatureResource.kind === 'timeseries'}
                                    onChange={ev => ev.target.checked && project.temperatureResource.setKind('timeseries')}/>
                    </div>
                </div>

                {project.temperatureResource.kind === 'timeseries' && <>
                    <div className='col-2'>
                        <TimeSeriesImport onSelect={handleSelectTemperature}/>
                    </div>
                    <div className='col-6'>
                        <SensitivityVarView model={project.temperatureResource.scale} label='Annual Average Temperature (°C) ' units='°C' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>
                </>}
            </div>
            {project.temperatureResource.data.state === 'ready' && project.temperatureResource.kind === 'timeseries' &&
                <div className='col-12'>
                    <TimeSeriesView model={project.temperatureResource.data} chartTitle={project.temperatureResourceName} intlCulture={intlCulture} />
                </div>}
        </div>
    )
})

export default ResourceView
