export type ValidationFunc = (value: number) => string[]

export class Validation {
    static all(...params: ValidationFunc[]): ValidationFunc {
        return (value: number) => {
            return params.flatMap(f => f(value))
        }
    }

    static min(x: number, strict?: boolean): ValidationFunc {
        return (value: number) => {
            return strict ?
                (x < value ? [] : [`The value must be > ${x}`]) :
                (x <= value ? [] : [`The value must be ≥ ${x}`])
        }
    }

    static max(x: number, strict?: boolean): ValidationFunc {
        return (value: number) => {
            return strict ?
                (x > value ? [] : [`The value must be < ${x}`]) :
                (x >= value ? [] : [`The value must be ≤ ${x}`])
        }
    }

    static range(min: number, max: number, strictMin?: boolean, strictMax?: boolean, onlyIntegers?: boolean): ValidationFunc {
        return (value: number) => {
            if (onlyIntegers && !Number.isInteger(value)) {
                return ['The value must be an integer']
            }
            if (strictMin && strictMax)
                return (min < value && value < max) ? [] : [`The value must be > ${min} and < ${max}`]
            if (strictMin && !strictMax)
                return (min < value && value <= max) ? [] : [`The value must be > ${min} and ≤ ${max}`]
            if (!strictMin && strictMax)
                return (min <= value && value < max) ? [] : [`The value must be ≥ ${min} and < ${max}`]
            if (!strictMin && !strictMax)
                return (min <= value && value <= max) ? [] : [`The value must be ≥ ${min} and ≤ ${max}`]
            return []
        }
    }

    static get percent(): ValidationFunc {
        return (value: number) => {
            return 0 <= value && value <= 100 ? [] : ['The values must be between 0% and 100%']
        }
    }

    static get percentAbs(): ValidationFunc {
        return (value: number) => {
            return -100 <= value && value <= 100 ? [] : ['The values must be between -100% and 100%']
        }
    }

    static get integer(): ValidationFunc {
        return (value: number) => {
            return Number.isInteger(value) ? [] : ['The value must be an integer']
        }
    }
}
