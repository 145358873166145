import { action, makeObservable, observable, runInAction } from "mobx";
import api, { StorageCurveItem, StorageSpec } from "../../services/api";
import { ComplexCostTableDto, ConverterDto, CostTableDto, deserializeSensitivityVar, SensitivityVarDto, serializeSensitivityVar, SimpleCostTableDto, SizingKind, SizingVarDto } from "../../services/inputs";
import { ComplexCostItem, CostTable, CostTableType, CostUnit } from "../project/model/CostTable";
import { Inverter } from "../project/model/Inverter";
import { SensitivityItem, SensitivityVar } from "../project/model/SensitivityVar";

export class UserStorage {
    constructor() { makeObservable(this) }

    @observable specs: UserStorageClass | null = null

    @action async getUserStorage(id: string) {
        var spec = await api.getUserStorage(id!)
        runInAction(() => {
            this.specs = spec
        })
    }

}

export class UserStorageClass {
    constructor() { makeObservable(this) }

    @observable id: string = ""
    @observable name: string = ""
    @observable abbreviation: string = ""
    @observable nominalCapacity: number = 0 // kWh
    @observable nominalPower: number = 0 // kW
    @observable maximumCapacity: number = 0 // Ah
    @observable nominalVoltage: number = 0 // V
    @observable chargeCurrent: number = 0 // A
    @observable rangeOfCharge: number = 0 // %
    @observable minimumStateOfCharge: number = 0 // % 
    @observable estimatedLifetimeThroughput: number = 0 // kWh
    @observable lifetimeCurve: StorageCurveItem[] = []
    @observable chemistry: string = "" 
    @observable manufacturer: string = "" 
    @observable requiresOneMinuteTimestep: boolean = false
    @observable rateConstant: number = 0
    @observable capacityRatio: number = 0
    @observable effectiveSeriesResistance: number = 0
    @observable fittedD0: number = 0
    @observable fittedD1: number = 0
    @observable fittedD2: number = 0
    @observable fittedA: number = 0
    @observable fittedBeta: number = 0
    @observable arrheniusDegredationB: number = 0
    @observable arrheniusDegredationD: number = 0
    @observable capacityDegradationLimitForFitCyclelifetimeModel: number = 0
    @observable notes: string = ""
    @observable initialStateOfCharge: number = 0
    @observable useDedicatedConverter: boolean = false
    @observable capacityCurve: StorageCurveItem[] = []
    @observable useLifetimeCurve: boolean = false
    @observable applyMaxDischargeCurrent: boolean = false
    @observable applyMaxChargeCurrent: boolean = false
    @observable applyMaxChargeRate: boolean = false
    @observable maxDischargeCurrent: number = 0
    @observable maxChargeCurrent: number = 0
    @observable maxChargeRate: number = 0
    @observable otherLosses: number = 0
    @observable additiveEndOfLifeCalculation: boolean = false
    @observable degradationUsesNominalSoc: boolean = false
    @observable ignoreCapacityVsTemperatureInModel: boolean = false
    @observable capacityTemperatureCurve: StorageCurveItem[] = []
    @observable ignoreTemperatureEffectOnLifetime: boolean = false
    @observable simpleLifetimeInYears: number = 0
    @observable stringSize: number = 0
    @observable lifetimeTemperatureCurve: StorageCurveItem[] = []
    @observable maximumOperatingTemperature: number = 0
    @observable minimumOperatingTemperature: number = 0
    @observable mass: number = 0
    @observable fixedTemperature: number = 0
    @observable thermalH: number = 0
    @observable thermalCSpecific: number = 0
    @observable augmentationPrice: number = 0
    @observable augmentationDegradationLimitSensitivity: SensitivityVar = new SensitivityVar(0)
    @observable capacityDegradationLimitForFitLifetimeTemperatureModel: number = 0
    @observable dedicatedConverter: Inverter = new Inverter()
    @observable applyMinimumLifetime: boolean = false
    @observable minimumLifetime: number = 0
    @observable isAc: boolean = false
    @observable useFixedTemperature: boolean = false
    @observable costTable: CostTable = new CostTable()

    @action.bound setUserStorageName(x: string) { this.name = x }
    @action.bound setUserStorageAbbreviation(x: string) { this.abbreviation = x }
    @action.bound setUserStorageManufacturer(x: string) { this.manufacturer = x }
    @action.bound setUserStorageChemistry(x: string) { this.chemistry = x }
    @action.bound setUserStorageNotes(x: string) { this.notes = x }
    @action.bound setUserStorageRequiresOneMinuteTimestep(x: boolean) { this.requiresOneMinuteTimestep = x }
    @action.bound setUserStorageNominalVoltage(x: number) { this.nominalVoltage = x }
    @action.bound setUserStorageUseMaxChargeRate(x: boolean) { this.applyMaxChargeRate = x }
    @action.bound setUseDedicatedConverter(x: boolean) { this.useDedicatedConverter = x }
    @action.bound setUserStorageUseMaxChargeCurrent(x: boolean) { this.applyMaxChargeCurrent = x }
    @action.bound setUserStorageUseMaxDischargeCurrent(x: boolean) { this.applyMaxDischargeCurrent = x }
    @action.bound setUserStorageMaxChargeRate(x: number) { this.maxChargeRate = x }
    @action.bound setUserStorageMaxChargeCurrent(x: number) { this.maxChargeCurrent = x }
    @action.bound setUserStorageMaxDischargeCurrent(x: number) { this.maxDischargeCurrent = x }
    @action.bound setUserStorageAdditiveEndOfLifeCalculation(x: boolean) { this.additiveEndOfLifeCalculation = x }
    @action.bound setUserStorageDegradationUsesNominalSoc(x: boolean) { this.degradationUsesNominalSoc = x }
    @action.bound userStorageLifetimeCurveAddRow() { this.lifetimeCurve.push({ x: 0, y: 0 }) }
    @action.bound userStorageCapacityCurveAddRow() { this.capacityCurve.push({ x: 0, y: 0 }) }
    @action.bound userStorageCapacityTemperatureCurveAddRow() { this.capacityTemperatureCurve.push({ x: 0, y: 0 }) }
    @action.bound userStorageLifetimeTemperatureCurveAddRow() { this.lifetimeTemperatureCurve.push({ x: 0, y: 0 }) }
    @action.bound setUserStorageLifetimeCurveItem(value: number, index: number, x: boolean) { if (x) this.lifetimeCurve[index].x = value; else this.lifetimeCurve[index].y = value }
    @action.bound setUserStorageCapacityCurveItem(value: number, index: number, x: boolean) { if (x) this.capacityCurve[index].x = value; else this.capacityCurve[index].y = value }
    @action.bound setUserStorageCapacityTemperatureCurveItem(value: number, index: number, x: boolean) { if (x) this.capacityTemperatureCurve[index].x = value; else this.capacityTemperatureCurve[index].y = value }
    @action.bound setUserStorageLifetimeTemperatureCurveItem(value: number, index: number, x: boolean) { if (x) this.lifetimeTemperatureCurve[index].x = value; else this.lifetimeTemperatureCurve[index].y = value }
    @action.bound userStorageLifetimeCurveDeleteItem(index: number) { this.lifetimeCurve.splice(index, 1) }
    @action.bound userStorageCapacityCurveDeleteItem(index: number) { this.capacityCurve.splice(index, 1) }
    @action.bound userStorageCapacityTemperatureCurveDeleteItem(index: number) { this.capacityTemperatureCurve.splice(index, 1) }
    @action.bound userStorageLifetimeTemperatureCurveDeleteItem(index: number) { this.lifetimeTemperatureCurve.splice(index, 1) }
    @action.bound setUserStorageNominalCapacity(x: number) { this.nominalCapacity = x }
    @action.bound setUserStorageNominalPower(x: number) { this.nominalPower = x }
    @action.bound setUserStorageMaximumCapacity(x: number) { this.maximumCapacity = x }
    @action.bound setUserStorageChargeCurrent(x: number) { this.chargeCurrent = x }
    @action.bound setUserStorageRangeOfCharge(x: number) { this.rangeOfCharge = x }
    @action.bound setUserStorageMinimumStateOfCharge(x: number) { this.minimumStateOfCharge = x }
    @action.bound setUserStorageEstimatedLifetimeThroughput(x: number) { this.estimatedLifetimeThroughput = x }
    @action.bound setUserStorageRateConstant(x: number) { this.rateConstant = x }
    @action.bound setUserStorageCapacityRatio(x: number) { this.capacityRatio = x }
    @action.bound setUserStorageEffectiveSeriesResistance(x: number) { this.effectiveSeriesResistance = x }
    @action.bound setUserStorageFittedD0(x: number) { this.fittedD0 = x }
    @action.bound setUserStorageFittedD1(x: number) { this.fittedD1 = x }
    @action.bound setUserStorageFittedD2(x: number) { this.fittedD2 = x }
    @action.bound setUserStorageFittedA(x: number) { this.fittedA = x }
    @action.bound setUserStorageFittedBeta(x: number) { this.fittedBeta = x }
    @action.bound setUserStorageArrheniusDegredationB(x: number) { this.arrheniusDegredationB = x }
    @action.bound setUserStorageArrheniusDegredationD(x: number) { this.arrheniusDegredationD = x }
    @action.bound setUserStorageCapacityDegradationLimitForFitCyclelifetimeModel(x: number) { this.capacityDegradationLimitForFitCyclelifetimeModel = x }
    @action.bound setUserStorageInitialStateOfCharge(x: number) { this.initialStateOfCharge = x }
    @action.bound setUserStorageUseLifetimeCurve(x: boolean) { this.useLifetimeCurve = x }
    @action.bound setUserStorageOtherLosses(x: number) { this.otherLosses = x }
    @action.bound setUserStorageIgnoreCapacityVsTemperatureInModel(x: boolean) { this.ignoreCapacityVsTemperatureInModel = x }
    @action.bound setUserStorageIgnoreTemperatureEffectOnLifetime(x: boolean) { this.ignoreTemperatureEffectOnLifetime = x }
    @action.bound setUserStorageSimpleLifetimeInYears(x: number) { this.simpleLifetimeInYears = x }
    @action.bound setUserStorageStringSize(x: number) { this.stringSize = x }
    @action.bound setUserStorageMaximumOperatingTemperature(x: number) { this.maximumOperatingTemperature = x }
    @action.bound setUserStorageMinimumOperatingTemperature(x: number) { this.minimumOperatingTemperature = x }
    @action.bound setUserStorageMass(x: number) { this.mass = x }
    @action.bound setUserStorageFixedTemperature(x: number) { this.fixedTemperature = x }
    @action.bound setUserStorageThermalH(x: number) { this.thermalH = x }
    @action.bound setUserStorageThermalCSpecific(x: number) { this.thermalCSpecific = x }
    @action.bound setUserStorageAugmentationPrice(x: number) { this.augmentationPrice = x }
    //@action.bound setUserStorageAugmentationDegradationLimitSensitivity: number[] = []
    @action.bound setUserStorageCapacityDegradationLimitForFitLifetimeTemperatureModel(x: number) { this.capacityDegradationLimitForFitLifetimeTemperatureModel = x }
    //@action.bound setUserStorageDedicatedConverter: Inverter = new Inverter()
    @action.bound setUserStorageApplyMinimumLifetime(x: boolean) { this.applyMinimumLifetime = x }
    @action.bound setUserStorageMinimumLifetime(x: number) { this.minimumLifetime = x }
    @action.bound setUserStorageIsAc(x: boolean) { this.isAc = x }
    @action.bound setUserStorageUseFixedTemperature(x: boolean) { this.useFixedTemperature = x }
    //@action.bound setUserStorageCostTable: CostTable = new CostTable()

    @action.bound initialize(storage: StorageSpec) {
        this.id = storage.id
        this.name = storage.name
        this.abbreviation = storage.abbreviation
        this.nominalCapacity = storage.nominalCapacity
        this.nominalPower = storage.nominalPower
        this.maximumCapacity = storage.maximumCapacity
        this.rateConstant = storage.rateConstant
        this.capacityRatio = storage.capacityRatio
        this.effectiveSeriesResistance = storage.effectiveSeriesResistance
        this.fittedD0 = storage.fittedD0
        this.fittedD1 = storage.fittedD1
        this.fittedD2 = storage.fittedD2
        this.nominalVoltage = storage.nominalVoltage
        this.chargeCurrent = storage.chargeCurrent
        this.rangeOfCharge = storage.rangeOfCharge
        this.minimumStateOfCharge = storage.minimumStateOfCharge
        this.fittedA = storage.fittedA
        this.fittedBeta = storage.fittedBeta
        this.capacityDegradationLimitForFitCyclelifetimeModel = storage.capacityDegradationLimitForFitCyclelifetimeModel
        this.capacityDegradationLimitForFitLifetimeTemperatureModel = storage.capacityDegradationLimitForFitLifetimeTemperatureModel
        this.arrheniusDegredationB = storage.arrheniusDegredationB
        this.arrheniusDegredationD = storage.arrheniusDegredationD
        this.estimatedLifetimeThroughput = storage.estimatedLifetimeThroughput
        this.lifetimeCurve = storage.lifetimeCurve
        this.chemistry = storage.chemistry
        this.manufacturer = storage.manufacturer
        this.requiresOneMinuteTimestep = storage.requiresOneMinuteTimestep
        this.notes = storage.notes
        this.initialStateOfCharge = storage.initialStateOfCharge
        this.capacityCurve = storage.capacityCurve
        this.useLifetimeCurve = storage.useLifetimeCurve
        this.useDedicatedConverter = storage.useDedicatedConverter
        this.capacityCurve = storage.capacityCurve
        this.useLifetimeCurve = storage.useLifetimeCurve
        this.applyMaxDischargeCurrent = storage.applyMaxDischargeCurrent
        this.applyMaxChargeCurrent = storage.applyMaxChargeCurrent
        this.applyMaxChargeRate = storage.applyMaxChargeRate
        this.maxDischargeCurrent = storage.maxDischargeCurrent
        this.maxChargeCurrent = storage.maxChargeCurrent
        this.maxChargeRate = storage.maxChargeRate
        this.otherLosses = storage.otherLosses
        this.additiveEndOfLifeCalculation = storage.additiveEndOfLifeCalculation
        this.degradationUsesNominalSoc = storage.degradationUsesNominalSoc
        this.ignoreCapacityVsTemperatureInModel = storage.ignoreCapacityVsTemperatureInModel
        this.capacityTemperatureCurve = storage.capacityTemperatureCurve
        this.ignoreTemperatureEffectOnLifetime = storage.ignoreTemperatureEffectOnLifetime
        this.simpleLifetimeInYears = storage.simpleLifetimeInYears
        this.stringSize = storage.stringSize
        this.lifetimeTemperatureCurve = storage.lifetimeTemperatureCurve
        this.maximumOperatingTemperature = storage.maximumOperatingTemperature
        this.minimumOperatingTemperature = storage.minimumOperatingTemperature
        this.mass = storage.mass
        this.fixedTemperature = storage.fixedTemperature
        this.thermalH = storage.thermalH
        this.thermalCSpecific = storage.thermalCSpecific
        this.augmentationDegradationLimitSensitivity = deserializeSensitivityVar(storage.augmentationDegradationLimitSensitivity)
        this.augmentationPrice = storage.augmentationCost
        var converter = new Inverter()
        converter.efficiency.values = []
        storage.dedicatedConverter?.efficiency?.values?.map(x => {
            var item = new SensitivityItem()
            item.setValue(x)
            converter.efficiency.values.push(item)
        })
        converter.lifetime.values = []
        storage.dedicatedConverter?.lifetime?.values?.map(x => {
            var item = new SensitivityItem()
            item.setValue(x)
            converter.lifetime.values.push(item)
        })
        converter.sizing.kind = SizingKind.relative
        converter.sizing.relativeValues = []
        storage.dedicatedConverter?.size?.values?.map(x => {
            var item = new SensitivityItem()
            item.setValue(x)
            converter.sizing.relativeValues.push(item)
        })
        converter.cost.costTableType = CostTableType.simple
        converter.cost.simple.base.capital = Number(storage.dedicatedConverter?.cost?.simpleCostTable?.capital)
        converter.cost.simple.base.operating = Number(storage.dedicatedConverter?.cost?.simpleCostTable?.operating)
        converter.cost.simple.base.replacement = Number(storage.dedicatedConverter?.cost?.simpleCostTable?.replacement)
        converter.cost.simple.items[0].capital = Number(storage.dedicatedConverter?.cost?.simpleCostTable?.capital)
        converter.cost.simple.items[0].operating = Number(storage.dedicatedConverter?.cost?.simpleCostTable?.operating)
        converter.cost.simple.items[0].replacement = Number(storage.dedicatedConverter?.cost?.simpleCostTable?.replacement)
        this.dedicatedConverter = converter

        this.applyMinimumLifetime = storage.applyMinimumLifetime
        this.minimumLifetime = storage.minimumLifetime
        this.isAc = storage.isAc
        this.useFixedTemperature = storage.useFixedTemperature
        this.costTable.costTableType = storage.useComplexCost ? CostTableType.complex : CostTableType.simple
        this.costTable.simple.base.capital = storage.simpleCostTable.capital
        this.costTable.simple.base.operating = storage.simpleCostTable.operating
        this.costTable.simple.base.replacement = storage.simpleCostTable.replacement

        storage.complexCostTable?.directCapital?.map(x => {
            var item = new ComplexCostItem();
            item.setName(x.name)
            item.setUnit(x.units as CostUnit)
            item.setValue(x.value)
            this.costTable.complex.directCapital.items.push(item)
        })
        storage.complexCostTable?.indirectCapital?.map(x => {
            var item = new ComplexCostItem();
            item.setName(x.name)
            item.setUnit(x.units as CostUnit)
            item.setValue(x.value)
            this.costTable.complex.indirectCapital.items.push(item)
        })
        storage.complexCostTable?.operating?.map(x => {
            var item = new ComplexCostItem();
            item.setName(x.name)
            item.setUnit(x.units as CostUnit)
            item.setValue(x.value)
            this.costTable.complex.operating.items.push(item)
        })
    }
}

export function SerializeStorageSpecs(storage: UserStorageClass): StorageSpec {
    var spec = {} as StorageSpec

    spec.id = storage.id
    spec.name = storage.name
    spec.abbreviation = storage.abbreviation
    spec.maximumCapacity = storage.maximumCapacity
    spec.nominalVoltage = storage.nominalVoltage
    spec.chargeCurrent = storage.chargeCurrent
    spec.rangeOfCharge = storage.rangeOfCharge
    spec.minimumStateOfCharge = storage.minimumStateOfCharge 
    spec.estimatedLifetimeThroughput = storage.estimatedLifetimeThroughput
    spec.lifetimeCurve = storage.lifetimeCurve
    spec.chemistry = storage.chemistry
    spec.manufacturer = storage.manufacturer
    spec.requiresOneMinuteTimestep = storage.requiresOneMinuteTimestep
    spec.notes = storage.notes
    spec.initialStateOfCharge = storage.initialStateOfCharge
    spec.capacityCurve = storage.capacityCurve
    spec.useLifetimeCurve = storage.useLifetimeCurve
    spec.capacityCurve = storage.capacityCurve
    spec.useLifetimeCurve = storage.useLifetimeCurve
    spec.applyMaxChargeCurrent = storage.applyMaxChargeCurrent
    spec.applyMaxChargeRate = storage.applyMaxChargeRate
    spec.applyMaxDischargeCurrent = storage.applyMaxDischargeCurrent
    spec.maxDischargeCurrent = storage.maxDischargeCurrent
    spec.maxChargeCurrent = storage.maxChargeCurrent
    spec.maxChargeRate = storage.maxChargeRate
    spec.otherLosses = storage.otherLosses
    spec.additiveEndOfLifeCalculation = storage.additiveEndOfLifeCalculation
    spec.degradationUsesNominalSoc = storage.degradationUsesNominalSoc
    spec.ignoreCapacityVsTemperatureInModel = storage.ignoreCapacityVsTemperatureInModel
    spec.capacityTemperatureCurve = storage.capacityTemperatureCurve
    spec.ignoreTemperatureEffectOnLifetime = storage.ignoreTemperatureEffectOnLifetime
    spec.simpleLifetimeInYears = storage.simpleLifetimeInYears
    spec.stringSize = storage.stringSize
    spec.lifetimeTemperatureCurve = storage.lifetimeTemperatureCurve
    spec.maximumOperatingTemperature = storage.maximumOperatingTemperature
    spec.minimumOperatingTemperature = storage.minimumOperatingTemperature
    spec.mass = storage.mass
    spec.fixedTemperature = storage.fixedTemperature
    spec.thermalH = storage.thermalH
    spec.thermalCSpecific = storage.thermalCSpecific
    spec.augmentationDegradationLimitSensitivity = serializeSensitivityVar(storage.augmentationDegradationLimitSensitivity)
    spec.applyMinimumLifetime = storage.applyMinimumLifetime
    spec.minimumLifetime = storage.minimumLifetime
    spec.isAc = storage.isAc
    spec.useFixedTemperature = storage.useFixedTemperature
    spec.rateConstant = storage.rateConstant
    spec.capacityRatio = storage.capacityRatio
    spec.effectiveSeriesResistance = storage.effectiveSeriesResistance
    spec.fittedA = storage.fittedA
    spec.fittedBeta = storage.fittedBeta
    spec.fittedD0 = storage.fittedD0
    spec.fittedD1 = storage.fittedD1
    spec.fittedD2 = storage.fittedD2
    spec.capacityDegradationLimitForFitCyclelifetimeModel = storage.capacityDegradationLimitForFitCyclelifetimeModel
    spec.capacityDegradationLimitForFitLifetimeTemperatureModel = storage.capacityDegradationLimitForFitLifetimeTemperatureModel
    spec.arrheniusDegredationB = storage.arrheniusDegredationB
    spec.arrheniusDegredationD = storage.arrheniusDegredationD
    spec.augmentationCost = storage.augmentationPrice

    spec.useComplexCost = storage.costTable.costTableType == CostTableType.complex
    spec.simpleCostTable = {} as SimpleCostTableDto
    spec.simpleCostTable.capital = storage.costTable.simple.base.capital
    spec.simpleCostTable.operating = storage.costTable.simple.base.operating
    spec.simpleCostTable.replacement = storage.costTable.simple.base.replacement
    spec.complexCostTable = {} as ComplexCostTableDto
    spec.complexCostTable.directCapital = []
    spec.complexCostTable.replacement = []
    spec.complexCostTable.indirectCapital = []
    spec.complexCostTable.operating = []
    storage.costTable?.complex?.replacement?.items?.map(x => spec.complexCostTable.replacement.push({ name: x.name, units: x.unit, value: x.value }))
    storage.costTable?.complex?.operating?.items?.map(x => spec.complexCostTable.operating.push({ name: x.name, units: x.unit, value: x.value }))
    storage.costTable?.complex?.directCapital?.items?.map(x => spec.complexCostTable.directCapital.push({ name: x.name, units: x.unit, value: x.value }))
    storage.costTable?.complex?.indirectCapital?.items?.map(x => spec.complexCostTable.indirectCapital.push({ name: x.name, units: x.unit, value: x.value }))

    spec.useDedicatedConverter = storage.useDedicatedConverter
    spec.dedicatedConverter = {} as ConverterDto
    spec.dedicatedConverter.size = {} as SizingVarDto
    spec.dedicatedConverter.size.values = []
    spec.dedicatedConverter.size.sizingKind = SizingKind.relative
    storage.dedicatedConverter.sizing.values.map(x => spec.dedicatedConverter.size.values.push(x.value))
    spec.dedicatedConverter.cost = {} as CostTableDto
    spec.dedicatedConverter.cost.simpleCostTable = {} as SimpleCostTableDto
    spec.dedicatedConverter.cost.costTableKind = CostTableType.simple
    spec.dedicatedConverter.cost.simpleCostTable = {} as SimpleCostTableDto
    spec.dedicatedConverter.cost.simpleCostTable.augmentation = storage.dedicatedConverter.cost.simple.base.augmentation
    spec.dedicatedConverter.cost.simpleCostTable.capital = storage.dedicatedConverter.cost.simple.base.capital
    spec.dedicatedConverter.cost.simpleCostTable.operating = storage.dedicatedConverter.cost.simple.base.operating
    spec.dedicatedConverter.cost.simpleCostTable.replacement = storage.dedicatedConverter.cost.simple.base.replacement
    spec.dedicatedConverter.efficiency = {} as SensitivityVarDto
    spec.dedicatedConverter.efficiency.values = []
    storage.dedicatedConverter.efficiency.values.map(x => spec.dedicatedConverter.efficiency.values.push(x.value))
    spec.dedicatedConverter.lifetime = {} as SensitivityVarDto
    spec.dedicatedConverter.lifetime.values = []
    storage.dedicatedConverter.lifetime.values.map(x => spec.dedicatedConverter.lifetime.values.push(x.value))

    return spec
}
