import acconv from 'assets/images/dynamo-ac.svg'
import dcconv from 'assets/images/dynamo-dc.svg'
import SchematicIcon from './SchematicIcon'

interface ConverterProps {
    bus: string;
    systemConverter?: boolean;
}

const Converter = ({bus, systemConverter}: ConverterProps) => {
    const iconSource = bus === 'ac' || systemConverter ? acconv : dcconv
    const label = systemConverter ? (bus === 'ac' ? 'Syst Inv' : 'Syst Conv') : (bus === 'ac' ? 'DC/AC Inv' : 'DC/DC Conv')
    return <SchematicIcon src={iconSource} label={label}/>
}

export default Converter
