import './MultiYearView.scss'
import { observer } from 'mobx-react-lite'
import { MultiYearVar } from '../../model/MultiYearVar'
import Form from 'react-bootstrap/Form'
import { Dropdown } from 'react-bootstrap'
import { useProjectStore } from '../../ProjectProvider'
import NumberInput from 'components/helper/NumberInput'
import { Validation } from 'components/helper/validation'
import ContextHelp from 'components/helper/ContextHelp'
import ToolTipInfo from 'components/helper/ToolTipInfo'
import { ClipboardEvent } from 'react';
import { ValidationFunc } from 'components/helper/validation'
import api from '../../../../services/api'
import { MultiYearKind } from '../../../../services/inputs'


interface MultiYearViewProps {
    model: MultiYearVar
    id: string

    label: string
    linearLabel?: string
    customLabel?: string
    header?: string

    helpId?: string
    validator?: ValidationFunc
    disabled?: boolean
}


const MultiYearView = observer(({ model, id, label, linearLabel, customLabel, header, helpId, validator, disabled = false }: MultiYearViewProps) => {
    const project = useProjectStore().project!
    const baseYear = project?.baseYear
    const useCommaAsDecimalPoint = project?.useCommaAsDecimalPoint

    const handleSelect = (kind: MultiYearKind) => model.setType(kind, project?.lifetime)

    const getLabel = (kind: MultiYearKind) => {
        switch (kind) {
            case MultiYearKind.linear: { return linearLabel ?? '%/year' }
            case MultiYearKind.custom: { return customLabel ?? 'Escalation by year' }
        }
    }

    const isLinear = model.multiYearKind === MultiYearKind.linear
    const isCustom = model.multiYearKind === MultiYearKind.custom

    return (<>
        <div className='form-group row align-items-center'>
            <div className='d-flex col-5'>
                <Form.Check id={'multi-year-enabled-' + id}
                    type='checkbox' label={label} custom
                    checked={model.enabled}
                    disabled={project?.earlyAnalysis || disabled}
                    onChange={ev => model.setEnabled(ev.target.checked)} />
                {project?.earlyAnalysis && <ToolTipInfo label={'Disabled for early-stage analysis, because only a single year is simulated'} />}
            </div>

            {model.enabled && !project?.earlyAnalysis && <>
                {isLinear &&
                    <div className='col-3'>
                        <NumberInput id={'multi-year-value-' + id}
                            value={model.linearValue}
                            onChange={x => model.setLinearValue(x)}
                            validator={validator ? validator : Validation.range(-100, +100)}
                            disabled={!model.enabled || disabled} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>}

                <div className={isLinear ? 'col-4' : 'col-7'}>
                    <div className='d-flex flex-row align-items-center'>
                        <Dropdown drop='down'>
                            <Dropdown.Toggle className='multi-year-dropdown' id={'multi-year-kind-' + id}>
                                {getLabel(model.multiYearKind)}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className='multi-year-dropdown-menu'>
                                <Dropdown.Item onClick={() => handleSelect(MultiYearKind.linear)}>{getLabel(MultiYearKind.linear)}</Dropdown.Item>
                                <Dropdown.Item onClick={() => handleSelect(MultiYearKind.custom)}>{getLabel(MultiYearKind.custom)}</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <div className='mx-1' />
                        {helpId && <ContextHelp helpId={helpId} />}
                    </div>
                </div>
            </>}
        </div>

        {model.enabled && isCustom && !project?.earlyAnalysis &&
            <div className='form-group row align-items-center'>
                <div className='offset-5 col-7 multi-year-table table-scroll'>
                    <MultiYearTable model={model} header={header ?? label} baseYear={baseYear} validator={validator} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                </div>
            </div>}
    </>)
})

export default MultiYearView


interface MultiYearTableProps {
    model: MultiYearVar
    header: string
    baseYear: number
    validator?: ValidationFunc
    useCommaAsDecimalPoint: boolean
}

const MultiYearTable = observer(({ model, header, baseYear, validator, useCommaAsDecimalPoint }: MultiYearTableProps) => {
    const showBaseYearAsYearZero = api.showBaseYearAsYearZero 

    const handleClipboardEvent = (ev: ClipboardEvent<HTMLInputElement>, indx: number) => {
        ev.preventDefault();
        if (ev.clipboardData) {
            let clipboardText = ev.clipboardData.getData('text/plain');
            let clipboardRows = clipboardText.split('\n');
            for (var i = 0; i < clipboardRows.length; i++) {
                let t = clipboardRows[i];
                if (parseFloat(clipboardRows[i]) && (clipboardRows[i].replace(/\s/g, " ").indexOf(" ") !== 0) && (indx + i) <= model.customValues.length - 1) {
                    model.customValues[indx + i].value = parseFloat(clipboardRows[i]);
                }
            }
        }
    }
    const handleEnter = (index?: number) => {
        try {
            (document.getElementById(model.customValues[(index ?? 0) + 1].id) as HTMLElement).focus();
        }
        catch {
            console.log('not able to focus on next input')
        }
    }
    return <table className='table-bordered table-hover table-sm'>
        <thead>
            <tr>
                <th>Project Year</th>
                <th>{header}</th>
            </tr>
        </thead>
        <tbody>
            {model.customValues.map((item, index) =>
                <tr key={item.id}>
                    <td>{showBaseYearAsYearZero ? baseYear + item.year : item.year + 1}</td>
                    <td className='table-w-2 p-0 position-relative'>
                        <NumberInput value={item.value}
                            validator={validator}
                            id={item.id}
                            onChange={x => item.setValue(x)}
                            className='h-100 border-0 rounded-0'
                            onPaste={(ev) => handleClipboardEvent(ev, index)}
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint}
                            onEnter={() => handleEnter(index)} />
                    </td>
                </tr>)}
        </tbody>
    </table>
})
