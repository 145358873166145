import { Form } from 'react-bootstrap'
import { useProjectStore } from 'components/project/ProjectProvider'
import SensitivityVarView from 'components/project/pages/EnergyMarket/SensitivityVarView'
import { observer } from 'mobx-react-lite'
import TimeSeriesView from 'components/helper/TimeSeriesView'
import TimeSeriesImport from 'components/helper/TimeSeriesImport'
import NumberInput from 'components/helper/NumberInput'
import { Wind } from 'components/project/model/Wind'
import { Validation } from 'components/helper/validation'

interface WindResourceViewProps {
    model: Wind
}

const WindResourceView = observer(({ model }: WindResourceViewProps) => {
    const store = useProjectStore()
    const project = store.project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    const handleSelectWind = async (data: number[], year: number, fileName: string) => {
        project.windResource.setData(data, year, true)
        project.setWindResourceName(fileName)
        await store.saveProject()
    }

    return (<>
        <div className='d-flex flex-column'>
            <div className='d-flex align-items-center'>
                <div className='d-flex flex-column col-4'>
                    <h6>Wind speed data selection:</h6>
                    <div>
                        <Form.Check custom label='Use Wind Resource Data' id='wind-library' type='radio'
                            checked={project.windResource.kind === 'library'}
                            onChange={ev => ev.target.checked && project.windResource.setKind('library')} />
                    </div>
                    <div>
                        <Form.Check custom label='Upload wind resource data (m/s)' id='wind-timeseries' type='radio'
                            checked={project.windResource.kind === 'timeseries'}
                            onChange={ev => ev.target.checked && project.windResource.setKind('timeseries')} />
                    </div>
                </div>

                {project.windResource.kind === 'timeseries' && <>
                    <div className='col-2'>
                        <TimeSeriesImport onSelect={handleSelectWind} />
                    </div>
                    <div className='col-6'>
                        <div className='row'>
                            <SensitivityVarView model={project.windResource.scale} label='Annual Average Wind Speed (m/s) ' units='m/s' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            <div className='row'>
                                <div className='col-5'>Altitude above sea level (m)</div>
                                <div className='col-3'>
                                    <NumberInput useCommaAsDecimalPoint={useCommaAsDecimalPoint} value={model.altitude}
                                        onChange={x => model.setAltitude(x)}
                                        validator={Validation.min(0)} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-5'>Anemometer height (m)</div>
                                <div className='col-3'>
                                    <NumberInput useCommaAsDecimalPoint={useCommaAsDecimalPoint} value={model.anemometerHeight}
                                        onChange={x => model.setAnemometerHeight(x)}
                                        validator={Validation.min(0)} />
                                </div>
                            </div>
                        </div>
                    </div>
                </>}
            </div>
            {project.windResource.data.state === 'ready' && project.windResource.kind === 'timeseries' && <div className='col-12'>
                <TimeSeriesView model={project.windResource.data} chartTitle={project.windResourceName} intlCulture={intlCulture} />
            </div>}
        </div>

    </>
    )
})

export default WindResourceView
