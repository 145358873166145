import { TimeSeriesItem, TimeSeriesViewerModel } from './TimeSeriesViewerModel'
import './TimeSeriesViewer.scss'
import { observer } from 'mobx-react-lite'
import { Chart, ChartLegend, ChartSeries, ChartSeriesItem, ChartTooltip, ChartXAxis, ChartXAxisItem, ChartYAxis, ChartYAxisItem, DragEndEvent, SharedTooltipContext, TooltipContext, ZoomEndEvent } from '@progress/kendo-react-charts'
import { Fragment, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { makeRange, Range } from 'components/project/pages/Results/time-series/Range'
import { ColorRect } from 'components/helper/ColorRect'
import { useProjectStore } from 'components/project/ProjectProvider'
import ContextHelp from 'components/helper/ContextHelp'
import PanZoomControl from './PanZoomControl'
import allcurrency from 'iso-country-currency'
import DropDownSelector from '../../SharedComponents/DropDownSelector'
import { IntlService } from 'Kendo-Intl-5'
import { Splitter, SplitterOnChangeEvent } from "@progress/kendo-react-layout";



interface TimeSeriesViewerProps {
    model: TimeSeriesViewerModel
    showBaseYearAsYearZero: boolean
    baseYear: number
}

const TimeSeriesViewer = observer(({ model, showBaseYearAsYearZero, baseYear }: TimeSeriesViewerProps) => {

    const project = useProjectStore().project!
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    const year = project.baseYear ?? 2007

    const handleYearChange = async (x: number) => await model.setYear(x)
    const handleRangeChange = (r: Range) => model.setRange(r)

    const [panes, setPanes] = useState<Array<any>>([
        { min: '60%', scrollable: false, collapsible: true },
        { size: '30%', min: '20%', scrollable: true, collapsible: true }
    ]);
    const onChange = (event: SplitterOnChangeEvent) => {
        setPanes(event.newState);
    };

    return (<>

        <Splitter
            panes={panes}
            onChange={onChange}
        >
            <div className="pane-content">
                <div className='d-flex flex-row m-2 justify-content-end'>
                    <div className='d-flex flex-row align-items-center'>
                        <button className='btn btn-sm btn-outline-primary' onClick={() => model.exportCsv()}>Export Single Year</button>
                        <Spinner animation='border' size='sm' hidden={!model.loadingCsv} className='ml-2 text-primary' />
                        <button className='btn btn-sm btn-outline-primary ml-2' onClick={() => model.exportMultiYearCsv()}>Export All Years</button>
                        <Spinner animation='border' size='sm' hidden={!model.loadingMultiYearCsv} className='ml-2 text-primary' />
                    </div>
                    <div className='mx-auto' />
                    {!project.earlyAnalysis && <div className='d-flex flex-row align-items-center'>
                        <div className='mr-2'>Year:</div>
                        <DropDownSelector max={project.lifetime} value={model.year} onValueChange={handleYearChange} showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={baseYear} />
                        <Spinner animation='border' size='sm' hidden={!model.loading} className='ml-2 text-primary' />
                    </div>}
                    <div className='mx-auto' />
                    <ContextHelp helpId='TIMESERIES' />
                </div>
                {(model.hasTop || !model.hasBottom) &&
                    <div className='d-flex flex-column align-items-stretch justify-content-center'>
                        <TimeSeriesChart items={model.itemsTop} size={model.size}
                            axis={model.axisTop} intlCulture={intlCulture} year={year} projectYear={model.year}
                            range={model.range} onRangeChange={handleRangeChange} />
                        <PanZoomControl model={model} />
                    </div>}
                {model.hasBottom &&
                    <div className='d-flex flex-column align-items-stretch justify-content-center'>
                        <TimeSeriesChart items={model.itemsBottom} size={model.size}
                            axis={model.axisBottom} intlCulture={intlCulture} year={year} projectYear={model.year}
                            range={model.range} onRangeChange={handleRangeChange} />
                        <PanZoomControl model={model} />
                    </div>}
            </div>
            <div className="pane-content">
                <LegendView model={model} />
            </div>
        </Splitter>

    </>
    )
})


export default TimeSeriesViewer


interface TimeSeriesChartProps {
    items: TimeSeriesItem[]
    axis: string[]
    size: number
    range: Range
    onRangeChange: (r: Range) => void
    year: number
    //From year 0 on
    projectYear: number
    intlCulture: string
}


// X axis labels

//const START_DATE = new Date(2023, 0, 1)

const dateFormatter = new Intl.DateTimeFormat('en', { month: 'short', day: 'numeric', year: 'numeric' })
const timeFormatter = new Intl.DateTimeFormat('en', { hour: 'numeric', minute: 'numeric', hour12: false })

const formatLabel = (ms: number, rangeHours: number): string => {
    if (rangeHours < 24 * 7) {
        return dateFormatter.format(ms) + '\n' + timeFormatter.format(ms)
    }
    return dateFormatter.format(ms)
}

const CHART_HEIGHT = '350px'

const TimeSeriesChart = observer(({ items, axis, size, range, onRangeChange, intlCulture, year = 2007, projectYear = 0 }: TimeSeriesChartProps) => {
    const intl = new IntlService(intlCulture)
    const allCurSymbol = allcurrency.getAllISOCodes().map(item => item.symbol);
    const handleZoomEnd = (ev: ZoomEndEvent) => {
        const axis = ev.axisRanges['x']
        const r = makeRange(Number(axis.min), Number(axis.max))
        onRangeChange(r)
    }

    const handleDragEnd = (ev: DragEndEvent) => {
        const isZooming = ev.nativeEvent.event?.shiftKey ?? false
        if (isZooming) return
        const axis = ev.axisRanges['x']
        const r = makeRange(Number(axis.min), Number(axis.max))
        onRangeChange(r)
    }

    const valueToMs = (x: number): number => {
        const START_DATE = new Date(year + projectYear, 0, 1)
        const stepsPerHour = size / (365 * 24)
        const ms = (x / stepsPerHour) * 60 * 60 * 1000
        return START_DATE.getTime() + ms
    }

    const labelContent = (e: any): string => {
        const t = valueToMs(Number(e.value))
        const stepsPerHour = size / (365 * 24)
        const rangeHours = (range.max - range.min) / stepsPerHour
        return formatLabel(t, rangeHours)
    }

    const tooltipContent = (context: TooltipContext | SharedTooltipContext) => {
        if (!context.hasOwnProperty('point')) return
        const point = (context as TooltipContext).point
        const ms = valueToMs(point.value.x)
        const y = allCurSymbol.indexOf(point.series.yAxis) > -1 ? (point.series.yAxis + " " + intl.formatNumber(point.value.y, 'n2')) : (intl.formatNumber(point.value.y, 'n2') + " " + point.series.yAxis)
        const series = (point.series.name.length > 40 ? (' ' + point.series.name.substring(0, 20) + "..." + point.series.name.substring(point.series.name.length - 20, point.series.name.length)) : (' ' + point.series.name))
        const label = formatLabel(ms, 1)
        return (<div>{label}<br />{y}<br />{series}</div>)
    }

    return (
        <Chart style={{ height: CHART_HEIGHT }} renderAs='canvas' transitions={false}
            zoomable={{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }}
            onDragEnd={handleDragEnd}
            onZoomEnd={handleZoomEnd}>
            <ChartLegend visible={false} />
            <ChartTooltip render={tooltipContent} format='{0:n}' />
            <ChartXAxis>
                <ChartXAxisItem name='x' min={range.min} max={range.max}
                    labels={{ content: labelContent }} axisCrossingValue={[range.min, range.max]} />
            </ChartXAxis>
            <ChartYAxis>
                {axis.map(x =>
                    <ChartYAxisItem key={x} name={x} title={{ text: x }} />)}
            </ChartYAxis>
            <ChartSeries>
                {items.map(item =>
                    <ChartSeriesItem key={item.name} type='scatterLine' markers={{ visible: false }}
                        name={item.name}
                        yAxis={item.units}
                        data={item.points}
                        color={item.color} />)}
            </ChartSeries>
        </Chart>
    )
})


interface LegendViewProps {
    model: TimeSeriesViewerModel
}

const LegendView = observer(({ model }: LegendViewProps) => {
    return (
        <>
            {model.groups.map(group => {
                return (
                    <Fragment key={group.id}>
                        <div className='mt-3'><strong>{group.name}</strong></div>
                        {group.mainItems.map(item =>
                            <LegendItemView key={item.name} item={item} model={model} timeseriesCount={model.items?.length} />)}
                        {group.showExtra && group.extraItems.map(item =>
                            <LegendItemView key={item.name} item={item} model={model} timeseriesCount={model.items?.length} />)}
                        {group.extraItems.length > 0 &&
                            <button className='btn btn-sm btn-link btn-no-focus align-self-start'
                                onClick={() => group.toggleShowExtra()}>
                                {group.showExtra ? 'Show Less' : 'Show More'}
                            </button>}
                    </Fragment>
                )
            })}
        </>
    )
})

interface LegendItemViewProps {
    model: TimeSeriesViewerModel,
    item: TimeSeriesItem,
    timeseriesCount: number
}

const LegendItemView = observer(({ item, model, timeseriesCount }: LegendItemViewProps) => {
    const axesTop = model.axisTop
    const canSelectTop = axesTop.length < 2 || axesTop.includes(item.units)

    const axesBottom = model.axisBottom
    const canSelectBottom = axesBottom.length < 2 || axesBottom.includes(item.units)

    return (
        <div key={item.name} className='pl-2 d-flex flex-row align-items-center position-relative'>
            <ColorRect color={item.color}
                onColorChange={x => model.setColor(item.name, x)}
                timeseriesCount={timeseriesCount} />
            <Form.Check custom id={`${item.name}-top`} type='checkbox'
                style={{ display: 'inline-block' }}
                checked={item.selectedTop}
                onChange={ev => model.selectTop(item.name, ev.target.checked)}
                disabled={!canSelectTop && !item.selectedTop} />
            <Form.Check custom id={`${item.name}-bottom`} type='checkbox'
                style={{ display: 'inline-block' }}
                checked={item.selectedBottom}
                onChange={ev => model.selectBottom(item.name, ev.target.checked)}
                disabled={!canSelectBottom && !item.selectedBottom} />
            <span className='text-nowrap'>{item.name} ({item.units})</span>
        </div>
    )
})
