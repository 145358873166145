import {RouteComponentProps, useNavigate} from '@reach/router'
import {BiDotsVerticalRounded} from 'react-icons/all'
import Dropdown from 'react-bootstrap/Dropdown'
import {Grid, GridCellProps} from '@progress/kendo-react-grid'
import {orderBy} from '@progress/kendo-data-query'
import {GridColumn as Column} from '@progress/kendo-react-grid/dist/npm/GridColumn'
import React from 'react'
import {observer} from 'mobx-react-lite'
import { StorageSpec, UserStorageInfo} from 'services/api'
import './UserLibraryView.scss'
import { useUserLibraryStore } from './UserStorageProvider'


interface userStorageLibraryTableListProps extends RouteComponentProps {
    model: StorageSpec[]
    onDelete?: (id: string) => void
    onCopy?: (id: string) => void
    onDownload?: (id: string) => void
}

const initialSort = [{
    field: 'name',
}]

const UserStorageLibraryTableListView = observer(({model, onDelete, onCopy, onDownload}: userStorageLibraryTableListProps) => {
    const navigate = useNavigate()

    const [sort, setSort] = React.useState(initialSort)
    var store = useUserLibraryStore()

    const navigateToEditPage = async (id: string) => {
        store.getUserStorage(id!).then()
        await navigate(`/storageLibrary/edit/${id}`)
    }

    const iconCell = (cell: GridCellProps) => {
        const item = cell.dataItem as UserStorageInfo
        return (
            <td>
                <div className={'project-list-row-dot-menu d-flex align-items-center justify-content-between'}>
                    <div>
                        <Dropdown className='project-list-row-dot-menu'>
                            <Dropdown.Toggle id='projectActionMenu' className='project-list-row-dropdown-button'>
                                <BiDotsVerticalRounded />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => onDelete?.(item.id)}>Delete</Dropdown.Item>
                                <Dropdown.Item id='copyProjectButton' onClick={() => onCopy?.(item.id)}>Copy</Dropdown.Item>
                                <Dropdown.Item onClick={() => onDownload?.(item.id)}>Download</Dropdown.Item>
                                <Dropdown.Item onClick={() => navigateToEditPage(item.id)}>Edit</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </td>
        )
    }

    return (
        <Grid
            scrollable={'scrollable'}
            sortable={true}
            data={orderBy(model, sort)}
            className='user-library-table-list'
            sort={sort}
            onSortChange={ev => setSort(ev.sort)}>
            <Column title='Name' width={250} field='name'/>
            <Column title='Manufacturer' field='manufacturer' />
            <Column title='Chemistry' field='chemistry' />
            <Column title='Nominal Capacity' field='nominalCapacity' format='{0:n2}' />
            <Column title='Nominal Voltage' field='nominalVoltage' format='{0:N2}' />
            <Column title='Roundtrip Efficiency' field='roundtripEfficiency' format='{0:N2}' />
            <Column title='Range of Charge' field='rangeOfCharge' format='{0:N2}' />
            <Column title='Min State of Charge' field='minimumStateOfCharge' format='{0:N2}' />
            <Column title='Est. Lifetime Throughput' width={200} field='estimatedLifetimeThroughput' format='{0:N2}' />
            <Column width={80} title='Actions' cell={iconCell}/>
        </Grid>
    )
})

export default UserStorageLibraryTableListView
