import { observer } from 'mobx-react-lite'
import CostTableView from './shared/CostTableView'
import MultiYearView from 'components/project/pages/SharedComponents/MultiYearView'
import { Button, Dropdown, Form, Modal, Spinner } from 'react-bootstrap'
import InverterView from 'components/project/pages/equipment/InverterView'
import { GoX } from 'react-icons/all'
import { storageUnits, ChangeUnitOptionSetCurrency } from 'components/project/pages/equipment/shared/DetailCostBreakup'
import { useProjectStore } from 'components/project/ProjectProvider'
import SizingView from 'components/project/pages/equipment/shared/SizingView'
import SensitivityVarView from 'components/project/pages/EnergyMarket/SensitivityVarView'
import React, { useEffect, useState } from 'react'
import api, { StorageCurveItem, StorageSpec } from 'services/api'
import { Validation } from 'components/helper/validation'
import ContextHelp from 'components/helper/ContextHelp'
import FilePicker from 'components/helper/FilePicker'
import { IntlService } from 'Kendo-Intl-5'
import { Chart, ChartSeries, ChartSeriesItem, ChartXAxis, ChartXAxisItem, ChartYAxis, ChartYAxisItem } from '@progress/kendo-react-charts'
import NumberField from 'components/helper/NumberField'
import { Storage } from 'components/project/model/Storage'
import NumberInput from 'components/helper/NumberInput'
import StringField from '../../../helper/StringField'
import { StrValidation } from '../../../helper/StringValidation'
import { deserializeComplexCostTable, deserializeSensitivityVar, deserializeSizingVar, MultiYearKind } from '../../../../services/inputs'
import { CostTableType } from '../../model/CostTable'
import { EnergyMarket, getEnergyMarketKindLabel } from '../../model/EnergyMarket'
import { DatePicker } from '@progress/kendo-react-dateinputs'
import { DateFormat } from '../../model'
import { roundToDay } from '../../../../utils'
import { DefermentUnit } from '../../model/Deferment'
import ToolTipInfo from '../../../helper/ToolTipInfo'

interface StorageViewProps {
    model: Storage,
    index: number
    onDelete?: () => void,
    useCommaAsDecimalPoint: boolean
}

const StorageView = observer(({ model, index, onDelete, useCommaAsDecimalPoint }: StorageViewProps) => {
    const project = useProjectStore().project!
    const currency = useProjectStore().project!.currencySymbol ?? "$"
    const storageUnitsAux = ChangeUnitOptionSetCurrency(storageUnits, currency)
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    const status = project.status
    const dateFormatArray = Object.values(DateFormat)
    const defermentUnitArray = Object.values(DefermentUnit)
    const isValidDateRange = roundToDay(model.deferment.startDate) <= roundToDay(project.expectedDate)
    const projectStartDate = new Date(project.expectedDate)
    var projectEndDate = new Date(projectStartDate);
    var projectLifetime = project.lifetime
    var maxDeferment = model.deferment.periodUnit === 0 ? projectLifetime * 365 : model.deferment.periodUnit === 1 ? projectLifetime * 12 : projectLifetime
    projectEndDate.setFullYear(projectEndDate.getFullYear() + projectLifetime)
    var a = 'aaa'
    const gotToRevenuPage = async () => {
        status.goTo(1)
    }

    const handleSelectLibrary = (x: StorageSpec) => {
        model.setKind('library')
        model.setSpecs(x)
        model.setModel(x.name)
        model.setCustomAbbreviation(x.abbreviation)
        model.setAugmentationCost(x.augmentationCost)
        model.augmentationDegradationLimit = deserializeSensitivityVar(x.augmentationDegradationLimitSensitivity)
        model.cost.costTableType = x.useComplexCost ? CostTableType.complex : CostTableType.simple
        model.cost.simple.base.capital = model.cost.simple.base.capital = x.simpleCostTable.capital
        model.cost.simple.base.operating = model.cost.simple.base.operating = x.simpleCostTable.operating
        model.cost.simple.base.replacement = model.cost.simple.base.replacement = x.simpleCostTable.replacement
        model.cost.complex = deserializeComplexCostTable(x.complexCostTable)

        model.hasConverter = x.useDedicatedConverter
        model.converter.cost.costTableType = x.dedicatedConverter.cost.costTableKind as CostTableType
        model.converter.cost.simple.base.capital = model.converter.cost.simple.base.capital = Number(x.dedicatedConverter.cost.simpleCostTable?.capital)
        model.converter.cost.simple.base.operating = model.converter.cost.simple.base.operating = Number(x.dedicatedConverter.cost.simpleCostTable?.operating)
        model.converter.cost.simple.base.replacement = model.converter.cost.simple.base.replacement = Number(x.dedicatedConverter.cost.simpleCostTable?.replacement)
        model.converter.cost.complex = deserializeComplexCostTable(x.dedicatedConverter.cost.complexCostTable)
        model.converter.efficiency = deserializeSensitivityVar(x.dedicatedConverter.efficiency)
        model.converter.lifetime = deserializeSensitivityVar(x.dedicatedConverter.lifetime)
        model.converter.sizing = deserializeSizingVar(x.dedicatedConverter.size)
    }

    const [showImport, setShowImport] = useState(false)
    const handleShowImport = () => setShowImport(true)
    const handleHideImport = () => setShowImport(false)
    const handleSelectCustom = (content: string, specs: StorageSpec) => {
        setShowImport(false)
        model.setKind('custom')
        model.setSpecs(specs)
        model.setCustomData(content)
        if (model.customName === `Storage ${index + 1}`)
            model.setCustomName(specs.name)
        if (model.customAbbreviation === `Storage ${index + 1}`)
            model.setCustomAbbreviation(specs.abbreviation)
    }

    const converterLabel = project.bus === 'ac' ? 'Storage DC/AC Converter' : 'Storage DC/DC Converter'

    // fetch available storage options
    const [modelOptions, setModelOptions] = useState<StorageSpec[]>([])
    const [userModelOptions, setUserModelOptions] = useState<StorageSpec[]>([])

    useEffect(() => {
        let cancel = false
        const libStorage = async () => {
            let rv = await api.listStorageSpecs()
            if (!cancel) { setModelOptions(rv) }
        }
        libStorage().then()
        const userStorage = async () => {
            let rv = await api.listUserStorageSpecs()
            if (!cancel && rv.length > 0) { setUserModelOptions(rv) }
        }
        userStorage().then()
        return () => { cancel = true }
    }, [])

    model.customName = model.customName === '' ? `Energy Storage ${index + 1}` : model.customName
    model.customAbbreviation = model.customAbbreviation === '' ? `Storage ${index + 1}` : model.customAbbreviation

    const handleSetUseCycleLimitPerDay = async (value: boolean) => await model.setUseCycleLimitPerDay(value)
    const handleSetOptimizeCycleLimitPerDay = async (value: boolean) => await model.setOptimizeCycleLimitPerDay(value)

    const onSelectMarket = () => {
        model.setUseAuxiliaryLoadFlatPrice(false)
        if (model.energyMarket === null || !project.energyMarkets.includes(model.energyMarket)) {
            model.setEnergyMarket(project.energyMarkets[0])
        }
    }
    const onSelectFlat = () => {
        model.setUseAuxiliaryLoadFlatPrice(true)
        model.setEnergyMarket(null)
    }

    const getEscalationKindLabel = (energyMarket: EnergyMarket): string => {
        const v = energyMarket.priceEscalator
        if (!v.enabled) return 'No escalation defined in'
        switch (v.multiYearKind) {
            case MultiYearKind.linear:
                return `${energyMarket.priceEscalator.linearValue}% escalation, as defined in`
            case MultiYearKind.custom:
                return 'Custom escalation, as defined in'
        }
    }

    const setHasNoDeferment = () => {
        model.setHasDeferment(false)
    }

    const setHasDefermentPeriod = () => {
        model.setHasDeferment(true)
        model.deferment.setIsExactDate(false)
    }
    const setHasDefermentDate = () => {
        model.setHasDeferment(true)
        model.deferment.setIsExactDate(true)
    }
    return (
        <>
            <div className='d-flex align-items-center mb-2'>
                <label className='mr-3 mb-0'>Storage Type: </label>
                <Dropdown>
                    <Dropdown.Toggle variant='outline-primary' className='pillButton' id={`dropdown-basic-${index}`}>
                        {model.kind === 'library' ? model.model : `Imported: ${model.customName}`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {userModelOptions.length > 0 && userModelOptions.map(x =>
                            <Dropdown.Item key={x.name} onClick={() => handleSelectLibrary(x)}><b>{x.name}</b></Dropdown.Item>)}
                        <Dropdown.Divider />
                        {modelOptions.map(x =>
                            <Dropdown.Item key={x.name} onClick={() => handleSelectLibrary(x)}>{x.name}</Dropdown.Item>)}
                        <Dropdown.Divider />
                        <Dropdown.Item onClick={handleShowImport}>Import...</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <div className='ml-5'>
                    <StringField label="Storage Name" value={model.customName} onChange={x => model.setCustomName(x)} validator={StrValidation.maxLen(40)} />
                </div>
                <div>
                    <StringField label="Abbreviation" value={model.customAbbreviation} onChange={x => model.setCustomAbbreviation(x)} validator={StrValidation.maxLen(20)} />
                </div>
                <div className='m-auto' />
                <button className='btn btn-outline-danger pillButton' onClick={() => onDelete?.()}><GoX /></button>
            </div>

            <StorageImportModal show={showImport} onCancel={handleHideImport} onSelect={handleSelectCustom} />
            <div className='form-group d-flex mb-2'>
                <div className='border rounded w-50 mr-1 p-2'>
                    <StorageSpecsView intlCulture={intlCulture} specs={model.specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    <hr />
                    <SizingView id={model.id} model={model.sizing} absoluteUnits='Units' validator={Validation.integer} componentId={`storageSizeUnits-${index}`} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    {/*<div className='d-flex flex-row align-items-start ml-2 mt-4'>*/}
                    {/*    <Form.Check id={model.id + '-no-deferment'} type='radio' label='No Deferment'*/}
                    {/*        disabled={project.earlyAnalysis}*/}
                    {/*        className='mr-2 align-items-center' custom*/}
                    {/*        checked={!model.hasDeferment}*/}
                    {/*        onChange={ev => setHasNoDeferment()} />*/}
                    {/*    <Form.Check id={model.id + '-use-deferment-period'} type='radio' label='Set Deferment Period' custom*/}
                    {/*        disabled={project.earlyAnalysis}*/}
                    {/*        checked={model.hasDeferment && !model.deferment.isExactDate}*/}
                    {/*        onChange={ev => setHasDefermentPeriod()} className='ml-2' />*/}
                    {/*    <Form.Check id={model.id + '-use-start-date'} type='radio' label='Defer Until' custom*/}
                    {/*        disabled={project.earlyAnalysis}*/}
                    {/*        checked={model.hasDeferment && model.deferment.isExactDate}*/}
                    {/*        onChange={ev => setHasDefermentDate()} className='ml-3 mr-3' />*/}
                    {/*    {project.earlyAnalysis && <ToolTipInfo label={'Disabled for early-stage analysis, because only a single year is simulated'} />}*/}
                    {/*    {!project.earlyAnalysis && <ContextHelp helpId='STORAGEDEFERMENT' /> }*/}
                    {/*</div>*/}
                    {/*<div className='d-flex flex-row no-gutters align-items-start ml-2 mt-3'>*/}
                    {/*    {!model.deferment.isExactDate && <>*/}
                    {/*        <div className='col-12'>*/}
                    {/*            <div className='form-group row align-items-center'>*/}
                    {/*                <div className='col-2'>{'Defered period'}</div>*/}
                    {/*                <div className='col-2'>*/}
                    {/*                    <NumberInput value={model.deferment.period}*/}
                    {/*                        onChange={x => model.deferment.setPeriod(x)}*/}
                    {/*                        validator={Validation.range(0, maxDeferment - 1, true, false, true)}*/}
                    {/*                        disabled={!model.hasDeferment || project.earlyAnalysis}*/}
                    {/*                        id={model.id + '-deferment-period'} */}
                    {/*                        useCommaAsDecimalPoint={useCommaAsDecimalPoint} />*/}
                    {/*                </div>*/}
                    {/*                <div className='col-3'>*/}
                    {/*                    <Dropdown>*/}
                    {/*                        <Dropdown.Toggle disabled={!model.hasDeferment || project.earlyAnalysis} variant={'outline-primary'}*/}
                    {/*                            className='dropdown-selector-toggle d-flex align-items-center justify-content-between pillButton' style={{ width: '120px' }}>*/}
                    {/*                            <span className='dropdown-selector-label text-truncate'>{defermentUnitArray[model.deferment.periodUnit]}</span>*/}
                    {/*                        </Dropdown.Toggle>*/}
                    {/*                        <Dropdown.Menu className='dropdown-selector-menu'>*/}
                    {/*                            {defermentUnitArray.map((option, i) =>*/}
                    {/*                                <Dropdown.Item key={i}*/}
                    {/*                                    className={option === defermentUnitArray[i] ? 'active' : ''}*/}
                    {/*                                    onClick={() => model.deferment.setPeriodUnit(i)}>*/}
                    {/*                                    {option}*/}
                    {/*                                </Dropdown.Item>)}*/}
                    {/*                        </Dropdown.Menu>*/}
                    {/*                    </Dropdown>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </>*/}
                    {/*    }*/}
                    {/*    {model.deferment.isExactDate && <div className='form-group row align-items-center'>*/}
                    {/*        <label className='col-5'>Start Date</label>*/}
                    {/*        <div className='col-7'>*/}
                    {/*            <DatePicker format={dateFormatArray[project.dateFormat]}*/}
                    {/*                disabled={!model.hasDeferment}*/}
                    {/*                value={model.deferment.startDate}*/}
                    {/*                min={projectStartDate}*/}
                    {/*                max={projectEndDate}*/}
                                    
                    {/*                onChange={dateVal => dateVal.target.value != null && model.deferment.setStartDate(dateVal.target.value)} />*/}
                    {/*        </div>*/}
                    {/*    </div>}*/}
                    {/*</div>*/}
                </div>
                <div className='border rounded w-50 ml-1 p-2'>
                    <CostTableView cost={model.cost} id={model.id}
                        showDetailedCostTable={true} showDetailedReplacement={false} unitsSet={storageUnitsAux}
                        capitalUnits={`${currency}/kW`} operatingUnits={`${currency}/kW/yr`}
                        simpleCostLabel='Cost Sensitivity Analysis'
                        complexCostLabel='Cost Breakdown' isStorage={true} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    <MultiYearView model={model.omEscalator} label='O&M cost escalator (%/yr)'
                        id={`storage-om-escalator-${model.id}`} />
                    <NumberField value={model.augmentationCost}
                        onChange={x => model.setAugmentationCost(x)}
                        label={`Augmentation (${currency}/kW)`}
                        disabled={model.augmentationPriceDecline.enabled && model.augmentationPriceDecline.multiYearKind === MultiYearKind.custom}
                        id={model.id + '-augmentation'}
                        useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    <MultiYearView model={model.augmentationPriceDecline}
                        label={model.augmentationPriceDecline.multiYearKind === MultiYearKind.linear ? 'Augmentation price decline (%/yr)' : 'Augmentation price decline ($/kWh)'}
                        id={`storage-augmentation-price-decline-${model.id}`} customLabel='Price decline by year' />
                    <SensitivityVarView model={model.augmentationDegradationLimit}
                        label='Augmentation degradation limit (%)' units='%' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    <div className='form-group row align-items-center mt-1'>
                        <div className='col-5'>
                            <Form.Check custom label='Limit Cycles per Day' id={`useCycleLimitPerDay-${index}`}
                                checked={model.useCycleLimitPerDay}
                                onChange={ev => handleSetUseCycleLimitPerDay(ev.target.checked)}
                            />
                        </div>
                        <div className='col-4 mt-3'>
                            <SensitivityVarView model={model.cycleLimitPerDay}
                                label='Limit Cycles per Day' units='' hideLabel={true} isDisabled={!model.useCycleLimitPerDay}
                                useCommaAsDecimalPoint={useCommaAsDecimalPoint}
                            />
                        </div>
                        <div className='col-1 ml-0'>
                            <ContextHelp helpId={'STORAGECYCLE'} />
                        </div>
                        <div className='ml-3'>
                            <Form.Check custom label='Make Cycle Limit Behave More Optimally (Slower)' id={`optimizeCycleLimitPerDay-${index}`}
                                checked={model.optimizeCycleLimitPerDay}
                                onChange={ev => handleSetOptimizeCycleLimitPerDay(ev.target.checked)}
                                disabled={!model.useCycleLimitPerDay}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className='border rounded p-2 mb-2'>
                <div className='d-flex mb-2 justify-content-start'>
                    <Form.Check className='mr-2' custom label={converterLabel} id={`storage-hasConverter-${model.id}`} type='switch'
                        checked={model.hasConverter}
                        onChange={ev => { model.setHasConverter(ev.target.checked); }} />
                    <ContextHelp helpId={'STORAGECONV'} />
                </div>
                {model.hasConverter &&
                    <div className='mt-2'>
                        <InverterView model={model.converter} label={converterLabel}
                            relativeUnits='Ratio'
                            relativeLabel='Size Relative to Storage Capacity (C-rate or 1/hr rate)'
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>}
            </div>
            {<div className='border rounded p-2 mb-2'>
                <div className='d-flex mb-2 justify-content-start'>
                    <Form.Check className='mr-2' custom label='Model an Auxiliary Load' id={`use_auxiliary_load_${model.id}`} type='switch'
                        checked={model.useAuxiliaryLoad}
                        onChange={ev => { model.setUseAuxiliaryLoad(ev.target.checked); }} />
                    <ContextHelp helpId={'AUXLOAD'} />
                </div>
                {model.useAuxiliaryLoad && <>
                    <div className='d-flex mb-2 justify-content-between'>
                        <SensitivityVarView model={model.auxiliaryLoadSensitivity} label='Auxiliary Load (%)' units='%' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>
                    <div className='d-flex mb-2 justify-content-between'>
                        <Form.Check custom label='Storage Self-Serves Auxiliary Load' id={`storage_serves__auxiliary_load_${model.id}`} type='switch'
                            checked={model.storageServesAuxiliaryLoad}
                            onChange={ev => { model.setStorageServesAuxiliaryLoad(ev.target.checked); }} />
                        {/*    <ContextHelp helpId={'STORAGECONV'} />*/}
                    </div>
                    <div className='form-group row align-items-center'>
                        <div className='col-1'>
                            <div className='d-flex align-items-center'>
                                <Form.Check id={`aux-load-price-market-${index}`}
                                    custom label='' type='radio'
                                    disabled={project.energyMarkets.length === 0 || model.storageServesAuxiliaryLoad}
                                    checked={!model.useAuxiliaryLoadFlatPrice}
                                    onChange={ev => ev.target.checked && onSelectMarket()} />
                                <Dropdown>
                                    <Dropdown.Toggle className='pillButton' variant='outline-primary' id={`dropdown-basic-${index}`} disabled={model.storageServesAuxiliaryLoad || model.useAuxiliaryLoadFlatPrice}>
                                        {model.energyMarket?.name ?? 'Select Energy Market'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {project.energyMarkets.map(x =>
                                            <Dropdown.Item key={x.id} onClick={() => { model.setEnergyMarket(x) }}>
                                                {getEnergyMarketKindLabel(x.kind)}
                                            </Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        {model.energyMarket &&
                            <div className='col-7 two'>
                                <p>{getEscalationKindLabel(model.energyMarket)} <b className='text-primary' style={{ cursor: 'pointer' }} onClick={() => gotToRevenuPage()}>Energy Market Section</b></p>
                            </div>}
                    </div>
                    <div className='form-group row align-items-center'>
                        <div className='col-2'>
                            <Form.Check id={`aux-load-price-flat-${index}`}
                                type='radio' label={`Flat rate (${currency}/MWh)`} custom
                                checked={model.useAuxiliaryLoadFlatPrice}
                                disabled={model.storageServesAuxiliaryLoad}
                                onChange={ev => ev.target.checked && onSelectFlat()} />
                        </div>
                        <div className='col-1'>
                            <NumberInput disabled={model.storageServesAuxiliaryLoad || !model.useAuxiliaryLoadFlatPrice}
                                value={model.auxiliaryLoadFlatPrice}
                                onChange={x => model.setAuxiliaryLoadFlatPrice(x)} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        </div>
                    </div>
                    <div className='form-group row align-items-center'>
                        <div className='col-5'>
                            <MultiYearView model={model.auxiliaryLoadPriceMultiYear} label='Flat Price Escalator (%/yr)' disabled={model.storageServesAuxiliaryLoad || !model.useAuxiliaryLoadFlatPrice}
                                id={`auxLoadFlatPriceEscalator-${model.id}`} />
                        </div>
                    </div>
                </>}
            </div>}
        </>
    )
})

export default StorageView

interface StorageImportModalProps {
    show: boolean
    onSelect: (content: string, specs: StorageSpec) => void
    onCancel: () => void
}

type State
    = { kind: 'blank' }
    | { kind: 'importing', source: string, content: string }
    | { kind: 'ready', source: string, content: string, specs: StorageSpec }
    | { kind: 'error', source: string, message: string }

const StorageImportModal = ({ show, onSelect, onCancel }: StorageImportModalProps) => {
    const [state, setState] = useState<State>({ kind: 'blank' })
    useEffect(() => setState({ kind: 'blank' }), [show])

    const handleSelect = async (content: string, file: File) => {
        const source = file.name
        try {
            setState({ kind: 'importing', source, content })
            const r = await api.importStorage(content)
            if (!r.success) {
                console.error(r.message)
                setState({ kind: 'error', source, message: r.message })
                return
            }
            setState({ kind: 'ready', source, content, specs: r.spec! })
        } catch (error) {
            console.error(error)
            setState({ kind: 'error', source, message: `Service unavailable` })
            return
        }
    }

    const handleImport = () => {
        if (state.kind !== 'ready') return
        const { content, specs } = state
        onSelect(content, specs)
    }

    return (
        <Modal show={show} onHide={onCancel} size='lg'>
            <Modal.Header closeButton onHide={onCancel}>
                Import Battery Specifications
            </Modal.Header>
            <Modal.Body>
                <div className='d-flex align-items-center flex-row'>
                    <FilePicker onSelect={handleSelect} label='Import *.xml' types='.xml' />
                    <div className='mx-2' />
                    {state.kind === 'ready' && <div>
                        <div>Imported: <strong>{state.source}</strong></div>
                        <div>Battery: <strong>{state.specs.name}</strong></div>
                    </div>}
                    {state.kind === 'error' && <div className='text-danger'>
                        <div>Import error:</div>
                        <div>File format unsupported.</div>
                        <div><strong>{state.source}</strong> must be an Advanced Storage Model</div>
                    </div>}
                </div>
                <div className='my-2' />
                <div>Contact <a href='mailto:support@homerenergy.com'>support@homerenergy.com</a> for assistance in creating a customized .xml file for your battery specifications.
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Spinner hidden={state.kind !== 'importing'} animation='border' size='sm' className='text-primary' />
                <Button className='pillButton' onClick={onCancel} variant='outline-primary'>Cancel</Button>
                <Button className='pillButton' onClick={handleImport} disabled={state.kind !== 'ready'}>Add Storage</Button>
            </Modal.Footer>
        </Modal>
    )
}


interface StorageSpecsViewProps {
    specs?: StorageSpec
    useCommaAsDecimalPoint: boolean
    intlCulture: string
}

const StorageSpecsView = observer(({ specs, useCommaAsDecimalPoint, intlCulture }: StorageSpecsViewProps) => {
    const intl = new IntlService(intlCulture)

    const [more, setMore] = useState(false)
    const less = !more

    const getCharge = () => {
        return (100 - specs!.minimumStateOfCharge ?? 0)
    }
    return specs ? (
        <div>
            <div className='font-weight-bold'>Parameters for {specs.name}</div>

            {less && <>
                <div>Nominal Capacity per unit: {intl.formatNumber(specs.nominalCapacity, 'n0')} kWh</div>
                <div>Nominal Power per unit: {intl.formatNumber(specs.nominalPower, 'n0')} kW</div>
                <div className='d-flex align-items-center'>Roundtrip Efficiency (%): <NumberInput value={specs.roundtripEfficiency} onChange={x => { specs.roundtripEfficiency = x }} className="col-md-4 ml-2" useCommaAsDecimalPoint={useCommaAsDecimalPoint}></NumberInput></div>
                <div>Allowable Range of Charge: {intl.formatNumber(getCharge(), 'n0')}%</div>
            </>}
            {more && <>
                <div>Nominal Capacity per unit: {intl.formatNumber(specs.nominalCapacity, 'n0')} kWh</div>
                <div>Nominal Power per unit: {intl.formatNumber(specs.nominalPower, 'n0')} kW</div>
                <div>Maximum Capacity per unit: {intl.formatNumber(specs.maximumCapacity, 'n0')} Ah</div>
                <div>Nominal Voltage: {intl.formatNumber(specs.nominalVoltage, 'n0')} V</div>
                <div>Charge Current: {intl.formatNumber(specs.chargeCurrent, 'n0')} A</div>
                <div>Discharge Current: {intl.formatNumber(specs.dischargeCurrent, 'n0')} A</div>
                <div className='d-flex align-items-center'>Roundtrip Efficiency (%): <NumberInput value={specs.roundtripEfficiency} onChange={x => { specs.roundtripEfficiency = x }} className="col-md-4 ml-2" useCommaAsDecimalPoint={useCommaAsDecimalPoint} ></NumberInput></div>
                <div>Allowable Range of Charge: {intl.formatNumber(getCharge(), 'n0')}%</div>
                <div className='d-flex align-items-center'>Minimum State of Charge (%): <NumberInput value={specs.minimumStateOfCharge} onChange={x => { specs.minimumStateOfCharge = x }} className="col-md-4 ml-2" useCommaAsDecimalPoint={useCommaAsDecimalPoint}></NumberInput></div>
                <div>Estimated lifetime throughput: {intl.formatNumber(specs.estimatedLifetimeThroughput, 'n0')} kWh</div>
                <div>Lifetime curve:</div>
                <div>
                    <LifetimeCurveView points={specs.lifetimeCurve} />
                </div>
            </>}
            <button className='btn btn-sm btn-link btn-no-focus align-self-start pillButton'
                onClick={() => setMore(!more)}>
                {more ? 'Show Less' : 'Show More'}
            </button>
        </div>
    ) : null
})


interface LifetimeCurveViewProps {
    points: StorageCurveItem[]
}

export const LifetimeCurveView = ({ points }: LifetimeCurveViewProps) => {
    return (
        <Chart>
            <ChartXAxis>
                <ChartXAxisItem title={{ text: 'Depth of Discharge (%)' }} />
            </ChartXAxis>
            <ChartYAxis>
                <ChartYAxisItem title={{ text: 'Cycles to Failure' }} />
            </ChartYAxis>
            <ChartSeries>
                <ChartSeriesItem key='annual' type='scatterLine' data={points} xField='x' yField='y' />
            </ChartSeries>
        </Chart>
    )
}
