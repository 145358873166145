import {createContext, PropsWithChildren, useContext} from 'react'
import { UserStorage } from './UserStorage'


const UserStorageContext = createContext<UserStorage>({} as UserStorage)

interface UserStorageProviderProps extends PropsWithChildren<{}> {
    value: UserStorage
}

const UserStorageProvider = ({ children, value }: UserStorageProviderProps) => {
    return (
        <UserStorageContext.Provider value={value}>
            {children}
        </UserStorageContext.Provider>
    )
}

export const useUserLibraryStore = () => useContext(UserStorageContext)

export default UserStorageProvider
