import {GoX} from 'react-icons/all'
import React from 'react'

interface ButtonDeleteProps {
    onClick?: () => void
}

const ButtonDelete = ({onClick}: ButtonDeleteProps) =>
    <button className='btn btn-sm btn-outline-primary border-0 pillButton' onClick={onClick}>
        <GoX/>
    </button>

export default ButtonDelete
