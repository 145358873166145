import {useEffect} from 'react'
import {useProjectStore} from './ProjectProvider'
import NoMatch from '../helper/NoMatch'
import ResultsPage from './pages/ResultsPage'
import SetupPage from './pages/SetupPage'
import RevenuePage from './pages/RevenuePage'
import EquipmentPage from './pages/EquipmentPage'
import OtherPage from './pages/OtherPage'
import {globalHistory, Link, Redirect, RouteComponentProps, Router} from '@reach/router'
import {ProjectNavFooter, ProjectNavHeader} from 'components/project/ProjectNavigationBar'
import {observer} from 'mobx-react-lite'
import {useInternationalization} from 'Kendo-Intl-5'
import LogOut from 'components/project/pages/SharedComponents/LogOut'
import ToolTipInfo from '../helper/ToolTipInfo'
import { Button, Modal } from 'react-bootstrap'
import { projectListStatus } from '../../App'


interface ProjectViewProps extends RouteComponentProps {
    projectId?: string
    setIsProjectSaving: (x: projectListStatus) => void
}

const ProjectView = observer(({ projectId, setIsProjectSaving }: ProjectViewProps) => {
    const id = projectId!
    const store = useProjectStore()
    const ready = store.project !== null

    const unlockProject = () => {
        store.updateLock(false)
    }
    const cleanup = () => {
        window.removeEventListener('beforeunload', unlockProject);
    }
    useEffect(() => {
        window.addEventListener('beforeunload', unlockProject);
        return cleanup;
    }, []);

    useEffect(() => {
        store.loadProject(id).then()
    }, [id, store])

    return (
        <div className='container-fluid h-100 d-flex flex-column'>
            {!ready ?
                <h1>Loading...</h1> :
                <>
                    <ProjectSaver setIsProjectSaving={setIsProjectSaving} />
                    <div className='row'>
                        <div className='col'>
                            <div>
                                <ProjectNavHeader/>
                            </div>
                        </div>
                    </div>
                    <div className='py-2'/>
                    <div className='vh-100 overflow-hidden bg-white rounded-lg shadow-page'>
                        <div className='col d-flex flex-column pr-0 pl-0 h-100'>
                            <Router className='h-100'>
                                <Redirect from='/' to='setup' noThrow/>
                                <SetupPage path='setup'/>
                                <RevenuePage path='revenue'/>
                                <EquipmentPage path='equipment'/>
                                <OtherPage path='other'/>
                                <ResultsPage path='results/*'/>
                                <NoMatch default/>
                            </Router>
                        </div>
                    </div>
                    <div className='py-2'/>
                    <div className='row'>
                        <div className='col'>
                            <div>
                                <ProjectNavFooter/>
                            </div>
                        </div>
                    </div>
                </>}
        </div>
    )
})

export default ProjectView


interface ProjectHeaderViewProps extends RouteComponentProps {}

export const ProjectHeaderView = observer((_: ProjectHeaderViewProps) => {
    const intl = useInternationalization()
    const projectStore = useProjectStore()
    const info = projectStore.info
    const readOnly = projectStore.readOnly
    const handleUlockProject = () => {
        projectStore.updateLock(false, true)
    }
    return (<>
        {info &&
            <div className='d-flex'>
                <div className='text-body mr-1 align-self-center'>{info.name}</div>
                <small className='text-muted align-self-center'>(last saved {intl.formatDate(new Date(info.updated), 'g')})</small>
                
            </div>}
        <div className='d-flex justify-content-between'>
            {info && readOnly && <>
                <p className='text-danger align-self-center mr-2'>Read Only Mode</p>
                <ToolTipInfo label={'Read Only Mode is active because the same project is open in another tab, window, or by another user. Clicking the Enable Editing button will let you save your changes, but will put other users in Read Only Mode.'} />
                <button id='forceUnlockProject' className='btn btn-sm d-flex align-items-center ml-1 mr-1 pillButton' onClick={handleUlockProject}>Enable Editing</button>
            </>}
            <a className="btn btn-sm mr-1" href="https://support.ul-renewables.com/homer-manual-front/index.html" target="_blank" rel="noreferrer">User Manual</a>
            <a className="btn btn-sm mr-1" href="https://ulsolutions.qualtrics.com/jfe/form/SV_2mDugFCSdYwbEdE" target="_blank" rel="noreferrer">Request a Feature</a>
            <Link id='storageLibraryButton' to='/storageLibrary' className='btn btn-sm mr-1 pillButton'>Storage Library</Link>
            <Link id='projectListingButton' to='/library' className='btn btn-sm pillButton'>Project Listing</Link>
            <div className='ml-1'>
                <LogOut/>
            </div>
        </div>
    </>)
})

interface ProjectSaverProps {
    setIsProjectSaving: (x: projectListStatus) => void
}
// check URL change; if leaving editor, then save data
const ProjectSaver = ({setIsProjectSaving}: ProjectSaverProps) => {
    const store = useProjectStore()

    useEffect(() => {
        return globalHistory.listen(async (ev) => {
            // leaving project editor? => save project
            const leaving = !ev.location.pathname.startsWith('/project')
            if (leaving) {
                setIsProjectSaving('saving')
                store.readOnly = null
                await store.closeProject()
                //await store.updateLock(false)
                setIsProjectSaving('loading')
            }
        })
    }, [store])

    return null
}

interface UnlockProjectModalProps {
    show: boolean
    onClose: () => void
}

export const UnlockProjectModal = ({ show, onClose }: UnlockProjectModalProps) => {

    return (
        <Modal show={show} onHide={onClose}>
            <Modal.Header className='alert alert-warning' closeButton onHide={onClose}>
                Read Only Mode
            </Modal.Header>
            <Modal.Body>Someone else is editing this project. If you made changes on the previous step, they weren't saved.</Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={onClose}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}
