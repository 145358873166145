import {action, makeObservable, observable} from 'mobx'

export enum DefermentUnit {
    day = 'Day',
    month = 'Month',
    year = 'Year',
}

export class Deferment {
    constructor() {
        makeObservable(this)
    }
    @observable period: number = 1
    @observable periodUnit: number = 0
    @observable isExactDate: boolean = false
    @observable startDate: Date = new Date()

    @action setPeriod(x: number) { this.period = x }
    @action setPeriodUnit(x: number) { this.periodUnit = x }
    @action setIsExactDate(x: boolean) { this.isExactDate = x }
    @action setStartDate(x: Date) { this.startDate = x }
}
