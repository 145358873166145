import '@progress/kendo-theme-bootstrap/dist/all.scss'
import './ResultTableView.scss'
import React, { useState } from 'react'
import { useProjectStore } from 'components/project/ProjectProvider'
import { observer } from 'mobx-react-lite'
import { IntlService } from 'Kendo-Intl-5'
import { Button, Row, Table } from 'react-bootstrap'
import ToolTipInfo from '../../../helper/ToolTipInfo'

interface ResultTableProps {
    onSelect?: (index: number) => void
    resultType: ResultTableType
    intlCulture: string
    calculate: () => void
}

const ResultTableView = observer(({ onSelect, resultType, intlCulture, calculate }: ResultTableProps) => {
    const dbProjectStatus = useProjectStore().dbProjectStatus
    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"
    const intl = new IntlService(intlCulture)
    const table = resultType === 'sensitivity' ? project.results.sensitivityTable : project.results.optimizationTable
    const [selectedRow, setSelectedRow] = useState(-1)
    const onSelectRow = (i: number, doubleClick: boolean) => {
        if (dbProjectStatus === 1 && resultType !== 'sensitivity') return
        setSelectedRow(i)
        if (onSelect !== undefined && (doubleClick || resultType === 'compareEconomics'))
            onSelect(i)
    }

    return (<>
        <div className='ml-2 mt-0 col-11'>
            <Table className='styled-table' responsive={true}>
                <thead>
                    <tr>
                        {resultType === 'sensitivity' && < th colSpan={table.header.sensitivity?.length}>Sensitivity</th>}
                        <th colSpan={table.header.optimization?.length}>Optimization</th>
                        <th colSpan={table.header.revenue?.length}>Revenue</th>
                        <th colSpan={2}>Cost</th>
                        <th colSpan={4}>Project Economics</th>
                    </tr>
                    <tr>
                        {resultType === 'sensitivity' && table.header.sensitivity?.map(x =>
                            <th>{`${x.name} (${x.units})`}</th>
                        )}
                        {table.header.optimization?.map(x =>
                            <th>{`${x.name} (${x.units.join('/')})`}</th>
                        )}
                        {table.header.revenue?.map(x =>
                            <th>{`${x.name} (${currency}/yr)`}</th>
                        )}
                        <th>Capital Expenses</th>
                        <th style={{ width: '150px' }}>Operating Expenses Excl. Augmentation</th>
                        <th>NPV</th>
                        <th>LCOE</th>
                        <th>IRR</th>
                        <th>Payback</th>
                    </tr>
                </thead>
                <tbody>
                    {table.items?.map((x, i) =>
                        <tr style={{ backgroundColor: `${(i === selectedRow ? 'turquoise' : '')}` }} onClick={() => onSelectRow(i, false)} onDoubleClick={() => onSelectRow(i, true)}>
                            {resultType === 'sensitivity' && x.sensitivity?.map(y => <td>{`${intl.formatNumber(y, 'n1')}`}</td>)}
                            {x.optimization?.map(y => <td>{(y.length === 1 && y[0] != null ? intl.formatNumber(y[0], 'n1') : y.length === 2 && y[0] != null && y[1] != null ? `${intl.formatNumber(y[0], 'n1')}/${intl.formatNumber(y[1], 'n1')}` : '')}</td>)}
                            {x.revenue?.map(y => <td>{`${intl.formatNumber(y, 'n2')}`}</td>)}
                            <td>{intl.formatNumber(x.capex, 'n2')}</td>
                            <td>{intl.formatNumber(x.opex, 'n2')}</td>
                            <td>{intl.formatNumber(x.npv, 'n2')}</td>
                            <td>{(x.lcoe != null ? intl.formatNumber(x.lcoe, 'n2') : '-')}</td>
                            <td>{(x.irr != null ? intl.formatNumber(x.irr, 'n2') : '-')}</td>
                            <td>{(x.payback != null ? intl.formatNumber(x.payback, 'n1') : '-')}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
            {resultType !== 'compareEconomics' && onSelect !== undefined &&
                <Row className='ml-1' style={{ verticalAlign: 'middle' }}>
                    {dbProjectStatus !== 1 && <Button disabled={selectedRow === -1} id='selectRow' variant='primary' size='sm' onClick={() => onSelect(selectedRow)} className='mt-2 pillButton'>{(resultType === 'sensitivity' ? 'Select Sensitivity' : 'Get Detailed Results')}
                    </Button>}
                    {selectedRow === -1 && dbProjectStatus !== 1 &&
                        <div className='mt-2 ml-2'>
                            <ToolTipInfo label={'Select simulation'} />
                        </div>}
                    {dbProjectStatus === 1 && <div className='ml-2'>
                        <Row><p className='mb-3'>Inputs have changed, these results are not up to date. Please recalculate to view updated results and detailed calculations.</p></Row>
                        <Row><button className='btn btn-primary pillButton' onClick={calculate}>Recalculate</button></Row>
                    </div>}
                </Row>}
        </div>
    </>
    )
})

export default ResultTableView

type ResultTableType = 'optimization' | 'sensitivity' | 'compareEconomics'
