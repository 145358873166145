import {IntlService} from 'Kendo-Intl-5'
import {StorageSpec} from 'services/api'
import {LifetimeCurveView} from 'components/project/pages/equipment/StorageView'

interface ResultStorageSpecTableProps {
    spec?: StorageSpec
    intlCulture: string
}

interface RowProps {
    label: string
    value: string
}

const Row = ({label, value}: RowProps) => (
    <tr>
        <td>{label}</td>
        <td>{value}</td>
    </tr>
)

const ResultStorageSpecTable = ({ spec, intlCulture }: ResultStorageSpecTableProps) => {
    const intl = new IntlService(intlCulture)

    const getCharge = () => {
        return (100 - spec!.minimumStateOfCharge ?? 0)
    }

    if (!spec) return null

    return (<>
        <div className='d-flex'>
            <div className='my-2 font-weight-bold'>Parameters for {spec.name}</div>
        </div>
        <table className='table table-bordered table-responsive-sm small'>
            <tbody>
                <Row label='Nominal Capacity per unit' value={intl.formatNumber(spec.nominalCapacity / 1000, 'n2') + ' MWh'}/>
                <Row label='Nominal Power per unit' value={intl.formatNumber(spec.nominalPower / 1000, 'n2') + ' MW'}/>
                <Row label='Maximum Capacity per unit' value={intl.formatNumber(spec.maximumCapacity, 'n0') + ' Ah'}/>
                <Row label='Nominal Voltage' value={intl.formatNumber(spec.nominalVoltage, 'n0') + ' V'}/>
                <Row label='Charge Current' value={intl.formatNumber(spec.chargeCurrent, 'n0') + ' A'}/>
                <Row label='Discharge Current' value={intl.formatNumber(spec.dischargeCurrent, 'n0') + ' A'}/>
                <Row label='Roundtrip Efficiency' value={intl.formatNumber(spec.roundtripEfficiency, 'n0') + '%'}/>
                <Row label='Allowable Range of Charge' value={intl.formatNumber(getCharge(), 'n0') + '%'}/>
                <Row label='Minimum State of Charge' value={intl.formatNumber(spec.minimumStateOfCharge, 'n0') + '%'} />
                <Row label='Estimated lifetime throughput' value={intl.formatNumber(spec.estimatedLifetimeThroughput, 'n0') + ' kWh'}/>
            </tbody>
        </table>
        <div>Lifetime curve:</div>
        <div>
            <LifetimeCurveView points={spec.lifetimeCurve}/>
        </div>
    </>)
}

export default ResultStorageSpecTable
