import {RouteComponentProps} from '@reach/router'
import {useProjectStore} from 'components/project/ProjectProvider'
import TimeSeriesViewer from 'components/project/pages/Results/time-series/TimeSeriesViewer'


interface ResultTimeSeriesSectionProps extends RouteComponentProps {
    showBaseYearAsYearZero: boolean
    baseYear: number
}


const ResultTimeSeriesSection = ({showBaseYearAsYearZero, baseYear}: ResultTimeSeriesSectionProps) => {
    const project = useProjectStore().project!
    const details = project.results.details
    return (<TimeSeriesViewer model={details.timeSeriesViewerModel} showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={baseYear} />)
}

export default ResultTimeSeriesSection
