import { useEffect, useState } from 'react'
import api from 'services/api'
import { Link, RouteComponentProps } from '@reach/router'
import './UserLibraryView.scss'
import { Button, Col, Container, Form, Modal, Nav, Row, Spinner, Tab, Tabs } from 'react-bootstrap'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import envVariables from 'env-variables.json'
import { IntlProvider, LocalizationProvider } from 'Kendo-Intl-5'
import StringInput from '../helper/StringInput'
import NumberInput from '../helper/NumberInput'
import { useUserLibraryStore } from './UserStorageProvider'
import StorageLibraryGeneralTab from './UserStorageLibraryGeneralTabView'
import StorageLibraryFuncModelTab from './UserStorageLibraryFuncModelTabView'
import { observer } from 'mobx-react-lite'
import { SerializeStorageSpecs } from './UserStorage'
import StorageLibraryTempCapacityTab from './UserStorageLibraryTempCapacityTabView'
import StorageLibraryCycleLifetimeTab from './UserStorageLibraryCycleLifetimeTabView'
import StorageLibraryTempLifetimeTab from './UserStorageLibraryTempLifetimeTabView'
import StorageLibraryThermalTab from './UserStorageLibraryThermalTabView'
import StorageLibraryAugmentationTab from './UserStorageLibraryAugmentationTabView'
import StorageLibraryConverterTab from './UserStorageLibraryConverterTabView'
import StorageLibraryDefaultsTab from './UserStorageLibraryDefaultsTabView'

interface UserLibraryViewProps extends RouteComponentProps {
    id?: string
}

const UserStorageLibraryComponentEditView = observer(({ id }: UserLibraryViewProps) => {
    const intlCulture = api.defaultDecimalPoint ? 'es' : 'en'
    const useCommaAsDecimalPoint = api.defaultDecimalPoint

    var store = useUserLibraryStore()
    var specs = store.specs!
    var ready = store.specs !== null

    const [showSaveConfirmation, setShowSaveConfirmation] = useState(false)
    const handleSave = async () => {
        setShowSaveConfirmation(true)
    }
    const handleConfirmSave = async () => {
        setShowSaveConfirmation(false)
        let data = SerializeStorageSpecs(specs)
        await api.updateUserStorage(data)
    }

    useEffect(() => {
        store.getUserStorage(id!).then()
        specs = store.specs!
    }, [id])
    const handleCancelSave = () => setShowSaveConfirmation(false)

    const [showSuccess, setShowSuccess] = useState(false)


    const appInsights = new ApplicationInsights({
        config: {
            connectionString: envVariables[process.env.NODE_ENV].appInsightsConnString
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    return (<>
        {ready &&
            <LocalizationProvider language={intlCulture} >
                <IntlProvider locale={intlCulture}>
                    <Row className="bg-lightgray align-items-center justify-content-end">
                        <h3 className="col-6 justify-content-start">{specs.name}</h3>
                        <div className='m-auto' />
                        <div className="col-2 mt-0 mr-0 justify-content-end d-flex">
                            <Link id='storageLibraryDiscardChanges' to='/storageLibrary' className='btn btn-sm btn-outline-primary mr-1 pillButton'>Discard Changes</Link>
                            <button id='storageLibrarySave' onClick={ev => handleSave()} className='btn btn-sm btn-primary mr-0 pillButton'>Save</button>
                        </div>
                    </Row>
                    <div className='d-flex flex-column container-fluid bg-white rounded-lg mt-2 justify-content-center'>
                        <Tabs
                            defaultActiveKey="general"
                            id="userStorageEditPage"
                            className="mb-3 mt-2"
                            variant="pills"
                        >
                            <Tab eventKey="general" title="General" className="m-2">
                                <StorageLibraryGeneralTab model={specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </Tab>
                            <Tab eventKey="functionalModel" title="Functional Model">
                                <StorageLibraryFuncModelTab model={specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </Tab>
                            <Tab eventKey="tempVCapacity" title="Temperature vs. Capacity">
                                <StorageLibraryTempCapacityTab model={specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </Tab>
                            <Tab eventKey="cycleLifetime" title="Cycle Lifetime">
                                <StorageLibraryCycleLifetimeTab model={specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </Tab>
                            <Tab eventKey="tempVLifetime" title="Temperature vs. Lifetime">
                                <StorageLibraryTempLifetimeTab model={specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </Tab>
                            <Tab eventKey="thermal" title="Thermal">
                                <StorageLibraryThermalTab model={specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </Tab>
                            <Tab eventKey="augmentation" title="Augmentation">
                                <StorageLibraryAugmentationTab model={specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </Tab>
                            <Tab eventKey="converter" title="Converter">
                                <StorageLibraryConverterTab model={specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </Tab>
                            <Tab eventKey="defaults" title="Defaults">
                                <StorageLibraryDefaultsTab model={specs} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </Tab>
                        </Tabs>
                    </div>
                    <ConfirmationModal show={showSaveConfirmation} onCancel={handleCancelSave} onConfirm={handleConfirmSave} />
                </IntlProvider>
            </LocalizationProvider>
        }
        {!ready &&
            <Spinner animation='border' className='text-primary' />}
    </>


    )
})

export default UserStorageLibraryComponentEditView

interface ConfirmationModalProps {
    show: boolean
    onCancel: () => void
    onConfirm: () => void
}

const ConfirmationModal = ({ show, onCancel, onConfirm }: ConfirmationModalProps) => {
    const [visible, setVisible] = useState(show)
    useEffect(() => { setVisible(show) }, [show])

    const handleCancel = () => {
        setVisible(false)
        onCancel?.()
    }

    const handleConfirm = () => {
        setVisible(false)
        onConfirm?.()
    }

    return (
        <Modal show={visible} onHide={handleCancel} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Save</Modal.Title>
            </Modal.Header>
            <Modal.Body>{`Do you want to save the changes?`}</Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' variant='secondary' onClick={handleCancel}>Cancel</Button>
                <Button className='pillButton' variant='primary' onClick={handleConfirm}>Continue</Button>
            </Modal.Footer>
        </Modal>
    )
}
