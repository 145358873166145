import { observer } from 'mobx-react-lite'
import { Chart, ChartSeries, ChartSeriesItem, ChartXAxis, ChartXAxisItem, ChartYAxis, ChartYAxisItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartValueAxis, ChartValueAxisItem } from '@progress/kendo-react-charts'
import { WindTurbinePowerCurveItem } from 'services/api'


interface PowerCurveChartViewProps {
    id: string,
    model: WindTurbinePowerCurveItem[] | undefined,
    chartTitle?: string,
    yAxisTitle?: string,
}

const PowerCurveChart = observer(({ model, chartTitle, yAxisTitle }: PowerCurveChartViewProps) => {


    return (
        <div>
            <div className='d-flex flex-row mb-1 justify-content-end'>
                <h6 className='m-auto'>{chartTitle ?? ''}</h6>
            </div>
            <div className='d-flex flex-row'>
                <div className='flex-grow-1'>
                    <Chart style={{ height: 200 }} renderAs='canvas' transitions={false}>
                        <ChartValueAxis>
                            <ChartValueAxisItem title={{ text: yAxisTitle ?? '' }} />
                        </ChartValueAxis>
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem title={{ text: 'Wind Speed(m/s)' }}>
                            </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                        <ChartSeries>
                            <ChartSeriesItem type='area' name='' data={model ?? []} markers={{ visible: false }} categoryField="x" field='y' />
                        </ChartSeries>
                    </Chart>
                </div>
            </div>
        </div>
    )
})

export default PowerCurveChart
