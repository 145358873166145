import { navigate } from '@reach/router'
import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import { AnalyzerItem } from './analyze'

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import envVariables from 'env-variables.json'
export interface ProjectStep {
    readonly label: string
    readonly url: string
}

export const URL_SETUP = 'setup'
export const URL_APPLICATION = 'revenue'
export const URL_EQUIPMENT = 'equipment'
export const URL_OTHER = 'other'
export const URL_RESULTS = 'results'

const allSteps: ProjectStep[] = [
    {label: 'Setup', url: URL_SETUP},
    {label: 'Application', url: URL_APPLICATION},
    {label: 'Equipment', url: URL_EQUIPMENT},
    {label: 'Project Economics', url: URL_OTHER},
    {label: 'Results', url: URL_RESULTS},
]

export class ProjectStatus {
    constructor(public id: string, public onNav?: (from: string, to: string) => Promise<void>) {
        makeObservable(this)
    }

    @observable transition = false

    steps: ProjectStep[] = [...allSteps]
    @observable currentStep: number = 0
    @observable completedStep: number = 0

    get firstStep(): number { return 0 }
    get lastStep(): number { return this.steps.length - 1 }

    @computed get isFirst(): boolean { return this.currentStep === this.firstStep }
    @computed get isLast(): boolean { return this.currentStep === this.lastStep }

    isDisabled(i: number,issues?:AnalyzerItem[]): boolean {
        return i > this.completedStep && issues?.filter(x => x.link?.includes(this.steps[this.completedStep].url)).length === 0
    }

    @action
    async goToPrevious(errors:AnalyzerItem[]) {
        await this.navigate(this.currentStep - 1,'',errors,true)
    }

    @action
    async goToNext(errors:AnalyzerItem[]) {
        await this.navigate(this.currentStep + 1,'',errors)
    }

    @action
    async goTo(i: number) {
        await this.navigate(i)
    }

    @action
    async navigate(to: number, ref?: string,currentStepErrors?:AnalyzerItem[],isBack?:boolean) {
        const from = this.currentStep
        const newStep = Math.min(Math.max(to, this.firstStep), this.lastStep)
        if (newStep === from || (isBack?false:(currentStepErrors?currentStepErrors.length>0:false))) { return }

        this.transition = true

        await this.onNav?.(this.steps[from].url, this.steps[to].url)

        runInAction(() => {
            this.transition = false
            this.currentStep = newStep
            window.localStorage.setItem('currentStep', newStep.toString())
            this.completedStep = Math.max(newStep, this.completedStep)
        })

        const url = this.steps[this.currentStep].url + (ref ? `#${ref}` : '')
        await navigate(`/project/${this.id}/${url}`)

        const appInsights = new ApplicationInsights({
            config: {
                connectionString: envVariables[process.env.NODE_ENV].appInsightsConnString
            }
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
    }

    @action
    async navigateUrl(link: string) {
        const parts = link.split('#')
        const page = parts[0]
        const ref = parts.length > 1 ? parts[1] : undefined
        const index = allSteps.findIndex(x => x.url === page)
        if (index !== -1)
            await this.navigate(index, ref)
    }

    @action setCurrentStep(x: number) {
        this.currentStep = x;
    }
}
