
import WindResourceView from 'components/project/pages/equipment/WindResourceView'
import { observer } from 'mobx-react-lite'
import { useProjectStore } from 'components/project/ProjectProvider'

interface WindGroupViewProps {
    id: string
}

const WindGroupView = observer(({ id }: WindGroupViewProps) => {
    const project = useProjectStore().project!
    const windModel = project.wind.filter(item => item.id == id)[0]

    return (
        <>
            <>
                <hr className='bg-dark' />
                <div className='my-4'>
                    <WindResourceView model={windModel} />
                </div>
            </>
        </>
    )
})


export default WindGroupView
