import {Chart, ChartSeries, ChartSeriesItem, ChartCategoryAxis, ChartCategoryAxisItem, ChartTitle, ChartValueAxis, ChartValueAxisItem, ChartTooltip, ChartLegend} from '@progress/kendo-react-charts'
import {SolarResultItem} from 'components/project/results-details'

interface ResultSolarEnergyProductionChartProps {
    items: SolarResultItem[]
    showBaseYearAsYearZero: boolean
    baseYear: number
}

const ResultSolarEnergyProductionChart = ({ items, showBaseYearAsYearZero, baseYear }: ResultSolarEnergyProductionChartProps) => {
    const xs = items.map(item => ({ ...item, year: showBaseYearAsYearZero ? baseYear + item.year + 1 : item.year + 1 }))

    return (
        <>
        <h5 className='font-size-16 font-weight-bold'>Annual Energy Production</h5>
        <Chart>
            <ChartCategoryAxis>
                <ChartCategoryAxisItem labels={{visible: true, rotation: 'auto'}} title={{text: 'Year'}}/>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem title={{text: 'MWh'}} min={0} labels={{format: '{0:n}'}}/>
            </ChartValueAxis>
            <ChartSeries>
                <ChartSeriesItem type='column' data={xs} categoryField='year' field='production'
                                 name='Production (MWh)' color='#0000FF'/>
            </ChartSeries>
            <ChartLegend visible={false}/>
            <ChartTooltip format='{0:n}'/>
        </Chart>
        </>
    )
}

export default ResultSolarEnergyProductionChart
