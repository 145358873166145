import { observer } from "mobx-react-lite"
import { Row } from "react-bootstrap";
import ContextHelp from "../helper/ContextHelp";
import NumberInput from "../helper/NumberInput";
import { Validation } from "../helper/validation";
import SensitivityVarView from "../project/pages/EnergyMarket/SensitivityVarView";
import { UserStorageClass } from "./UserStorage"

interface StorageGeneralTabProps {
    model: UserStorageClass
    useCommaAsDecimalPoint: boolean
}

const StorageLibraryAugmentationTab = observer(({ model, useCommaAsDecimalPoint }: StorageGeneralTabProps) => {
    return (
        <>
            <Row className="mt-2 d-flex">
                <label className="col-2 ml-2 mt-2">Augmentation ($/kWh)</label>
                <NumberInput value={model.augmentationPrice}
                    onChange={model.setUserStorageAugmentationPrice}
                    className="mt-1 ml-2 col-1 mr-4"
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)} />
                <ContextHelp helpId='USERLIBRARYAUGMENTATION' />
            </Row>
            <Row className="mt-2 ml-2 d-flex">
                <SensitivityVarView model={model.augmentationDegradationLimitSensitivity}
                    label='Augmentation Degradation Limit (%)' units='%' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            </Row>
        </>
        )
})

export default StorageLibraryAugmentationTab
