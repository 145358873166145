import {Link, LinkGetProps, LinkProps} from '@reach/router'
import React from 'react'


type NavLinkProps =
    React.PropsWithoutRef<LinkProps<{}>> &
    React.RefAttributes<HTMLAnchorElement> & {
    disabled?: boolean
}

const NavLink = (props: NavLinkProps) => (
    <Link {...props}
          getProps={({isPartiallyCurrent}: LinkGetProps) => ({
              className: props.className +
                  (isPartiallyCurrent ? ' active' : '') +
                  (props.disabled ? ' disabled' : ''),
          })}
    />
)

export default NavLink
