import {observer} from 'mobx-react-lite'
import Form from 'react-bootstrap/Form'
import NumberInput from 'components/helper/NumberInput'
import MultiYearView from 'components/project/pages/SharedComponents/MultiYearView'
import {ColorRect} from 'components/helper/ColorRect'
import ScheduleTimeGrid, {EditMode} from 'components/project/capacityMarket/ScheduleGridView'
import React, {useState} from 'react'
import ContextHelp from 'components/helper/ContextHelp'
import ButtonAdd from 'components/helper/ButtonAdd'
import ButtonDelete from 'components/helper/ButtonDelete'
import {useProjectStore} from 'components/project/ProjectProvider'
import {Contract} from 'components/project/model/Contract'
import ToolTipInfo from '../../../helper/ToolTipInfo'
import { RateSchedule } from '../../model/Schedule'

interface RateScheduleEditorProps {
    model: RateSchedule
    isPriceSchedule: boolean
}

const RateScheduleEditor = observer(({ model, isPriceSchedule }: RateScheduleEditorProps) => {
    const project = useProjectStore().project!
    const currency = project.currencySymbol ?? "$"
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint

    const [mode, setMode] = useState<EditMode>('all')
    const [marker, setMarker] = useState(0)

    const colors = model.scheduleRates.map(v => v.color)

    const handleAddNewRate = () => model.addNewPriceRate()
    const handleRemoveRate = (id: string) => {
        model.removePriceRate(id)
        setMarker(0)
    }
    const rateLabel = isPriceSchedule ? `Sell Price (${currency}/MWh)` : "Limit (MW)"
    const rateName = isPriceSchedule ? 'Rate' : 'Limit'
    return (
        <div className='row'>
            <div className='col-5'>
                <p><span className='font-weight-bold'>Step 1:</span> Select and define a {rateName}</p>
                <div>
                    <table className='table table table-bordered table-sm w-auto'>
                        <thead>
                            <tr>
                                <th/>
                                <th>{rateLabel}</th>
                                <th>
                                    <ButtonAdd onClick={handleAddNewRate}/>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {model.scheduleRates.map((item, index) =>
                                <tr key={item.id}>
                                    <td>
                                        <div className='d-flex align-items-center'>
                                            <Form.Check type='radio' id={`rate-${item.id}`} custom
                                                        checked={marker === index}
                                                        onChange={() => setMarker(index)}/>
                                            <ColorRect color={item.color} onColorChange={x => item.setColor(x)} popOverMarginTop='0px'/>
                                            <span>{rateName} {index + 1}</span>
                                        </div>
                                    </td>
                                    <td className='table-w-2 p-0'>
                                        <NumberInput id='todNumberInput' value={item.value} onChange={x => item.setValue(x)} className='border-0 h-100' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                                    </td>
                                    <td className='py-1 align-middle'>
                                        <ButtonDelete onClick={() => handleRemoveRate(item.id)}/>
                                    </td>
                                </tr>)}
                        </tbody>
                    </table>
                </div>

                <p><span className='font-weight-bold'>Step 2:</span> Select a period</p>
                <div className='d-flex flex-column pl-4 mb-3'>
                    <Form.Check type='radio' id={`mode-all-${model.id}`} custom
                                checked={mode === 'all'}
                                onChange={ev => ev.target.checked && setMode('all')}
                                label='All Week'/>
                    <Form.Check type='radio' id={`mode-weekday-${model.id}`} custom
                                checked={mode === 'weekday'}
                                onChange={ev => ev.target.checked && setMode('weekday')}
                                label='Weekdays'/>
                    <Form.Check type='radio' id={`mode-weekend-${model.id}`} custom
                                checked={mode === 'weekend'}
                                onChange={ev => ev.target.checked && setMode('weekend')}
                                label='Weekends'/>
                </div>

                <p><span className='font-weight-bold'>Step 3:</span> Click on the chart to indicate when the selected {rateName.toLowerCase} applies</p>
            </div>
            <div className='col-7'>
                <ScheduleTimeGrid value={model.schedule}
                                  onChange={x => model.setSchedule(x)}
                                  colors={colors} mode={mode} marker={marker}/>
            </div>
        </div>
    )
})

export default RateScheduleEditor
