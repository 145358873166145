import { observer } from 'mobx-react-lite'
import { useProjectStore } from 'components/project/ProjectProvider'
import { RouteComponentProps } from '@reach/router'
import { Form } from 'react-bootstrap'
import CollapsibleGroup from 'components/helper/CollapsibleGroup'
import ConfigurationView from 'components/project/pages/equipment/ConfigurationView'
import InverterView from 'components/project/pages/equipment/InverterView'
import StorageView from 'components/project/pages/equipment/StorageView'
import WindView from 'components/project/pages/equipment/WindView'
import React, { useState } from 'react'
import SchematicView from 'components/project/pages/equipment/SchematicView'
import SolarGroupView from 'components/project/pages/equipment/SolarGroupView'
import ContextHelp from 'components/helper/ContextHelp'
import OverflowComponent from 'components/project/pages/SharedComponents/OverflowComponent'
import schematicIcon from 'assets/images/schemantic.svg'
import { IntlProvider, LocalizationProvider } from 'Kendo-Intl-5'
import { MultiImportState } from '../../helper/MultiTimeSeriesImport'
import { UnlockProjectModal } from '../ProjectView'
import DeleteConfirmationModal, { DeleteComponentState } from './equipment/shared/DeleteConfirmationModal'

interface EquipmentPageProps extends RouteComponentProps { }

const EquipmentPage = (_: EquipmentPageProps) => {
    const store = useProjectStore()
    const project = useProjectStore().project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'

    const handleSelectSolar = async (value: boolean) => await project.setUseSolar(value)
    const handleSelectWind = async (value: boolean) => await project.setUseWind(value)
    const handleSelectStorage = async (value: boolean) => await project.setUseStorage(value)

    //todo create wind group similar to solar
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [componentToDelete, setComponentToDelete] = useState<DeleteComponentState>({ kind: 'Component' })
    const onDelete = (componentToDelete: DeleteComponentState) => {
        setComponentToDelete(componentToDelete)
        setShowDeleteModal(true)
    }
    const onConfirmDelete = () => {
        switch (componentToDelete.kind) {
            case 'PV':
                project.removeSolar(componentToDelete.id!)
                break;
            case 'Wind Turbine':
                project.removeWind(componentToDelete.id!)
                break;
            case 'Storage':
                project.removeStorage(componentToDelete.id!)
                break;
            default: 
                break;
        }
        setShowDeleteModal(false)
    }
    const [windState, setWindState] = useState<MultiImportState>({ kind: 'blank' })
    const [windFileData, setWindFileData] = useState<any[]>([])
    const handleDeleteWindFile = (id: number = 0) => {
        var newFileData = windFileData
        newFileData.splice(id, 1)
        setWindFileData(newFileData)
    }
    const handleWindSelect = async (sourceList: string[], customName: string[], customSize: number[], data: number[][], year: number, index: number = 0) => {
        project.wind[index].data.setData(data[0], year)
        project.wind[index].setCustomName(customName[0])
        project.wind[index].setDataName(customName[0])
        project.wind[index].customSizing.values[0].setValue(customSize[0] / 1000)

        //to uncoment to allow for multiple wind turbines
        //if (data.length > 1) {
        //    var pvCount = project.solar.length
        //    for (let i = 1; i < data.length; i++) {
        //        await project.addNewSolar()
        //        var pvIndex = i + pvCount - 1
        //        //project.solar[pvIndex].setKind('custom')
        //        project.wind[index].data.setData(data[0], year)
        //        project.wind[index].setCustomName(customName[0])
        //        project.wind[index].setCustomSize(customSize[0])
        //    }
        //}

        await store.saveProject()
    }

    project.setInterconnectionLimit(project.interconnectionLimit ? project.interconnectionLimit : 1000)
    const handleCloseReadOnlyPopup = async () => {
        await store.updateShowReadOnlyPopup(false)
    }
    return (<>
        <LocalizationProvider language={intlCulture} >
            <IntlProvider locale={intlCulture}>
                <div className='d-flex flex-column p-4' style={{ height: 'calc(100vh - 173px)' }}>
                    <div className='d-flex'>
                        <div className='mr-3'>
                            <Form.Check custom label='Solar' id='useSolar'
                                checked={project.useSolar}
                                onChange={ev => handleSelectSolar(ev.target.checked)} />
                        </div>
                        <div className='mr-3'>
                            <Form.Check custom label='Wind' id='useWind'
                                checked={project.useWind}
                                onChange={ev => handleSelectWind(ev.target.checked)} />
                        </div>
                        <div className='mr-3'>
                            <Form.Check custom label='Storage' id='useStorage'
                                checked={project.useStorage}
                                onChange={ev => handleSelectStorage(ev.target.checked)} />
                        </div>
                        <ContextHelp helpId='EQUIPMENT' />
                        <div className="m-auto"></div>
                        <div>
                            <OverflowComponent title=" " toolTipTitle="Schematic" children={<SchematicView model={project.schematics} project={project} />} icon={schematicIcon} />
                        </div>
                    </div>

                    <div className='py-2' />

                    <div className='d-flex h-100'>
                        <div className='flex-fill overflow-scroll k-overflow-x-hidden'>
                            <CollapsibleGroup title='Configuration' className='mb-4'>
                                <ConfigurationView model={project} store={store} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </CollapsibleGroup>

                            {project.useSolar &&
                                <CollapsibleGroup title='Solar' className='mb-4'>
                                    <SolarGroupView onDelete={onDelete} />
                                </CollapsibleGroup>}

                            {project.useWind &&
                                <CollapsibleGroup title='Wind' className='mb-4'>
                                    {project.wind.map((x) => <React.Fragment key={x.id}>
                                        <div>
                                            <WindView model={x} index={0} onDelete={() => onDelete({ kind: 'Wind Turbine', id: x.id })} state={windState} setState={setWindState} fileData={windFileData} setFileData={setWindFileData} handleSelect={handleWindSelect} handleDeleteFile={handleDeleteWindFile} />
                                        </div>
                                        <hr className='bg-dark' />
                                    </React.Fragment>)}
                                    {/* <div className='d-flex flex-column align-items-center my-4'>
                                <button className='btn btn-outline-primary pillButton' onClick={() => project.addNewWind()}>Add another wind technology</button>
                            </div> */}
                                </CollapsibleGroup>}

                            {project.useStorage &&
                                <CollapsibleGroup title='Storage' className='mb-4'>
                                    {project.storage.map((x, i) => <React.Fragment key={x.id}>
                                        <div>
                                            <StorageView model={x} onDelete={() => onDelete({ kind: 'Storage', id: x.id })} index={i} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                                        </div>
                                        <hr className='bg-dark' />
                                    </React.Fragment>)}
                                    <div className='d-flex flex-column align-items-center my-4'>
                                        <button className='btn btn-outline-primary pillButton' onClick={() => project.addNewStorage()}>Add another storage technology</button>
                                    </div>
                                </CollapsibleGroup>}

                            {project.hasSystemConverter &&
                                <CollapsibleGroup title='System Converter' className='mb-4'>
                                    <InverterView model={project.inverter} absoluteUnits='MW' label='Converter' type={'System'} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                                </CollapsibleGroup>}
                        </div>

                        {/* <div className='w-30 ml-2'>
                    <SchematicView model={project.schematics} project={project}/>
                </div> */}
                    </div>
                </div>
            </IntlProvider >
        </LocalizationProvider>
        <UnlockProjectModal show={store.showReadOnlyPopup} onClose={handleCloseReadOnlyPopup} />
        <DeleteConfirmationModal show={showDeleteModal} componentState={componentToDelete} onCancel={() => setShowDeleteModal(false)} onConfirm={onConfirmDelete} />
    </>

    )
}

export default observer(EquipmentPage)



