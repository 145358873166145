import {action, computed, makeObservable, observable} from 'mobx'
import {v4} from 'uuid'


export class SensitivityItem {
    constructor(init: Partial<SensitivityItem> = {}) {
        Object.assign(this, init)
        makeObservable(this)
    }

    id: string = v4()

    @observable value: number = 0.0

    @action setValue(x: number) { this.value = x }
}


export class SensitivityVar {
    constructor(value?: number) {
        if (value) this.setSingleValue(value)
        makeObservable(this)
    }

    @observable values: SensitivityItem[] = observable([new SensitivityItem()])

    @action addNewValue() {
        this.values.push(new SensitivityItem())
    }

    @action removeValue(id: string) {
        if (this.values.length <= 1) { return } // should have at least one value
        const i = this.values.findIndex(x => x.id === id)
        this.values.splice(i, 1)
    }

    @action setValue(id: string, value: number) {
        const i = this.values.findIndex(x => x.id === id)
        this.values[i].setValue(value)
    }

    @computed get singleValue(): number { return this.values[0].value }

    @action setSingleValue(value: number) {
        this.values.splice(0, this.values.length, new SensitivityItem({value: value}))
    }

    estimateSimulations(): number { return this.values.length }
}
