// wait/delay for async methods
export const delay = (ms: number) => {
    return new Promise(resolve => setTimeout(resolve, ms))
}


// equality for floating point numbers
const EPS = 1e-6
export const eq = (a: number, b: number): boolean => Math.abs(a - b) < EPS


// inclusive range [min..=max]
export function* rangeGen(min: number, max: number) {
    for (let i = min; i <= max; i++)
        yield i
}

export const range = (min: number, max: number): number[] => [...rangeGen(min, max)]


// read text file content
export const readFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onerror = reject
        reader.onload = () => {
            const result = reader.result as string
            resolve(result)
        }
        reader?.readAsText(file)
    })
}

interface GeocodeResult {
    address: string
    countryName: string
    countryCode: string
}

// location geocoding
export async function geocode(pos: { lat: number, lng: number }): Promise<GeocodeResult> {
    const geocoder = new google.maps.Geocoder()
    return new Promise<GeocodeResult>((resolve, reject) => {
        geocoder.geocode({location: pos}, (results, status) => {
            if (status !== google.maps.GeocoderStatus.OK) { reject(status) }
            const address = results[0].formatted_address
            const country = results[results.length - 1].address_components[0]
            resolve({
                address,
                countryName: country.long_name,
                countryCode: country.short_name,
            })
        })
    })
}


// date-time
export function roundToDay(x: Date): Date {
    let date = new Date(x)
    return new Date(date.getFullYear(), date.getMonth(), date.getDate())
}
