import {useLibraryStore} from '../library/LibraryProvider'
import {useNavigate} from '@reach/router'
import {BiClipboard, BiDotsVerticalRounded, BiLockOpen, BiPieChart, BsClipboard, BsPencilSquare, BsUnlock} from 'react-icons/all'
import Dropdown from 'react-bootstrap/Dropdown'
import {Grid, GridCellProps} from '@progress/kendo-react-grid'
import {orderBy} from '@progress/kendo-data-query'
import {GridColumn as Column} from '@progress/kendo-react-grid/dist/npm/GridColumn'
import React from 'react'
import {observer} from 'mobx-react-lite'
import {ProjectInfo} from 'services/api'
import {DateFormatOptions} from 'Kendo-Intl-5'
import {GridHeaderCellProps} from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps'
import Button from 'react-bootstrap/Button'


interface projectTableListProps {
    onDelete?: (id: string) => void
    onCopy?: (id: string) => void
    onDownload?: (id: string) => void
    onShare?: (id: string) => void
    onReport?: (id: string) => void
}

const initialSort = [{
    field: 'name',
}]

const dateFormat: DateFormatOptions = {
    day: 'numeric', month: 'short', year: 'numeric',
}
const dateTimeFormat: DateFormatOptions = {
    day: 'numeric', month: 'short', year: 'numeric',
    hour: 'numeric', minute: 'numeric', hour12: true,
}


const dateCell = (cell: GridCellProps) => {
    if (!cell.field || !cell.dataItem) return null
    const value = cell.dataItem[cell.field]
    const s = new Date(value).toLocaleString('en-US', dateFormat).replace(/,/g, '')
    return (<td>{s}</td>)
}

const dateTimeCell = (cell: GridCellProps) => {
    if (!cell.field || !cell.dataItem) return null
    const value = cell.dataItem[cell.field]
    const s = new Date(value).toLocaleString('en-US', dateTimeFormat).replace(/,/g, '')
    return (<td>{s}</td>)
}

const lockedCell = (cell: GridCellProps) => {
    if (!cell.field || !cell.dataItem) return null
    const locked = cell.dataItem[cell.field]
    if (locked === true)
        return (<td className='text-danger'>Locked</td>)
    else
        return (<td>Unlocked</td>)
}

const dataNotesCell = (cell: GridCellProps) => {
    if (!cell.field || !cell.dataItem) return null
    const value = cell.dataItem[cell.field]
    return (<td>{(value ?? []).length > 100 ? (value.substring(0, 99) + "...") : value}</td>)
}


const ProjectTableListView = observer(({onDelete, onCopy, onDownload, onShare, onReport}: projectTableListProps) => {
    const {projects} = useLibraryStore()
    const navigate = useNavigate()

    const [sort, setSort] = React.useState(initialSort)

    const navigateToInput = async (id: string) => {
        window.localStorage.removeItem('currentStep')
        await navigate(`/project/${id}`)
    }

    const navigateToResult = async (id: string) => {
        window.localStorage.removeItem('currentStep')               
        await navigate(`/project/${id}/results`)
    }

    const iconCell = (cell: GridCellProps) => {
        const item = cell.dataItem as ProjectInfo
        return (
            <td>
                <div className={'project-list-row-dot-menu d-flex align-items-center justify-content-between'}>
                    <div style={{ cursor: 'pointer' }}>
                        {/*{item.locked === true && <>*/}
                            {/*<Button id='cloneLockedProject' variant="outline-primary" className='w-99 mb-1 on-hover pillButton' size='sm' onClick={() => onCopy?.(item.id)}><BiClipboard style={{ color: '#5b0428', marginLeft: '-0.25rem', marginBottom: '0.25rem' }} /><span> Clone  </span></Button>*/}
                            {/*<Button id='unlockProject' variant="outline-primary" className='w-99 on-hover pillButton' size='sm' onClick={() => onCopy?.(item.id)}><BiLockOpen style={{ color: '#5b0428', marginLeft: '-0.25rem', marginBottom: '0.25rem' }} /><span> Unlock </span></Button>*/}
                        {/*</>}*/}
                        {/*{item.locked !== true && <>*/}
                        <Button id='getResults' variant="outline-primary" className='w-99 mb-1 on-hover pillButton' size='sm' onClick={() => navigateToResult(item.id)}><BiPieChart style={{ color: '#5b0428', marginBottom: '0.25rem' }} /> <span>Result</span></Button>
                        <Button variant="outline-primary" className='w-99 on-hover pillButton' size='sm' onClick={() => navigateToInput(item.id)}><BsPencilSquare  style={{ color: '#5b0428', marginBottom: '0.25rem' }} /><span className='mr-3'> Edit</span></Button>
                    {/*    </>}*/}
                    </div>
                    <div>
                        <Dropdown className='project-list-row-dot-menu'>
                            <Dropdown.Toggle id='projectActionMenu' className='project-list-row-dropdown-button'>
                                <BiDotsVerticalRounded />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => onDelete?.(item.id)}>Delete</Dropdown.Item>
                                <Dropdown.Item id='copyProjectButton' onClick={() => onCopy?.(item.id)}>Copy</Dropdown.Item>
                                <Dropdown.Item onClick={() => onDownload?.(item.id)}>Download</Dropdown.Item>
                                <Dropdown.Item onClick={() => onShare?.(item.id)}>Share</Dropdown.Item>
                                <Dropdown.Item onClick={() => onReport?.(item.id)}>Report Issue</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </td>
        )
    }

    return (
        <Grid
            scrollable={'scrollable'}
            sortable={true}
            data={orderBy(projects, sort)}
            className='project-table-list'
            sort={sort}
            onSortChange={ev => setSort(ev.sort)}>
            <Column title='Project Name' field='name'/>
            <Column title='Location' field='location'/>
            <Column title='Electricity Market' field='electricityMarket'/>
            <Column title='Type' field='type'/>
            <Column title='Coupling' field='coupling'/>
            <Column title='Notes' width='250px' field='notes' cell={dataNotesCell}/>
            {/* <Column title='POI (MW)' field='poi'/>
            <Column title='Solar Size*' field='solarSize' headerCell={(props) => <CustomHeader {...props}/>}/>
            <Column title='Storage Size*' field='storageSize' headerCell={(props) => <CustomHeader {...props}/>}/>
            <Column title='IRR*' field='irr' headerCell={(props) => <CustomHeader {...props}/>}/> */}
            <Column title='Expected COD' field='dateCod' cell={dateCell}/>
            <Column title='Last Accessed' width='150px' field='updated' cell={dateTimeCell}/>
            <Column title='Author' field='author' />
            {/*<Column title='Locked for Editing' field='locked' cell={lockedCell} />*/}
            <Column width={115} title='Actions' cell={iconCell}/>
        </Grid>
    )
})

export default ProjectTableListView

const CustomHeader = (props: GridHeaderCellProps) =>
    <span className='k-link' title='Highest NPV system results'>{props.title}</span>
