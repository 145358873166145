import { Grid, GridColumn as Column } from '@progress/kendo-react-grid'
import { process, State } from '@progress/kendo-data-query'
import '@progress/kendo-theme-bootstrap/dist/all.scss'
import './ResultTableView.scss'
import { useMemo, useState } from 'react'
import { formatOptimization } from '../../results'
import { GridHeaderCellProps } from '@progress/kendo-react-grid/dist/npm/interfaces/GridHeaderCellProps'
import { observer } from 'mobx-react-lite'
import { SystemSizerResultItem, SystemSizerResultHeader, SystemSizerResultTable } from '../../model/Contract'

interface SystemSizerResultTableProps {
    items: SystemSizerResultItem[],
    header: SystemSizerResultHeader,
    onEdit?: (model: SystemSizerResultTable) => void
}
interface ColSystemSizerDef {
    name: string,
    units: string,
    field: string,
}

const SystemSizerResultTableView = observer(({ items, header }: SystemSizerResultTableProps) => {

    // prepare column groups
    const columns: ColSystemSizerDef[] = header.optimization.map((x, index) =>
        ({ ...x, field: `optimization_${index}`, format: '{0:n}', units: x.units.join(' / ') }))

    // prepare items
    const data = useMemo(() =>
        items.map((x, index) => flattenResultItem(x, index)), [items])

    const createDataState = (dataState: State) => {
        return {
            result: process(data, dataState),
            dataState: dataState,
        }
    }
    const [resultState, setResultState] = useState(createDataState({
        skip: 0,
    }))

    const dataStateChange = (event: { dataState: State; }) => {
        setResultState(createDataState(event.dataState))
    }

    return (<><div className='mt-1 mb-3' style={{ overflowX: 'scroll' }}>
        <Grid style={{ minWidth: '35rem', fontSize: '18px', maxHeight: '20rem'  }}
            sortable={true} filterable={false}
            scrollable='scrollable'
            onDataStateChange={dataStateChange}
            {...resultState.dataState}
            data={resultState.result}
            className='result-table-list'>

            <Column title='Sizing Recommendations'>
                {columns.map((col, index) =>
                    <Column field={col.field}
                        title={col.name}
                        key={index} sortable={true}
                        headerCell={CustomHeaderCell} />)}
            </Column>
        </Grid>
    </div>
    </>
    )
})

export default SystemSizerResultTableView

function CustomHeaderCell(columnData: GridHeaderCellProps) {
    const column = columnData.title! // title_unit
    const [title, unit] = column.split('_')
    return (
        <span className='k-link customize-header-cell-link' onClick={columnData.onClick}>
            <div>{title}</div>
            {unit &&
                <div style={{ color: '#939598', fontSize: '0.6rem' }}>({unit})</div>}
        </span>
    )
}

type FlatItem = {
    data: SystemSizerResultItem,
    index: number,
    [field: string]: any
}

function flattenResultItem(item: SystemSizerResultItem, index: number): FlatItem {
    let rv: FlatItem = { data: item, index, ...item }
    for (let i = 0; i < item.results.length; i++)
        rv[`optimization_${i}`] = formatOptimization(item.results[i])

    return rv
}
