import {ComplexCostItem, ComplexCostTable, CostTable} from 'components/project/model/CostTable'
import {v4} from 'uuid'
import {action, computed, makeObservable, observable} from 'mobx'
import {StorageSpec} from 'services/api'
import {Sizing} from 'components/project/model/Sizing'
import {MultiYearVar} from 'components/project/model/MultiYearVar'
import {SensitivityVar} from 'components/project/model/SensitivityVar'
import {Inverter} from 'components/project/model/Inverter'
import { EnergyMarket } from './EnergyMarket'
import { SizingKind } from '../../../services/inputs'
import { Deferment } from './Deferment'


const DEFAULT_STORAGE: string = 'Energy Storage (1 MW / 1 MWh)'

export type StorageKind = 'library' | 'custom'


export class Storage {
    id: string = v4()
    constructor() {
        makeObservable(this)
        this.cost.complex = defaultStorageComplexCostTable()
        this.converter.sizing.kind = SizingKind.relative
    }

    @observable kind: StorageKind = 'library'
    @observable specs?: StorageSpec

    // library
    @observable model: string = DEFAULT_STORAGE

    // custom
    @observable customName: string = ''
    @observable customData: string = ''
    @observable customAbbreviation: string= ''

    @observable sizing: Sizing = new Sizing()
    @observable hasDeferment: boolean = false
    @observable deferment: Deferment = new Deferment()
    @observable cost: CostTable = new CostTable()
    @observable omEscalator: MultiYearVar = new MultiYearVar()
    @observable augmentationCost: number = 0 // $/kWh
    @observable augmentationDegradationLimit: SensitivityVar = new SensitivityVar(10)
    @observable augmentationPriceDecline: MultiYearVar = new MultiYearVar()

    @observable hasConverter: boolean = false
    @observable converter: Inverter = new Inverter()

    @observable useCycleLimitPerDay: boolean = false
    @observable cycleLimitPerDay: SensitivityVar = new SensitivityVar(1)
    @observable optimizeCycleLimitPerDay: boolean = false

    @observable useAuxiliaryLoad: boolean = false
    @observable auxiliaryLoadSensitivity: SensitivityVar = new SensitivityVar(1)
    @observable storageServesAuxiliaryLoad: boolean = false
    @observable useAuxiliaryLoadFlatPrice:boolean = true
    @observable auxiliaryLoadFlatPrice: number = 10
    @observable energyMarket: EnergyMarket | null = null
    @observable auxiliaryLoadPriceMultiYear: MultiYearVar = new MultiYearVar()

    //@observable auxiliaryLoadPrice: TimeSeries = new TimeSeries()

    @action setHasDeferment(x: boolean) { this.hasDeferment = x }
    @action setKind(x: StorageKind) { this.kind = x }
    @action setSpecs(x: StorageSpec) { this.specs = x }
    @action setUserStorageSpecs(x: StorageSpec) { this.specs = x }

    @action setModel(x: string) { this.model = x }
    @action setCustomName(x: string) { this.customName = x }
    @action setCustomAbbreviation(x: string) { this.customAbbreviation = x }
    @action setCustomData(x: string) { this.customData = x }
    @action setAugmentationCost(x: number) { this.augmentationCost = x }
    @action setHasConverter(x: boolean) { this.hasConverter = x }

    @action setOptimizeCycleLimitPerDay(x: boolean) { this.optimizeCycleLimitPerDay = x }
    @action setUseCycleLimitPerDay(x: boolean) { this.useCycleLimitPerDay = x }

    @computed get isZero(): boolean { return this.sizing.onlyZero }

    estimateSimulations(): number {
        const sens = this.augmentationDegradationLimit.estimateSimulations()
        const converter = this.hasConverter ? this.converter.estimateSimulations() : 1
        const size = this.sizing.estimateSimulations()
        const cost = this.cost.estimateSimulations()
        return sens * converter * size * cost
    }

    @action setEnergyMarket(x: EnergyMarket | null) { this.energyMarket = x }
    @action setUseAuxiliaryLoad(x: boolean) { this.useAuxiliaryLoad = x }
    @action setAuxiliaryLoadFlatPrice(x: number) { this.auxiliaryLoadFlatPrice = x }
    @action setUseAuxiliaryLoadFlatPrice(x: boolean) { this.useAuxiliaryLoadFlatPrice = x }
    @action setStorageServesAuxiliaryLoad(x: boolean) { this.storageServesAuxiliaryLoad = x }

}


function defaultStorageComplexCostTable(): ComplexCostTable {
    const rv = new ComplexCostTable()
    rv.directCapital.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Module', unit: 'CostPerKwh'}),
        new ComplexCostItem({name: 'Power Conversion System', unit: 'CostPerKw'}),
        new ComplexCostItem({name: 'Balance of System', unit: 'CostPerKw'}),
        new ComplexCostItem({name: 'Installation Labor', unit: 'CostPerKw'}),
        new ComplexCostItem({name: 'Installer Overhead and Margin', unit: 'CostPerKw'}),
        new ComplexCostItem({name: 'Contingency', unit: 'PercentOfDirectCapital'}),
    ])
    rv.indirectCapital.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Permitting and Environmental', unit: 'CostPerKw'}),
        new ComplexCostItem({name: 'Engineering and Developer Overhead', unit: 'CostPerKw'}),
        new ComplexCostItem({name: 'Land Purchase, Preparation and Transmissions', unit: 'CostPerKw'}),
        new ComplexCostItem({name: 'Working Capital Reserve Account', unit: 'Cost'}),
        new ComplexCostItem({name: 'Sales Tax Rate', unit: 'PercentOfDirectCapital'}),
    ])
    rv.operating.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Fixed Annual Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Fixed Cost by Capacity', unit: 'CostPerKwhYear'}),
        new ComplexCostItem({name: 'Variable Cost', unit: 'CostPerMwhThroughput'}),
        new ComplexCostItem({name: 'Insurance', unit: 'PercentOfDirectCapitalPerYear'}),
        new ComplexCostItem({name: 'Audit & Program Management Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Site Lease Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Property Tax Rate', unit: 'PercentOfDirectCapitalPerYear'}),
        new ComplexCostItem({name: 'Station Power Cost', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Interest on All Reserves', unit: 'CostPerYear'}),
        new ComplexCostItem({name: 'Storage Decommissioning Reserve', unit: 'CostPerKwhYear'}),
    ])
    rv.replacement.items.splice(0, 0, ...[
        new ComplexCostItem({name: 'Replacement Cost', unit: 'CostPerKwh'}),
    ])
    return rv
}
