import { observer } from 'mobx-react-lite'
import { StorageResult } from 'components/project/results-details'
import { Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, ChartTooltip, ChartValueAxis, ChartValueAxisItem } from '@progress/kendo-react-charts'
import ContextHelp from 'components/helper/ContextHelp'

interface AugmentationCapacityChartProps {
    result: StorageResult
    showBaseYearAsYearZero: boolean
    baseYear: number
}

const AugmentationCapacityChart = observer(({ result, showBaseYearAsYearZero, baseYear }: AugmentationCapacityChartProps) => {
    const items = result.items.map(item => ({ ...item, year: showBaseYearAsYearZero ? item.year + baseYear : item.year }))

    return (
        <div>
            <div className='mr-5' style={{ float: 'right', zIndex: 99, position: 'relative' }}><ContextHelp helpId='AUGCAPACITY' /></div>
                    <Chart>
                        <div>
                            <ChartTitle text='Augmentation Capacity' />
                        </div>
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem labels={{ visible: true, rotation: 'auto' }} title={{ text: 'Year' }} />
                        </ChartCategoryAxis>
                        <ChartValueAxis>
                            <ChartValueAxisItem title={{ text: 'MWh' }} min={0} labels={{ format: '{0:n0}', rotation: 'auto' }} />
                        </ChartValueAxis>
                        <ChartSeries>
                            <ChartSeriesItem type='column' data={items} categoryField='year' field='maximum' stack='begin'
                                name='Maximum Usable Energy Capacity (MWh)' color='#0000FF' />
                            <ChartSeriesItem type='column' data={items} categoryField='year' field='minimum' stack='end'
                                name='Minimum Usable Energy Capacity (MWh)' color='#FF8C00' />
                            <ChartSeriesItem type='column' data={items} categoryField='year' field='augmentation' stack='end'
                                name='Augmentation Capacity (MWh)' color='#32CD32' />
                        </ChartSeries>
                        <ChartLegend position='bottom' align='center' orientation='vertical' />
                        <ChartTooltip format='{0:n4}' />
                    </Chart>
        </div>
    )
})

export default AugmentationCapacityChart
