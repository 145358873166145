import { action, makeObservable, observable } from "mobx"
import { v4 } from "uuid"
import { getColor } from 'colors'

export class RateSchedule {
    id: string = v4()
    constructor() {
        makeObservable(this)
        this.addNewPriceRate(1000)
    }

    // for kind = 'schedule'
    @observable scheduleRates: ScheduleRate[] = observable([])
    @observable schedule: Schedule = defaultSchedule()


    @action addNewPriceRate(value: number = 0) {
        const x = new ScheduleRate()
        x.color = getNextRateColor()
        x.value = value
        this.scheduleRates.push(x)
    }

    @action removePriceRate(id: string) {
        const index = this.scheduleRates.findIndex(x => x.id === id)
        this.scheduleRates.splice(index, 1)

        // update schedule
        const schedule = index === 0 ?
            shiftScheduleValues(this.schedule) :
            removeScheduleValue(this.schedule, index)
        this.setSchedule(schedule)

        // if no more rates -> add new one
        if (this.scheduleRates.length === 0) { this.addNewPriceRate() }
    }

    @action setSchedule(x: Schedule) { this.schedule = x }
}
export type Schedule = number[][]

export class ScheduleRate {
    id: string = v4()
    constructor(init: Partial<ScheduleRate> = {}) {
        Object.assign(this, init)
        makeObservable(this)
    }

    @observable color: string = '#ffffff'
    @observable value: number = 1000

    @action setColor(v: string) { this.color = v }
    @action setValue(v: number) { this.value = v }
}

export function defaultSchedule(): Schedule {
    const months: number = 12
    const hours: number = 24
    const parts: number = 2 // weekday + weekend
    // [month+part][hours]
    // 0 -- off; 1 -- on
    return Array(parts * months).fill(null).map(() => new Array(hours).fill(0))
}

export function removeScheduleValue(schedule: Schedule, value: number): Schedule {
    return schedule.map(vs => vs.map(v => v === value ? 0 : (v > value ? v - 1 : v)))
}

// decrease every value (except 0)
export function shiftScheduleValues(schedule: Schedule): Schedule {
    return schedule.map(vs => vs.map(v => v === 0 ? 0 : v - 1))
}

export function fillSchedule(x: Schedule, value: number,
                             month0: number, month1: number,
                             hour0: number, hour1: number,
                             weekday: boolean, weekend: boolean) {
    for (let month = Math.min(month0, month1); month <= Math.max(month0, month1); month++)
        for (let hour = Math.min(hour0, hour1); hour <= Math.max(hour0, hour1); hour++) {
            if (weekday) x[2 * month][hour] = value
            if (weekend) x[2 * month + 1][hour] = value
        }
}

let nextRateColorIndex = 0

export function getNextRateColor() {
    const rv = getColor(nextRateColorIndex)
    nextRateColorIndex += 2
    return rv
}
