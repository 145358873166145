import {Dropdown} from 'react-bootstrap'
import {range} from 'utils'

export interface YearSelectorProps {
    label?: string
    value?: number,
    onValueChanged: (x?: number) => void,
    min: number,
    max: number,
}

const YearSelector = ({label, value, onValueChanged, min, max}: YearSelectorProps) => {
    const years = range(min, max)
    return (
        <div className='d-flex flex-row align-items-center'>
            <label className='m-0 p-0'>{label ?? 'Year '}</label>
            <div className='mx-2'/>
            <Dropdown drop='down'>
                <Dropdown.Toggle variant='outline-primary'>
                    {value ?? '—'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onValueChanged()}>—</Dropdown.Item>
                    {years.map(item =>
                        <Dropdown.Item key={item} onClick={() => onValueChanged(item)}>{item}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default YearSelector
