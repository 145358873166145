import React, {useState} from 'react'
import {Alert, Form} from 'react-bootstrap'
import {LoginResult} from 'services/Service'


const LOCAL = process.env.NODE_ENV !== 'production'
const DEFAULT_NAME = LOCAL ? 'daniel.pessoa@ul.com' : ''
const DEFAULT_PASSWORD = LOCAL ? '6kt3jhaMXLd5rcH' : ''
const DEFAULT_ACCEPT = LOCAL


interface LoginViewProps {
    onLogin?: (name: string, password: string) => Promise<LoginResult>
}

const LoginView = ({onLogin}: LoginViewProps) => {
    const [name, setName] = useState(DEFAULT_NAME)
    const [password, setPassword] = useState(DEFAULT_PASSWORD)
    const [accept, setAccept] = useState(DEFAULT_ACCEPT)

    const [acceptError, setAcceptError] = useState(false)
    const [serverErrors, setServerErrors] = useState<string[]>([])

    const clearErrors = () => {
        setAcceptError(false)
        setServerErrors([])
    }

    const handleAccept = (v: boolean) => {
        setAcceptError(false)
        setAccept(v)
    }

    const handleLogin = async () => {
        clearErrors()

        // basic validation
        if (!accept) {
            setAcceptError(true)
            return
        }

        if (!onLogin) { return }

        const result = await onLogin(name, password)
        if (!result.success) {
            setServerErrors(result.errors)
        }
    }

    //Removing qualtrics code 
    //useEffect(() => {
    //    const script = document.createElement('script');
    //    script.type = "text/javascript";
    //    script.src = "https://zne4e8skqwb8kkuly-ulsolutions.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_e4e8sKQwb8KKuLY";
    //    script.async = true;
    //    if (document.readyState == 'complete') {
    //        document.body.appendChild(script);
    //    }
    //    else if (window.addEventListener) {
    //        window.addEventListener("load", function () {
    //            document.body.appendChild(script);
    //        }, !1)
    //    }
    //    return () => {
    //        document.body.removeChild(script);
    //    }
    //}, []);

    return (
        <main className='flex-fill p-3 overflow-auto'>
            <div className='d-flex flex-column h-100 align-items-center justify-content-center'>

                <div className='bg-white rounded-lg shadow-page p-5 d-flex flex-column relative-parent' style={{width: '600px'}}>
                    <div>
                        <a style={{ color: '#5b0428', position: 'absolute', top: 25, right: '20px'}}
                           href='https://www.homerenergy.com/products/front/'>Sign Up</a>
                    </div>
                    <h4 className='align-self-center'>Welcome to HOMER Front</h4>

                    <div className='my-4'/>

                    <p className='align-self-start'>Please Log In</p>

                    <div className='my-4'/>

                    <div className='form-group'>
                        <label htmlFor='email'>Email</label>
                        <input type='email' className='form-control' id='email'
                               value={name} onChange={ev => setName(ev.target.value)}/>
                    </div>
                    <div className='form-group'>
                        <label htmlFor='password'>Password</label>
                        <input type='password' className='form-control' id='password'
                               value={password} onChange={ev => setPassword(ev.target.value)}/>
                    </div>

                    <Form.Check custom label={<><span style={{color: '#212529'}}> I agree to the</span><a href={require('../../assets/docs/HOMER_Service_Terms.pdf').default} target='_blank' rel='noreferrer'>{' Service Terms'}</a></>} id='eula'
                                isInvalid={acceptError} feedback='Please accept Service Terms'
                                checked={accept} onChange={ev => handleAccept(ev.target.checked)}/>

                    <div className='my-4'/>

                    {serverErrors.length > 0 && serverErrors.map(x =>
                        <Alert variant='danger'>{x}</Alert>)}

                    <div className='my-4'/>

                    <button id='logIn' className='btn btn-primary align-self-center pillButton' style={{width: '200px'}} onClick={handleLogin}>LOG IN</button>

                    <a href='https://users.homerenergy.com/account/password/new' className='align-self-center'><small>Forgot Password</small></a>

                </div>
            {/*    <div id='ZN_e4e8sKQwb8KKuLY'></div>*/}
            </div>
        </main>
    )
}

export default LoginView
