import sensitivityIcon from 'assets/images/sensitivity-var.svg'


interface SensitivityViewProps {
    count: number,
    onClick: () => void
    hideSensitivity?: boolean
}

const SensitivityVarIcon = ({ count, onClick, hideSensitivity = false }: SensitivityViewProps) => {
    return (
        <button hidden={hideSensitivity} onClick={onClick} className='btn btn-sm btn-outline-primary rounded-circle p-1 pillButton'>
            {count > 1 ?
                <div className='d-flex align-items-center justify-content-center' style={{width: '30px', height: '30px'}}>{count}</div> :
                <img src={sensitivityIcon} className='' alt='1'/>}
        </button>
    )
}

export default SensitivityVarIcon
