import {RouteComponentProps} from '@reach/router'
import {Collapse, Spinner} from 'react-bootstrap'
import {useProjectStore} from 'components/project/ProjectProvider'
import {observer} from 'mobx-react-lite'
import ResultSummary from 'components/project/pages/Results/ResultSummary'
import ResultAugmentation from 'components/project/pages/Results/ResultAugmentation'
import ResultTimeSeriesSection from 'components/project/pages/Results/ResultTimeSeriesSection'
import ResultSolarEnergy from 'components/project/pages/Results/ResultSolarEnergy'
import ResultStorage from 'components/project/pages/Results/ResultStorage'
import ResultRevenue from 'components/project/pages/Results/ResultRevenue'
import ResultCashFlow from 'components/project/pages/Results/ResultCashFlow'
import ResultProForma from 'components/project/pages/Results/ResultProForma'
import React, {useState} from 'react'
import {BiCaretDown, BiCaretRight} from 'react-icons/all'
import ResultWind from 'components/project/pages/Results/ResultWind'
import api from '../../../../services/api'
import ResultRegulationMarket from './ResultRegulationMarket'
import ResultCompareEconomics from './ResultCompareEconomics'


interface SelectedSystemProps extends RouteComponentProps {}


const SelectedSystem = observer((_: SelectedSystemProps) => {
    const project = useProjectStore().project!
    const results = project.results
    const details = results.details
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    const showBaseYearAsYearZero = api.showBaseYearAsYearZero

    return (<>
        {results.detailsState === 'loading' &&
            <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
                <Spinner animation='border' className='text-primary'/>
            </div>}
        {results.detailsState === 'error' && <>
            <ErrorView error={results.detailsError!}/>
        </>}
        {results.detailsState === 'ready' && <>
            {details.section === 'summary' &&
                <ResultSummary />}
            {details.section === 'regMarket' &&
                <ResultRegulationMarket result={details.details.regulationMarketResult} model={project} showBaseYearAsYearZero={showBaseYearAsYearZero} />}
            {details.section === 'wind' &&
                <ResultWind result={details.details.wind} model={project} />}
            {details.section === 'solar' &&
                <ResultSolarEnergy result={details.details.solar} model={project} showBaseYearAsYearZero={showBaseYearAsYearZero} />}
            {details.section === 'storage' &&
                <ResultStorage result={details.details.storage} model={project} showBaseYearAsYearZero={showBaseYearAsYearZero} />}
            {details.section === 'augmentation' &&
                <ResultAugmentation result={details.details.storage} baseYear={project.baseYear} intlCulture={intlCulture} showBaseYearAsYearZero={showBaseYearAsYearZero} />}
            {details.section === 'revenue' &&
                <ResultRevenue result={details.details.revenueResult} model={project} intlCulture={intlCulture} showBaseYearAsYearZero={showBaseYearAsYearZero} />}
            {details.section === 'timeseries' &&
                <ResultTimeSeriesSection showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={project.baseYear} />}
            {details.section === 'cashflow' &&
                <ResultCashFlow showBaseYearAsYearZero={showBaseYearAsYearZero} />}
            {details.section === 'proforma' &&
                <ResultProForma showBaseYearAsYearZero={showBaseYearAsYearZero} />}
            {details.section === 'compareEconomics' &&
                <ResultCompareEconomics showBaseYearAsYearZero={showBaseYearAsYearZero} baseYear={project.baseYear} intlCulture={intlCulture} />}
        </>}
    </>)
})


export default SelectedSystem


interface ErrorViewProps {
    error?: Error
}

const ErrorView = ({error}: ErrorViewProps) => {
    const [open, setOpen] = useState(false)

    return (
        <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
            <div className='alert alert-danger collapsible-group-header' onClick={() => setOpen(!open)}>
                <div className='d-flex flex-row align-items-center'>
                    {open ? <BiCaretDown className='mx-1'/> : <BiCaretRight className='mx-1'/>}
                    <div className='my-2'>Couldn't get detailed results</div>
                </div>
                <Collapse in={open}>
                    <div>
                        <hr/>
                        <div className='overflow-auto text-keep-lines' style={{maxHeight: '35rem', width: '35rem'}}>
                            {error?.message ?? 'Service Unavailable'}
                        </div>
                    </div>
                </Collapse>
            </div>
        </div>
    )
}
