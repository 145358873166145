import {observer} from 'mobx-react-lite'
import {RouteComponentProps} from '@reach/router'
import React, { useState } from 'react'
import CollapsibleGroup from 'components/helper/CollapsibleGroup'
import {useProjectStore} from 'components/project/ProjectProvider'
import {Dropdown} from 'react-bootstrap'
import IncentiveView from 'components/project/pages/IncentiveView'
import NumberField from 'components/helper/NumberField'
import {Validation} from 'components/helper/validation'
import {IncentiveKind} from 'components/project/model/Incentive'
import ContextHelp from 'components/helper/ContextHelp'
import { IntlProvider, LocalizationProvider } from 'Kendo-Intl-5'
import { UnlockProjectModal } from '../ProjectView'
import DeleteConfirmationModal, { DeleteComponentState } from './equipment/shared/DeleteConfirmationModal'


interface OtherPageProps extends RouteComponentProps {}


const getIncentiveLabel = (kind: IncentiveKind) => {
    switch (kind) {
        case 'itc':
            return 'Investment Tax Credit'
        case 'macrs':
            return 'MACRS'
        case 'bonus':
            return 'Bonus Depreciation'
        case 'prod':
            return 'Production Tax Credit/REC'
    }
}

const incentives: IncentiveKind[] = ['itc', 'macrs', 'bonus','prod']


const OtherPage = (_: OtherPageProps) => {
    const store = useProjectStore()
    const project = store.project!
    const currency = project.currencySymbol ?? "$"
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'

    const onNew = (x: string | null) => {
        const kind = x as IncentiveKind
        project.addNewIncentive(kind)
    }
    const handleCloseReadOnlyPopup = async () => {
        await store.updateShowReadOnlyPopup(false)
    }

    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [componentToDelete, setComponentToDelete] = useState<DeleteComponentState>({ kind: 'Component' })
    const onDelete = (componentToDelete: DeleteComponentState) => {
        setComponentToDelete(componentToDelete)
        setShowDeleteModal(true)
    }
    const onConfirmDelete = () => {
        if (componentToDelete.kind === 'Incentive')
            project.removeIncentive(componentToDelete.id!)
        setShowDeleteModal(false)
    }

    return (<>
        <LocalizationProvider language={intlCulture} >
            <IntlProvider locale={intlCulture}>
        <div className='d-flex flex-column p-4 overflow-scroll' style={{height: 'calc(100vh - 173px)'}}>
            <CollapsibleGroup title='Incentives' className='mb-4'>
                {project.incentives.map(x => <React.Fragment key={x.id}>
                    <div className='col-6 offset-3 my-4' id={x.id}>
                        <IncentiveView model={x} onDelete={() => onDelete({ kind: 'Incentive', id: x.id })} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>
                    <hr className='bg-dark'/>
                </React.Fragment>)}
                <div className='d-flex flex-column align-items-center my-4'>
                    <Dropdown onSelect={onNew}>
                        <Dropdown.Toggle variant='outline-primary' id='new-incentive'>Add new incentive</Dropdown.Toggle>
                        <Dropdown.Menu>
                            {incentives.map(x =>
                                <Dropdown.Item key={x} eventKey={x}>{getIncentiveLabel(x)}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </CollapsibleGroup>

            <CollapsibleGroup title='Economics' className='mb-4'>
                <div className='col-1 offset-11 my-3'><ContextHelp helpId='ECONOMICS' /></div>
                <div className='col-6 offset-3 my-3'>
                    <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label={`System fixed costs (${currency}) `} value={project.systemFixedCost} onChange={x => project.setSystemFixedCost(x)}
                                 validator={Validation.min(0)}/>
                    <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label={`System O&M costs (${currency}/yr) `} value={project.systemOmCost} onChange={x => project.setSystemOmCost(x)}
                                 validator={Validation.min(0)}/>
                    <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label='Discount rate (%) ' value={project.discountRate} onChange={x => project.setDiscountRate(x)}
                                 validator={Validation.percent}/>
                    <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label='Inflation rate (%) ' value={project.inflationRate} onChange={x => project.setInflationRate(x)}
                                 validator={Validation.percentAbs}/>

                    <div className='form-group row align-items-center'>
                        <label className='col-5'>Timestep Size (minutes) </label>
                        <div className='col-3'>
                            <Dropdown drop='down'>
                                <Dropdown.Toggle variant='outline-primary' id='economic_timestep_size_dropdown'>
                                    {project.timeStepSize}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => project.setTimeStepSize(60)}>60</Dropdown.Item>
                                    <Dropdown.Item onClick={() => project.setTimeStepSize(30)}>30</Dropdown.Item>
                                    <Dropdown.Item onClick={() => project.setTimeStepSize(15)}>15</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </CollapsibleGroup>
                </div>
            </IntlProvider >
        </LocalizationProvider>
        <UnlockProjectModal show={store.showReadOnlyPopup} onClose={handleCloseReadOnlyPopup} />
        <DeleteConfirmationModal show={showDeleteModal} componentState={componentToDelete} onCancel={() => setShowDeleteModal(false)} onConfirm={onConfirmDelete} />
        </>
    )
}

export default observer(OtherPage)
