import { observer } from "mobx-react-lite"
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import ContextHelp from "../helper/ContextHelp";
import NumberInput from "../helper/NumberInput";
import StringInput from "../helper/StringInput";
import { Validation } from "../helper/validation";
import { UserStorageClass } from "./UserStorage"
import { CapacityChart } from "./UserStorageChart";
import StorageCurveTable from "./UserStorageCurveTable";

interface StorageFuncModelTabProps {
    model: UserStorageClass
    useCommaAsDecimalPoint: boolean
}

const StorageLibraryFuncModelTab = observer(({ model, useCommaAsDecimalPoint }: StorageFuncModelTabProps) => {
    return (
        <>
            <Row className="mt-2 d-flex flex-column align-items-center justify-content-center">
                <h4>Capacity Curve</h4>
            </Row>
            <Row>
                <Col xs={3}>
                    <StorageCurveTable model={model.capacityCurve} useCommaAsDecimalPoint={useCommaAsDecimalPoint} setItem={model.setUserStorageCapacityCurveItem} deleteItem={model.userStorageCapacityCurveDeleteItem} addItem={model.userStorageCapacityCurveAddRow} columnHead1="Power (W)" columnHead2="Capacity (Wh)" />
                </Col>
                <Col xs={5}>
                    <CapacityChart model={model.capacityCurve} />
                </Col>
                <Col xs={4}>
                    <Row>
                        <label className="col-7 mt-2">Nominal Voltage (V)</label>
                        <NumberInput value={model.nominalVoltage}
                            onChange={model.setUserStorageNominalVoltage}
                            className="mt-1 col-2 mb-4 mr-4"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0)} />
                        <ContextHelp helpId='USERLIBRARYFUNCMODEL' />
                    </Row>
                    <p><b>Parameters Calculated from Capacity Curve:</b></p>
                    <Row>
                        <label className="col-7 mt-2">Maximum Capacity (Ah):</label>
                        <NumberInput value={model.maximumCapacity}
                            onChange={model.setUserStorageMaximumCapacity}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0)}/>
                    </Row>
                    <Row>
                        <label className="col-7 mt-2">Rate Constant (1/hr):</label>
                        <NumberInput value={model.rateConstant}
                            onChange={model.setUserStorageRateConstant}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0)}/>
                    </Row>
                    <Row>
                        <label className="col-7 mt-2">Capacity Ratio:</label>
                        <NumberInput value={model.capacityRatio}
                            onChange={model.setUserStorageCapacityRatio}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0)}/>
                    </Row>
                    <Row>
                        <label className="col-7 mt-2">Effective Series Resistance (ohm):</label>
                        <NumberInput value={model.effectiveSeriesResistance}
                            onChange={model.setUserStorageEffectiveSeriesResistance}
                            className="mt-1 col-2"
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} validator={Validation.min(0, false)}/>
                    </Row>
                </Col>
            </Row>
            <Row className="mt-1 d-flex">

            </Row>
        </>
    )
})

export default StorageLibraryFuncModelTab
