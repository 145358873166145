import { action, makeObservable, observable } from "mobx"
import { v4 } from "uuid"
import { EnergyMarket } from "./EnergyMarket"
import { SensitivityVar } from "./SensitivityVar"
import { TimeSeries } from "./TimeSeries"

export class RegulationMarket {
    id: string = v4()
    constructor() { makeObservable(this) }

    @observable name: string = ''
    @observable abbreviation: string = ''
    @observable useRegUp: boolean = false
    @observable useRegDown: boolean = false
    @observable regUpPriceYear: number = 2023
    @observable regDownPriceYear: number = 2023
    @observable regUpPrice: TimeSeries = new TimeSeries()
    @observable regUpPriceName: string = 'Reg Up Price' 
    @observable regDownPrice: TimeSeries = new TimeSeries()
    @observable regDownPriceName: string = 'Reg Down Price' 
    @observable maxCommitmentRegUp: SensitivityVar = new SensitivityVar(30) // (%)
    @observable maxCommitmentRegDown: SensitivityVar = new SensitivityVar(30) // (%)
    @observable minRegUpPrice: SensitivityVar = new SensitivityVar(0) // ($/MWh)
    @observable minRegDownPrice: SensitivityVar = new SensitivityVar(0) // ($/MWh)
    @observable useEnergyPrice: boolean = false
    @observable energyPriceYear: number = 2023
    //@observable energyPrice: TimeSeries = new TimeSeries()
    @observable throughputPercentage: SensitivityVar = new SensitivityVar(10) // (%)
    @observable energyMarket: EnergyMarket | null = null


    @action setName(x: string) { this.name = x }

    @action setRegUpPriceName(x: string) { this.regUpPriceName = x }
    @action setRegDownPriceName(x: string) { this.regDownPriceName = x }

    @action setAbbreviation(x: string) { this.abbreviation = x }
    @action setUseRegUp(x: boolean) { this.useRegUp = x }
    @action setUseRegDown(x: boolean) { this.useRegDown = x }
    @action setRegUpData(data: number[], year: number) {
        this.regUpPrice.setData(data, year)
        this.regUpPriceYear = year
          //const average = this.price.calculateAverage()
          //this.priceAverage.setSingleValue(average)
    }   
    @action setRegDownData(data: number[], year: number) {
        this.regDownPrice.setData(data, year)
        this.regDownPriceYear = year
    }
    @action setUseEnergyPrice(x: boolean) { this.useEnergyPrice = x }
    @action setEnergyPriceYear(x: number) { this.energyPriceYear = x }
    //@action setEnergyData(data: number[], year: number) {
    //    this.energyPrice.setData(data, year)
    //    this.energyPriceYear = year
    //}
    @action setEnergyMarket(x: EnergyMarket | null) { this.energyMarket = x }

}
