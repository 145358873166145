import Form from 'react-bootstrap/Form'
import {useState} from 'react'
import ScheduleTimeGrid, {EditMode} from 'components/project/capacityMarket/ScheduleGridView'


export interface ScheduleElementProps {
    value: number[][]
    onChange: (x: number[][]) => void
    id: string
    radioLabel1: string
    radioLabel2: string
}


const COLORS = [
    '#e4965c', // OFF
    '#258687', // ON
]


const ScheduleEditor = ({value, onChange, id, radioLabel1, radioLabel2 }: ScheduleElementProps) => {
    const [mode, setMode] = useState<EditMode>('all')
    const [marker, setMarker] = useState(1)

    return (
        <div className='d-flex flex-column'>
            <div className='d-flex flex-row align-items-center'>
                <div className='d-flex flex-column m-1'>
                    <Form.Check type='radio' id='schedule-on' custom
                                checked={marker === 1}
                                onChange={ev => ev.target.checked && setMarker(1)}
                                label={
                                    <div className='d-flex align-items-center'>
                                        <ColorRect color={COLORS[1]}/>
                                        {radioLabel1}
                                    </div>
                                }/>
                    <Form.Check type='radio' id='schedule-off' custom
                                checked={marker === 0}
                                onChange={ev => ev.target.checked && setMarker(0)}
                                label={
                                    <div className='d-flex align-items-center'>
                                        <ColorRect color={COLORS[0]}/>
                                        {radioLabel2}
                                    </div>
                                }/>
                </div>
                <div className='m-3'/>
                <div className='d-flex flex-column m-1'>
                    <Form.Check type='radio' id={`mode-all-${id}`} custom
                                checked={mode === 'all'}
                                onChange={ev => ev.target.checked && setMode('all')}
                                label='All Week'/>
                    <Form.Check type='radio' id={`mode-weekday-${id}`} custom
                                checked={mode === 'weekday'}
                                onChange={ev => ev.target.checked && setMode('weekday')}
                                label='Weekdays'/>
                    <Form.Check type='radio' id={`mode-weekend-${id}`} custom
                                checked={mode === 'weekend'}
                                onChange={ev => ev.target.checked && setMode('weekend')}
                                label='Weekends'/>
                </div>
            </div>

            <ScheduleTimeGrid value={value} onChange={onChange}
                              colors={COLORS}
                              mode={mode} marker={marker}/>
        </div>
    )
}


export default ScheduleEditor


interface ColorRectProps {color: string}

const COLOR_REACT_STYLE = {
    width: '20px',
    height: '20px',
    border: 'solid black 1px',
    display: 'inline-block',
}

const ColorRect = ({color}: ColorRectProps) => <div style={{...COLOR_REACT_STYLE, background: color}} className='mr-1'/>
