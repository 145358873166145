import {v4} from 'uuid'
import {makeObservable, observable} from 'mobx'
import {Sizing} from 'components/project/model/Sizing'
import {CostTable} from 'components/project/model/CostTable'
import {MultiYearVar} from 'components/project/model/MultiYearVar'
import {SensitivityVar} from 'components/project/model/SensitivityVar'


export class Inverter {
    id: string = v4()
    constructor() { makeObservable(this) }

    @observable sizing: Sizing = new Sizing()
    @observable cost: CostTable = new CostTable()
    @observable omEscalator: MultiYearVar = new MultiYearVar()
    @observable replacementEscalator: MultiYearVar = new MultiYearVar()
    @observable efficiency: SensitivityVar = new SensitivityVar(98) // (%)
    @observable lifetime: SensitivityVar = new SensitivityVar(15) // (years)

    estimateSimulations(): number {
        const sens =
            this.efficiency.estimateSimulations() *
            this.lifetime.estimateSimulations()
        const size = this.sizing.estimateSimulations()
        const cost = this.cost.estimateSimulations()
        return sens * size * cost
    }
}
