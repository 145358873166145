import { observer } from 'mobx-react-lite'
import React from 'react'
import { Button, Modal } from 'react-bootstrap'

interface DeleteConfirmationModalProps {
    show: boolean
    onCancel?: () => void
    onConfirm?: () => void
    componentState: DeleteComponentState
    label?: string
}

const DeleteConfirmationModal = observer(({ show, onCancel, onConfirm, componentState, label }: DeleteConfirmationModalProps) => {

    const handleCancel = () => {
        onCancel?.()
    }

    const handleConfirm = () => {
        onConfirm?.()
    }
    
    return (
        <Modal show={show} onHide={handleCancel} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Delete {`${componentState.kind}`}</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete the information associated with this {`${componentState.kind}`}?</Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' variant='secondary' onClick={handleCancel}>Cancel</Button>
                <Button className='pillButton' variant='primary' onClick={handleConfirm}>Continue</Button>
            </Modal.Footer>
        </Modal>
    )
})

export default DeleteConfirmationModal

export type DeleteComponentState
    = { kind: 'Component' }
    | { kind: 'PV', id?: string }
    | { kind: 'Wind Turbine', id?: string }
    | { kind: 'Storage', id?: string }
    | { kind: 'Energy Market', id?: string }
    | { kind: 'Capacity Market', id?: string }
    | { kind: 'Regulation Market', id?: string }
    | { kind: 'TOD Contract', id?: string }
    | { kind: 'Incentive', id?: string }
