import {observer} from 'mobx-react-lite'
import {RouteComponentProps} from '@reach/router'
import {StorageResult} from 'components/project/results-details'
import AugmentationCapacityChart from 'components/project/pages/Results/AugmentationCapacityChart'
import AugmentationCostChart from 'components/project/pages/Results/AugmentationCostChart'
import AugmentationTable from 'components/project/pages/Results/AugmentationTable'
import ContextHelp from 'components/helper/ContextHelp'

interface ResultAugmentationProps extends RouteComponentProps {
    result: StorageResult,
    baseYear: number
    intlCulture: string
    showBaseYearAsYearZero: boolean
}

const ResultAugmentation = observer(({result, baseYear, intlCulture, showBaseYearAsYearZero}: ResultAugmentationProps) => {
    return (
        <div className='row m-2'>
            <div className='col-6'>
                <AugmentationCapacityChart result={result} baseYear={baseYear} showBaseYearAsYearZero={showBaseYearAsYearZero} />
            </div>
            <div className='col-6'>
                <AugmentationCostChart result={result} baseYear={baseYear} showBaseYearAsYearZero={showBaseYearAsYearZero} />
            </div>
            <div className='col-12'>
                <div>
                    <div className='float-right mb-1'>
                        <ContextHelp helpId='AUGCOST'/>
                    </div>
                    <AugmentationTable items={result.items} baseYear={baseYear} intlCulture={intlCulture} showBaseYearAsYearZero={showBaseYearAsYearZero} />
                </div>
            </div>
        </div>
    )
})

export default ResultAugmentation
