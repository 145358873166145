import {action, makeObservable, observable} from 'mobx'
import {v4} from 'uuid'


export type TimeSeriesState = 'blank' | 'ready'


export class TimeSeries {
    constructor() { makeObservable(this) }
    id: string = v4()

    @observable state: TimeSeriesState = 'blank'
    @observable data: number[] = observable(Array(365 * 24).fill(0))
    @observable year: number = 2007

    @action setData(data: number[], year: number) {
        this.state = 'ready'
        this.data = observable(data)
        this.year = year
    }
    //@action setYear(year: number) {this.year = year}

    calculateAverage(): number {
        const sum = this.data.reduce((acc, x) => acc + x)
        const n = this.data.length === 0 ? 1 : this.data.length
        return sum / n
    }
}
