import React, { useEffect, useState } from 'react'
import { LibraryStore } from './LibraryStore'
import LibraryProvider from './LibraryProvider'
import api from 'services/api'
import { GoPlus } from 'react-icons/all'
import { navigate, RouteComponentProps } from '@reach/router'
import './LibraryView.scss'
import ProjectTableListView from '../project/ProjectTableListView'
import { Button, Modal, Spinner } from 'react-bootstrap'
import ContextHelp from 'components/helper/ContextHelp'
import Dropdown from 'react-bootstrap/Dropdown'
import { SampleDto } from 'services/Service'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import envVariables from 'env-variables.json'
import { IntlProvider, LocalizationProvider } from 'Kendo-Intl-5'
import { projectListStatus } from '../../App'

interface LibraryViewProps extends RouteComponentProps {
    isProjectSaving: projectListStatus
    setIsProjectSaving: (x: projectListStatus) => void
}


const LibraryView = ({ isProjectSaving, setIsProjectSaving }: LibraryViewProps) => {
    const intlCulture = api.defaultDecimalPoint ? 'es' : 'en'
    const [libraryStore] = useState(new LibraryStore())
    const refresh = async () => {
        await libraryStore.fetchProjects()
    }

    const handleCopy = async (id: string) => {
        setOperation(Operation.Copy)
        await api.duplicate(id)
        await refresh()
    }

    useEffect(() => {
        const refreshAfterProjectSave = async () => {
            await refresh();
            setIsProjectSaving('ready')
        }
        if (isProjectSaving === 'loading')
            refreshAfterProjectSave()
                .catch(console.error);
    }, [isProjectSaving])

    const handleDownload = async (id: string) => await api.downloadHomerFile(id)

    const [showReportIssue, setShowReportIssue] = useState(false)
    const [showShareProject, setShowShareProject] = useState(false)
    const [projectIdToReport, setProjectIdToReport] = useState<string | null>(null)
    const [projectIdToShare, setProjectIdToShare] = useState<string | null>(null)
    const handleReport = async (id: string) => {
        setOperation(Operation.Report)
        setProjectIdToReport(id)
        setShowReportIssue(true)
    }
    const handleConfirmReport = async (description: string) => {
        setShowReportIssue(false)
        if (!projectIdToReport) { return }
        await api.reportIssue(projectIdToReport, description)
        setShowSuccess(true)
    }
    const handleCancelReport = () => setShowReportIssue(false)

    const [hideError, setHideError] = useState(true)

    const handleShare = async (id: string) => {
        setOperation(Operation.Share)
        setProjectIdToShare(id)
        setShowShareProject(true)
    }
    const handleConfirmShare = async (email: string, description: string) => {
        if (!projectIdToShare) { return }
        var response = await api.shareProject(projectIdToShare, email, description)
        if (response === 201) {
            setShowShareProject(false)
            setHideError(true)
            setShowSuccess(true)
        }
        else
            setHideError(false)

    }
    const handleCancelShare = () => setShowShareProject(false)

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [projectIdToDelete, setProjectIdToDelete] = useState<string | null>(null)
    const handleDelete = async (id: string) => {
        setOperation(Operation.Delete)
        setProjectIdToDelete(id)
        setShowDeleteConfirmation(true)
    }
    const handleConfirmDelete = async () => {
        setShowDeleteConfirmation(false)
        if (!projectIdToDelete) { return }
        await api.delete(projectIdToDelete)
        await refresh()
    }
    const handleCancelDelete = () => setShowDeleteConfirmation(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const handleConfirmSuccess = () => {
        setShowSuccess(false)
    }
    const [operation, setOperation] = useState(Operation.Copy)

    useEffect(() => {
        // initial project list loading
        libraryStore.fetchProjects().then()
    }, [libraryStore])

    const appInsights = new ApplicationInsights({
        config: {
            connectionString: envVariables[process.env.NODE_ENV].appInsightsConnString
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    return (
        <LocalizationProvider language={intlCulture} >
            <IntlProvider locale={intlCulture}>
                <LibraryProvider value={libraryStore}>
                    <div className='d-flex flex-column h-100'>
                        <ActionBar />
                        <div className='py-2' />
                        <div className='bg-white h-100 rounded-lg shadow-page overflow-auto'>
                            {isProjectSaving === 'ready' && <ProjectTableListView onDelete={handleDelete} onCopy={handleCopy} onDownload={handleDownload} onShare={handleShare} onReport={handleReport} />}
                            {isProjectSaving !== 'ready' && <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
                                <Spinner animation='border' className='text-primary' />
                            </div>}
                        </div>
                    </div>
                    <DeleteConfirmationModal show={showDeleteConfirmation} onConfirm={handleConfirmDelete} onCancel={handleCancelDelete} />
                    <ShareProjectModal show={showShareProject} hideError={hideError} setHideError={setHideError} onConfirm={handleConfirmShare} onCancel={handleCancelShare} />
                    <ReportIssueModal show={showReportIssue} onConfirm={handleConfirmReport} onCancel={handleCancelReport} />
                    <SuccessModal show={showSuccess} operation={operation} onConfirm={handleConfirmSuccess} />
                </LibraryProvider>
            </IntlProvider>
        </LocalizationProvider>
    )
}

export default LibraryView


const ActionBar = () => {
    const [samples, setSamples] = useState<SampleDto[]>([])
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        api.listSamples().then(rv => setSamples(rv))
    })

    const appInsights = new ApplicationInsights({
        config: {
            connectionString: envVariables[process.env.NODE_ENV].appInsightsConnString
        }
    });
    appInsights.loadAppInsights();

    const handleCreateProject = async () => {
        const rv = await api.create()
        window.localStorage.removeItem('currentStep')
        await navigate(`/project/${rv.id}`)
        appInsights.trackPageView();
    }

    const handleCloneSample = async (id: string) => {
        setShow(true)
        const rv = await api.cloneSample(id)
        await navigate(`/project/${rv.id}`)
        setShow(false)
        appInsights.trackPageView();
    }

    return (
        <div className='d-flex flex-row justify-content-between'>
            <div className='d-flex flex-row align-content-center'>
                <span className='page-title mr-2'>Project Listing</span>
                <ContextHelp helpId='PROJECTLISTING' />
            </div>

            <div className='d-flex flex-row align-content-center'>
                <Spinner hidden={!show} animation='border' className='text-primary loader-spinner mr-3' />
                {samples.length > 0 &&
                    <Dropdown>
                        <Dropdown.Toggle variant='outline-primary pillButton' className='d-flex align-items-center mr-2'>
                            Open Sample
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {samples.map(item =>
                                <Dropdown.Item key={item.id} onClick={() => handleCloneSample(item.id)}>{item.name}</Dropdown.Item>)}
                        </Dropdown.Menu>
                    </Dropdown>}

                <button id='createNewProject' className='btn btn-primary d-flex align-items-center pillButton' onClick={handleCreateProject}>
                    <GoPlus className='mr-2' />
                    Create New Project
                </button>
            </div>
        </div>
    )
}


interface ReportIssueModalProps {
    show: boolean
    onConfirm?: (message: string) => void
    onCancel?: () => void
}

const ReportIssueModal = ({ show, onConfirm, onCancel }: ReportIssueModalProps) => {
    const [description, setDescription] = useState('')
    useEffect(() => { setDescription('') }, [show])

    return (
        <Modal show={show} onHide={onCancel}>
            <Modal.Header closeButton onHide={onCancel}>
                <Modal.Title>Report Issue</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className='form-group row align-items-center'>
                    <label className='col-12'>Description</label>
                    <div className='col-12'>
                        <textarea className='form-control'
                            value={description} onChange={ev => setDescription(ev.target.value)} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={onCancel} variant='outline-primary'>Cancel</Button>
            <Button className='pillButton' onClick={() => onConfirm?.(description)}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}

interface ShareProjectModalProps {
    show: boolean
    hideError: boolean
    setHideError?: (show: boolean) => void
    onConfirm?: (email: string, message: string) => void
    onCancel?: () => void
}

const ShareProjectModal = ({ show, hideError, setHideError, onConfirm, onCancel }: ShareProjectModalProps) => {
    const [email, setEmail] = useState('')
    useEffect(() => { setEmail('') }, [show])

    const [description, setDescription] = useState('')
    useEffect(() => { setDescription('') }, [show])

    const [hideAlert, setHideAlert] = useState(true)

    const onCancelShare = () => {
        setHideAlert(true)
        setHideError?.(true)
        onCancel?.()
    }

    const onConfirmShare = (email: string, description: string) => {
        if (email == undefined || email == '')
            setHideAlert(false)
        else {
            setHideAlert(true)
            onConfirm?.(email, description)
        }
    }

    return (
        <Modal show={show} onHide={onCancelShare}>
            <Modal.Header closeButton onHide={onCancelShare}>
                Share
            </Modal.Header>
            <Modal.Body>
                <div className='form-group row align-items-center'>
                    <label className='col-12 alert alert-danger' hidden={hideAlert}>Please provide the email associated with the user to share the project</label>
                    <label className='col-12 alert alert-danger' hidden={hideError}>Error sharing the project, please make sure the provided email is associated with a Homer Front license</label>
                </div>
                <div className='form-group row align-items-center'>
                    <label className='col-12'>Email of user to share</label>
                    <div className='col-12'>
                        <input className='form-control'
                            value={email} onChange={ev => setEmail(ev.target.value)} />
                    </div>
                </div>
                <div className='form-group row align-items-center'>
                    <label className='col-12'>Description</label>
                    <div className='col-12'>
                        <textarea className='form-control'
                            value={description} onChange={ev => setDescription(ev.target.value)} />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={onCancelShare} variant='outline-primary'>Cancel</Button>
                <Button className='pillButton' onClick={() => onConfirmShare(email, description)}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}



interface DeleteConfirmationModalProps {
    show: boolean
    onCancel?: () => void
    onConfirm?: () => void
}

const DeleteConfirmationModal = ({ show, onCancel, onConfirm }: DeleteConfirmationModalProps) => {
    const [visible, setVisible] = useState(show)
    useEffect(() => { setVisible(show) }, [show])

    const handleCancel = () => {
        setVisible(false)
        onCancel?.()
    }

    const handleConfirm = () => {
        setVisible(false)
        onConfirm?.()
    }

    return (
        <Modal show={visible} onHide={handleCancel} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure? Projects that are deleted cannot be restored.</Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' variant='secondary' onClick={handleCancel}>Cancel</Button>
                <Button className='pillButton' variant='primary' onClick={handleConfirm}>Continue</Button>
            </Modal.Footer>
        </Modal>
    )
}

export enum Operation {
    Copy,
    Delete,
    Download,
    Report,
    Share
}

interface SuccessModalProps {
    show: boolean
    operation: Operation
    onConfirm?: () => void
}

const SuccessModal = ({ show, operation, onConfirm }: SuccessModalProps) => {
    //const [description, setDescription] = useState('')
    //useEffect(() => { setDescription('') }, [show])

    return (
        <Modal show={show} backdrop='static'>
            <Modal.Header>
                <Modal.Title>{Operation[operation]}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='alert alert-success'>Operation performed successfully!</Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={() => onConfirm?.()}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}
