import {useState, useEffect, ChangeEvent, FocusEvent, KeyboardEvent,ClipboardEvent} from 'react'
import {ValidationFunc} from 'components/helper/validation'
import { useInternationalization } from 'Kendo-Intl-5'


interface NumberInputProps {
    value: number
    onChange?: (x: number) => void
    onEnter?: () => void

    id?: string
    className?: string
    disabled?: boolean,
    autoFocus?: boolean

    validator?: ValidationFunc
    onPaste?: (x: ClipboardEvent<HTMLInputElement>) => void
    useCommaAsDecimalPoint: boolean
}


const NumberInput = ({value, onChange, onEnter, id, className, disabled, autoFocus, validator, onPaste, useCommaAsDecimalPoint}: NumberInputProps) => {
    let formatedText = String(value)
    const intl = useInternationalization()
    if (useCommaAsDecimalPoint)
        formatedText = formatedText.replace(/\,/g, "").replace(/\./g, ",")

    const [text, setText] = useState(formatedText)
    const [errors, setErrors] = useState<string[]>([])

    useEffect(() => {
        formatedText = String(value)
        if (useCommaAsDecimalPoint)
            formatedText = formatedText.replace(/\,/g, "").replace(/\./g, ",")
        setText(String(formatedText))
    }, [value])

    useEffect(() => {
        setText(formatedText)
    }, [useCommaAsDecimalPoint])

    const commit = (inputText: string) => {
        let formatedInputText = inputText
        if (useCommaAsDecimalPoint)
            formatedInputText = formatedInputText.replace(/\./g, "").replace(/\,/g, ".")
        const value = Number(formatedInputText)
        const good = !Number.isNaN(value)
        if (!good) {
            const errors = ['Enter a number']
            setErrors(errors)
            if (useCommaAsDecimalPoint)
                formatedInputText = formatedInputText.replace(/\./g, "").replace(/\,/g, ".")
        } else {
            const errors = validator?.(value) ?? []
            setErrors(errors)
            onChange?.(value)
        }
    }

    const handleChange = (ev: ChangeEvent<HTMLInputElement>) => {
        setText(ev.target.value)
        setErrors([])
    }

    const handleOnPaste = (ev: ClipboardEvent<HTMLInputElement>) => {
        onPaste?.(ev)
    }
    const handleBlur = (ev: FocusEvent<HTMLInputElement>) => {
        commit(text)
    }

    const handleKey = (ev: KeyboardEvent<HTMLInputElement>) => {
        if (ev.key !== 'Enter') return
        commit(text)
        onEnter?.()
    }

    const c = 'form-control ' +
        (errors.length > 0 ? 'is-invalid ' : '') +
        (className ?? '')

    return (
        <>
            <input type='text' id={id} className={c} disabled={disabled}
                   value={text}
                   onChange={handleChange}
                   onBlur={handleBlur}
                   onKeyPress={handleKey}
                autoFocus={autoFocus}
                onPaste={handleOnPaste}/>
            {errors.length > 0 &&
            <div className='invalid-tooltip text-keep-lines'>
                {errors.join('\n')}
            </div>}
        </>
    )
}


export default NumberInput
