import { observer } from 'mobx-react-lite'
import { useProjectStore } from 'components/project/ProjectProvider'
import CostTableView from './shared/CostTableView'
import MultiYearView from 'components/project/pages/SharedComponents/MultiYearView'
import { Form } from 'react-bootstrap'
import { GoX } from 'react-icons/all'
import InverterView from 'components/project/pages/equipment/InverterView'
import { ChangeUnitOptionSetCurrency, solarUnits } from 'components/project/pages/equipment/shared/DetailCostBreakup'
import SizingView from 'components/project/pages/equipment/shared/SizingView'
import NumberInput from 'components/helper/NumberInput'
import TimeSeriesView from 'components/helper/TimeSeriesView'
import ContextHelp from 'components/helper/ContextHelp'
import { Validation } from 'components/helper/validation'
import { Solar } from 'components/project/model/Solar'
import MultiTimeSeriesImport, { MultiFileData, MultiImportState } from 'components/helper/MultiTimeSeriesImport'
import StringField from '../../../helper/StringField'
import { StrValidation } from '../../../helper/StringValidation'
import SensitivityVarView from '../EnergyMarket/SensitivityVarView'
import { ComponentKind, SizingKind } from '../../../../services/inputs'


interface SolarViewProps {
    model: Solar
    index: number
    state: MultiImportState
    setState: (state: MultiImportState) => void
    fileData: MultiFileData[]
    setFileData: (fileData: MultiFileData[]) => void
    onDelete?: () => void
    handleSelect?: (sourceList: string[], customName: string[], customSize: number[], data: number[][], year: number, index: number) => void
    handleDeleteFile: (id: number) => void
}


const SolarView = observer(({ model, index, state, setState, fileData, setFileData, onDelete, handleSelect, handleDeleteFile }: SolarViewProps) => {
    const project = useProjectStore().project!
    const currency = useProjectStore().project!.currencySymbol ?? "$"
    const solarUnitsAux = ChangeUnitOptionSetCurrency(solarUnits, currency)
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint

    const converterLabel = project.bus === 'ac' ? 'Solar DC/AC Inverter' : 'Solar DC/DC Converter'
    const converterRelativeUnits = project.bus === 'ac' ? 'PV/Conv. Ratio' : 'DC/AC Ratio'

    const customPvCount = project.solar.filter(x => x.kind === ComponentKind.custom).length

    const isHomer = model.kind === ComponentKind.homer
    const isCustom = model.kind === ComponentKind.custom

    model.customName = model.customName === '' ? `PV Solar ${index + 1}` : model.customName
    model.customAbbreviation = model.customAbbreviation === '' ? `PV ${index + 1}` : model.customAbbreviation


    return (
        <>
            <div className='d-flex align-items-center mb-2'>
                <div className='d-flex flex-row align-items-start'>
                    <Form.Check id={model.id + '-kind-homer'} type='radio' label='HOMER PV Calculator'
                        className='mr-2 align-items-center' custom
                        checked={model.kind === ComponentKind.homer}
                        onChange={ev => ev.target.value && model.setKind(ComponentKind.homer)} />
                    <Form.Check id={model.id + '-kind-custom'} type='radio' label='PVsyst Import' custom
                        checked={model.kind === ComponentKind.custom}
                        onChange={ev => ev.target.value && model.setKind(ComponentKind.custom)} />
                </div>
                <div className='ml-2'>
                    <ContextHelp helpId='PVCALC' />
                </div>
                <div className='ml-5'>
                    <StringField label="PV Name" value={model.customName} onChange={x => model.setCustomName(x)} validator={StrValidation.maxLen(40)} />
                </div>
                <div>
                    <StringField label="Abbreviation" value={model.customAbbreviation} onChange={x => model.setCustomAbbreviation(x)} validator={StrValidation.maxLen(20)} />
                </div>
                <div className='m-auto' />
                <div>
                    <button className='btn btn-outline-danger pillButton' onClick={() => onDelete?.()}><GoX /></button>
                </div>
            </div>

            {isCustom && <SolarImportView state={state} index={index} setState={setState} model={model} fileData={fileData} setFileData={setFileData} handleSelect={handleSelect} handleDeleteFile={handleDeleteFile} />}

            <div className='form-group d-flex mb-2'>
                <div className='border rounded w-50 mr-1 p-2'>
                    {isHomer && <>
                        <SizingView id={model.id} model={model.sizing} absoluteUnits='MW' useCommaAsDecimalPoint={useCommaAsDecimalPoint} customLabel='and Configuration' />
                        <div className='d-flex flex-row align-items-center mt-5'>
                            <SensitivityVarView model={model.deratingFactor} label='Derating Factor (%)' units='%' useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            <ContextHelp helpId='PVDERATINGFACTOR' />
                        </div>
                    </>}
                    {isCustom && <div className='d-flex flex-column align-items-center m-2'>
                        <div className='w-100'>
                            <SizingView id={model.id} model={model.customSizeArray} absoluteUnits='MW' customLabel='and Configuration' useCommaAsDecimalPoint={useCommaAsDecimalPoint} showLabel={false} hideHelp={true} />
                        </div>
                    </div>}
                </div>

                <div className='border rounded w-50 ml-1 p-2'>
                    <CostTableView cost={model.cost} id={model.id}
                        showDetailedCostTable={true} unitsSet={solarUnitsAux}
                        showReplacement={false} showDetailedReplacement={false}
                        simpleCostLabel='Cost Sensitivity Analysis'
                        complexCostLabel='Cost Breakdown' isStorage={false} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                        <MultiYearView model={model.omEscalator}
                            label='O&M cost escalator (%/yr)'
                            id={model.id + '-om'} />
                    <MultiYearView model={model.degradation} validator={Validation.range(0, 99.99)}
                        label='PV degradation (%/yr)'
                        id={model.id + '-degradation'} />
                </div>
            </div>

            <div className='border rounded p-2 mb-2'>
                <div>
                    <Form.Check custom label={converterLabel} id={`solar-hasConverter-${model.id}`} type='switch'
                        checked={model.hasConverter}
                        onChange={ev => model.setHasConverter(ev.target.checked)} />
                </div>
                {model.hasConverter &&
                    <div className='mt-2'>
                        <InverterView model={model.converter} label={converterLabel}
                            absoluteUnits='MW' relativeUnits={converterRelativeUnits}
                            relativeLabel='Size relative to solar'
                            sizeValidator={model.converter.sizing.kind === SizingKind.relative ? Validation.range(0, 10, true, true) : undefined}
                            useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                    </div>}
            </div>
        </>
    )
})


export default SolarView

interface SolarImportViewProps {
    model: Solar
    index: number
    state: MultiImportState
    setState: (state: MultiImportState) => void
    fileData: MultiFileData[]
    setFileData: (fileData: MultiFileData[]) => void
    handleSelect?: (sourceList: string[], customName: string[], customSize: number[], data: number[][], year: number, index: number) => void
    handleDeleteFile: (id: number) => void
}

const SolarImportView = observer(({ model, index, state, setState, fileData, setFileData, handleSelect, handleDeleteFile }: SolarImportViewProps) => {
    const store = useProjectStore()
    const intlCulture = store.project!.useCommaAsDecimalPoint ? 'es' : 'en'

    const ts = model.customData
    return (
        <div className='d-flex flex-column align-items-stretch align-self-stretch'>
            <div className='mb-2'>Import .csv file from PVsyst in kW</div>
            <div className='mb-2'>
                <MultiTimeSeriesImport onSelect={handleSelect} index={index} state={state} setState={setState} fileData={fileData} setFileData={setFileData} handleDeleteFile={handleDeleteFile} addFiles={false} allowMultipleImport={true} isPv={true} />
            </div>

            {ts.state === 'blank' && <>
                <div className='mb-2 font-weight-bold'>You need to export your file from PVsyst with a specific format:</div>
                <ul>
                    <li>A .csv file format with a semicolon (;) separator</li>
                    <li>Date and hour format of DD/MM/YY MM:SS</li>
                    <li>Hourly values</li>
                    <li>Units of the PV production should be in kW</li>
                    <li>Only the value for the total PV production from the array should be exposed</li>
                    <li>Check the '1 defined variable' is the only value being written to the .csv</li>
                </ul>
            </>}

            {ts.state === 'ready' && <TimeSeriesView intlCulture={intlCulture} model={ts} chartTitle={`${model.customName} Imported Data (MW/MWh-peak based on ${(model.customSize !== undefined && model.customSize > 0 ? model.customSize : 0)} MW array size)`} yAxisTitle='MW/MW-peak' />}
        </div>
    )
})
