import { RouteComponentProps } from '@reach/router'
import { Project } from 'components/project/model'
import { StorageResult } from 'components/project/results-details'
import { observer } from 'mobx-react-lite'
import ResultStorageTable from 'components/project/pages/Results/ResultStorageTable'
import ResultStorageMetricTable from 'components/project/pages/Results/ResultStorageMetricTable'
import TimeSeriesView from 'components/helper/TimeSeriesView'
import { useMemo } from 'react'
import ResultStorageChartByMonth from 'components/project/pages/Results/ResultStorageChartByMonth'
import ResultStorageChartByHour from 'components/project/pages/Results/ResultStorageChartByHour'
import ContextHelp from 'components/helper/ContextHelp'
import ResultStorageSpecTable from 'components/project/pages/Results/ResultStorageSpecTable'
import { TimeSeries } from 'components/project/model/TimeSeries'

interface ResultStorageProps extends RouteComponentProps {
    result: StorageResult
    model: Project
    showBaseYearAsYearZero: boolean
}

const ResultStorage = observer(({ result, model, showBaseYearAsYearZero }: ResultStorageProps) => {
    const ts = useMemo(() => {
        const rv = new TimeSeries()
        rv.setData(result.stateOfChargeTimeSeries, model.baseYear)
        return rv
    }, [result.stateOfChargeTimeSeries, model.baseYear])
    const intlCulture = model.useCommaAsDecimalPoint ? 'es' : 'en'

    const dateFormat = model.dateFormat
    const storageInput = model.storage.length >= result.index + 1 && model.storage[result.index] ? model.storage[result.index] : model.storage[0]
    let defermentPeriod = 0
    let defermentUnit = ''
    if (storageInput.hasDeferment) {
        if (!storageInput.deferment.isExactDate) {
            defermentPeriod = storageInput.deferment.period
            if (storageInput.deferment.periodUnit === 0) {
                defermentUnit = 'day(s)'
            }
            else if (storageInput.deferment.periodUnit === 1) {
                defermentUnit = 'month(s)'
            }
            else {
                defermentUnit = 'year(s)'
            }
        }
        else {
            let defermentDate = new Date(storageInput.deferment.startDate)
            let differenceInTime = model.expectedDate.getTime() - defermentDate.getTime();
            defermentPeriod = Math.abs(Math.round(differenceInTime / (1000 * 3600 * 24)));
        }
    }

    return (<>
        {storageInput.hasDeferment && <div className="ml-4 mb-5 mt-3">
            {!storageInput.deferment.isExactDate && <h3>{`This storage has been deffered by ${defermentPeriod} ${defermentUnit}`}</h3>}
            {storageInput.deferment.isExactDate && <h3>{`This Storage was deffered by ${defermentPeriod} day(s)`}</h3>}
        </div>}
        <div className='row m-2'>
            <div className='col-6'>
                <div className='row'>
                    <div className='col-12'>
                        <TimeSeriesView model={ts} chartTitle='State of Charge' intlCulture={intlCulture} />
                    </div>
                    <div className='col-12'>
                        <ResultStorageChartByMonth items={result.stateOfChargeByMonth} />
                    </div>
                    <div className='col-12'>
                        <ResultStorageChartByHour items={result.stateOfChargeByMonth} />
                    </div>
                </div>
            </div>
            <div className='col-6'>
                <div className='row'>
                    <div className='col-12'>
                        <ResultStorageMetricTable result={result} model={model} intlCulture={intlCulture} dateFormat={dateFormat} />
                    </div>
                    <div className='col-12'>
                        {result.index !== undefined && model.storage.length >= result.index + 1 && < ResultStorageSpecTable spec={model.storage[result.index]?.specs} intlCulture={intlCulture} />}
                    </div>
                </div>
            </div>
        </div>
        <div className='row m-2'>
            <div className='ml-1'>
                <ContextHelp helpId='STORAGETABLE' />
            </div>
            <div className='col-12 overflow-scroll'>
                <ResultStorageTable result={result} intlCulture={intlCulture} showBaseYearAsYearZero={showBaseYearAsYearZero} />
            </div>
        </div>
    </>)
})

export default ResultStorage
