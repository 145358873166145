import { OverlayTrigger, Popover } from 'react-bootstrap'
import { Placement } from 'react-bootstrap/Overlay'
import { v4 } from 'uuid'
import 'components/helper/ContextHelp.scss'

interface OverflowComponentProps {
    title?: string
    placement?: Placement,
    icon: string,
    children: React.ReactNode | React.ReactNode[];
    toolTipTitle: string
}

const OverflowComponent = ({ title, placement, icon, children, toolTipTitle }: OverflowComponentProps) => {
    const popover = (
        <Popover id={v4()}>
            <Popover.Title style={{ height: '32px' }}>
                <div className='d-flex justify-content-between' style={{ padding: 'auto' }}>
                    <div>{title}</div>
                    <div>
                        <button type='button' className='close pillButton' onClick={() => document.body.click()} style={{ margin: '-2px' }}><span aria-hidden='true'>×</span></button>
                    </div>
                </div>
            </Popover.Title>
            <Popover.Content className='mt-2'>
                {children}
            </Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger trigger='click' rootClose placement={placement ?? 'auto'} overlay={popover}>
            <div className='d-flex align-items-center text-primary cursor-pointer' title={toolTipTitle}>
                <img src={icon} />
            </div>
        </OverlayTrigger>
    )
}
export default OverflowComponent
