import {action, makeObservable, observable, runInAction} from 'mobx'
import {TimeSeriesViewerModel} from 'components/project/pages/Results/time-series/TimeSeriesViewerModel'
import api from 'services/api'
import download from 'downloadjs'

export interface ResultDetails {
    // summary
    financial: FinancialSummary
    price: EnergyPriceSummary
    revenue: RevenueSummary
    performance: PerformanceSummary

    summaryChart: ResultSummaryChart

    cashflow: number[]
    cashflowCumulative: number[]
    cashflowTableRevenues: string[]
    cashflowTable: ResultCashFlowTableItem[]
    cashflowTree: ResultCashFlowTreeItem[]

    regulationMarketResult: RegulationMarketResult[]
    storage: StorageResult
    wind: WindResult
    solar: SolarResult
    revenueResult: RevenueResult

    timeSeries: ResultTimeSeries
}


export interface ResultMetric {
    child: boolean
    label: string
    value: string
    valueAverage: string
    unit?: string
}

export interface ResultValueItem {
    first: number
    average: number
}

export interface FinancialSummary {
    realDiscountRate: number
    projectLifeTime: number
    capitalCost: number
    operatingCost: number
    augmentationCost:number
    contractParticipation: string
    marketParticipation: string
    capacityMarketParticipation :string
    revenue: number
    irr: number | null
    npv: number
    lcoe: number
}

export interface EnergyPriceSummaryItem {
    name: string
    purchasePrice: ResultValueItem
    salePrice: ResultValueItem
}

export interface EnergyPriceSummary {
    markets: EnergyPriceSummaryItem[]
    capacityPrice: number | null
}

export interface EnergyMarketPerformanceSummaryItem {
    name: string
    solarEnergy: ResultValueItem
    windEnergy: ResultValueItem
    storageEnergy: ResultValueItem
}

export interface CapacityMarketPerformanceSummaryItem {
    name: string
    storageEnergy: ResultValueItem
}

export interface EnergyRevenueSummaryItem {
    name: string
    solarRevenue: ResultValueItem
    windRevenue: ResultValueItem
    storageRevenue: ResultValueItem
}

export interface RegulationRevenueSummaryItems {
    name: string
    regUpEnergyRevenue: ResultValueItem
    regUpCapacityRevenue: ResultValueItem
    regUpMileageRevenue: ResultValueItem
    regDownEnergyExpense: ResultValueItem
    regDownCapacityRevenue: ResultValueItem
    regDownMileageRevenue: ResultValueItem
}

export interface CapacityRevenueSummaryItems {
    name: string
    energyRevenue: ResultValueItem
    capacityRevenue: ResultValueItem
    capacityBid:ResultValueItem
}

export interface ContractRevenueSummaryItem {
    name: string
    revenue: ResultValueItem
}

export interface RevenueSummary {
    energyMarkets: EnergyRevenueSummaryItem[]
    regulationMarkets: RegulationRevenueSummaryItems[]
    capacityMarkets: CapacityRevenueSummaryItems[]
    contracts: ContractRevenueSummaryItem[]
}

export interface RegulationPerformanceSummaryItems {
    name: string
    regUpEnergyExports: ResultValueItem
    regUpCapacityCommitted: ResultValueItem
    regDownEnergyImports: ResultValueItem
    regDownCapacityCommitted: ResultValueItem
}

export interface PerformanceSummary {
    solarProduction: ResultValueItem
    storageThroughput: ResultValueItem
    solarToStorage: ResultValueItem
    gridToStorage: ResultValueItem
    solarClippedEnergy: ResultValueItem
    curtailedEnergy: ResultValueItem
    otherClippedEnergy: ResultValueItem
    excessElectricity: ResultValueItem | null
    meanStateOfCharge: ResultValueItem | null
    cycleCost: ResultValueItem | null
    hoursFromGrid: ResultValueItem | null
    hoursToGrid: ResultValueItem | null
    hoursIdle: ResultValueItem | null
    utilizationRate: ResultValueItem | null
    energyMarkets: EnergyMarketPerformanceSummaryItem[]
    capacityMarkets: CapacityMarketPerformanceSummaryItem[]
    totalEnergy: ResultValueItem
    windProduction: ResultValueItem
    acLineLosses: ResultValueItem
    transformerLosses: ResultValueItem
    regulationMarketsThroughputRegUp: ResultValueItem
    regulationMarketsThroughputRegDown: ResultValueItem
    regulationMarkets: RegulationPerformanceSummaryItems[]
}


export interface ResultSummaryChartSeries {
    name: string
    color: string
}

export interface ResultSummaryChartDay {
    prices: number[][]
    revenues: number[][]
}

export interface ResultSummaryChart {
    prices: ResultSummaryChartSeries[]
    revenues: ResultSummaryChartSeries[]
    days: ResultSummaryChartDay[]
    average: ResultSummaryChartDay
    highestRevenueDayIndex: number
    lowestRevenueDayIndex: number
}


export interface ResultTimeSeries {
    groups: ResultTimeSeriesGroup[],
    items: ResultTimeSeriesItem[],
}


export interface ResultTimeSeriesGroup {
    name: string,
    mainNames: string[],
    extraNames: string[],
}


export interface ResultTimeSeriesItem {
    id: string,
    color: string,
    name: string,
    units: string,
    data: number[],
}


export interface ResultCashFlowTableItem {
    year: number
    solar: number
    wind: number
    storage: number
    capEx: number
    opEx: number
    augmentation:number
    revenues: number[]
    revenue: number
    income: number
    cumulative: number
}


export interface ResultCashFlowTreeItem {
    name: string
    values: number[]
    children?: ResultCashFlowTreeItem[]
}


export interface MonthMetricResultItem {
    minimum: number
    averageMinimum: number
    average: number
    averageMaximum: number
    maximum: number
    byHour: number[] // (x24)
}

export interface StorageResult {
    status: number
    nameplateCapacity: number // MWh
    nameplatePower?: number // MW
    capitalCost: number // $
    augmentationLimit: number // %
    levelizedCostOfStorage: number
    hasConverter?: boolean
    saleMarketNames?: string[]
    purchaseMarketNames?: string[]
    items: StorageResultItem[]
    stateOfChargeTimeSeries: number[] // %
    stateOfChargeByMonth: MonthMetricResultItem[] // (x12)
    capacityBid: number
    annualEffectiveFullCycles: number
    dailyEffectiveFullCycles: number
    index: number
}

export interface StorageResultItem {
    year: number
    maximum: number // MWh
    minimum: number // MWh
    augmentation: number // MWh
    augmentationFraction: number // *
    augmentationCost: number // $/kWh
    augmentationTotalCost: number // $
    energySold?: number[]
    energyPurchased?: number[]
    stateOfChargePercentile25: number // % of steps > 25% SOC
    stateOfChargePercentile50: number // % of steps > 50% SOC
    stateOfChargePercentile75: number // % of steps > 75% SOC
    equivalentCycles: number // #
    energyInput: number // MWh
    energyOutput: number // MWh
    roundtripEfficiency: number // %
    utilizationRate: number // %
    cycleCost: number // $/MWh
    converterEnergyInput?: number // MWh
    converterEnergyOutput?: number // MWh
    operatingTotalCost: number // $, in cash flow as "operating"
}


export interface SolarResult {
    status: number
    pvs: SolarPvResult[]
}

export interface SolarPvResult {
    nameplateCapacity: number // MW
    derateFactor: number // %
    capitalExpenses: number // $
    items: SolarResultItem[]
    totalProductionByMonth: number[] // MW (x12)
    index: number
}

export interface SolarResultItem {
    year: number // 0 .. lifetime-1
    production: number // MWh
    clippedProduction: number // MWh
    energyYield: number // MWh/MW
    degradation: number // % for this year
    capacityFactor: number // %
    operatingTotalCost: number // $
}


export interface WindResult {
    status: number
    windTurbines: WindTurbineResult[]
}

export interface WindTurbineResult {
    nameplateCapacity: number
    totalLosses: number
    capitalExpenses: number
    items: WindTurbineResultItem[]
    totalProductionByMonth: number[] // MW (x12)
    index: number
}

export interface WindTurbineResultItem {
    year: number
    production: number
    energyYield: number
    capacityFactor: number
    operatingTotalCost: number
}

export interface RegulationMarketResult {
    regUpCapacityRevenueTimeseries: number[]
    regUpEnergyRevenueTimeseries: number[]
    regUpCapacityRevenue: number
    regUpCapacityCommitted: number
    regUpEnergyRevenue: number
    regUpMileageRevenue: number
    regUpEnergyExports: number
    regUpMileage: number
    regDownCapacityRevenueTimeseries: number[]
    regDownEnergyExpenseTimeseries: number[]
    regDownCapacityRevenue: number
    regDownCapacityCommitted: number
    regDownEnergyExpense: number
    regDownMileageRevenue: number
    regDownMileage: number
    regDownEnergyImports: number
}

export interface RevenueResult {
    columns: RevenueResultCol
    items: RevenueResultItem[]
}

export interface RevenueResultCol {
    energyMarkets: string[]
    capacityMarkets: string[]
    contracts: string[]
    regulationMarkets: string[]
}

export interface RevenueResultItem {
    year: number // 0 .. lifetime-1
    operatingCost: number
    augmentationCost:number
    energyMarkets: RevenueResultEnergyMarketItem[]
    capacityMarkets: RevenueResultCapacityMarketItem[]
    regulationMarkets: RevenueResultRegulationMarketItem[]
    contracts: RevenueResultContractItem[]
}

export interface RevenueResultRegulationMarketItem {
    totalRegUpRevenue: number
    totalRegUpRevenueChange: number
    totalRegDownRevenue: number
    totalRegDownRevenueChange: number
    regUpEnergyExported: number
    regDownEnergyImported: number
}

export interface RevenueResultEnergyMarketItem {
    revenue: number
    energy: number
    price: number
    revenueChange: number
    priceChange: number
    solarRevenue: number
    storageRevenue: number
    solarEnergy: number
    storageEnergy: number,
    windRevenue: number,
    windEnergy: number
}

export interface RevenueResultCapacityMarketItem {
    revenue: number
    energy: number
    price: number
    revenueChange: number
    priceChange: number
}

export interface RevenueResultContractItem {
    revenue: number
    revenueChange: number,
    energy:number
}


export const defaultDetails: ResultDetails = {
    financial: {
        projectLifeTime: 0,
        realDiscountRate: 0,
        capitalCost: 0,
        operatingCost: 0,
        contractParticipation: '',
        marketParticipation: '',
        capacityMarketParticipation:'',
        revenue: 0,
        irr: 0,
        npv: 0,
        lcoe: 0,
        augmentationCost:0
    },
    price: {
        markets: [
            {name: 'DAM', salePrice: {first: 1, average: 0.5}, purchasePrice: {first: 2, average: 1.5}},
        ],
        capacityPrice: 0,
    },
    revenue: {
        energyMarkets: [{ name: 'EM', solarRevenue: { first: 10, average: 20.5 }, windRevenue: { first: 3211, average: 4111.5 }, storageRevenue: { first: 32, average: 41.5 } }],
        regulationMarkets: [{ name: 'RM', regUpEnergyRevenue: { first: 10, average: 20.5 }, regUpCapacityRevenue: { first: 32, average: 41.5 }, regUpMileageRevenue: { first: 32, average: 41.5 }, regDownEnergyExpense: { first: 10, average: 20.5 }, regDownCapacityRevenue: { first: 32, average: 41.5 }, regDownMileageRevenue: { first: 32, average: 41.5 } }],
        capacityMarkets: [{name: 'CM', energyRevenue: {first: 10, average: 20.5}, capacityRevenue: {first: 32, average: 41.5},capacityBid:{first:0,average:0}}],
        contracts: [],
    },
    performance: {
        windProduction: { first: 1, average: 0.5 },
        solarProduction: {first: 1, average: 0.5},
        storageThroughput: {first: 1, average: 0.5},
        solarToStorage: {first: 1, average: 0.5},
        gridToStorage: { first: 1, average: 0.5 },
        curtailedEnergy: {first: 1, average: 0.5},
        solarClippedEnergy: {first: 1, average: 0.5},
        otherClippedEnergy: { first: 1, average: 0.5 },
        excessElectricity: { first: 1, average: 0.5 },
        meanStateOfCharge: {first: 1, average: 0.5},
        cycleCost: {first: 1, average: 0.5},
        hoursFromGrid: {first: 1, average: 0.5},
        hoursToGrid: {first: 1, average: 0.5},
        hoursIdle: {first: 1, average: 0.5},
        utilizationRate: {first: 1, average: 0.5},
        energyMarkets: [
            {name: 'DAM', solarEnergy: {first: 1, average: 0.5}, windEnergy: {first: 211, average: 111.5}, storageEnergy: {first: 2, average: 1.5}},
        ],
        capacityMarkets: [
            {name: 'CM', storageEnergy: {first: 2, average: 1.5}},
        ],
        totalEnergy: { first: 1, average: 0.5 },
        acLineLosses: { first: 1, average: 0.5 },
        transformerLosses: { first: 1, average: 0.5 },
        regulationMarketsThroughputRegUp: { first: 1, average: 0.5 },
        regulationMarketsThroughputRegDown: { first: 1, average: 0.5 },
        regulationMarkets: [
            { name: 'Reg Mkt', regDownCapacityCommitted: { first: 1, average: 0.5 }, regDownEnergyImports: { first: 1, average: 0.5 }, regUpCapacityCommitted: { first: 1, average: 0.5 }, regUpEnergyExports: { first: 1, average: 0.5 } }
        ]
    },

    summaryChart: {
        prices: [],
        revenues: [],
        days: [],
        average: {prices: [], revenues: []},
        highestRevenueDayIndex: 0,
        lowestRevenueDayIndex: 0,
    },

    cashflow: [],
    cashflowCumulative: [],
    cashflowTableRevenues: [],
    cashflowTable: [],
    cashflowTree: [],

    regulationMarketResult:[],

    storage: {
        status: 1,
        nameplateCapacity: 0, nameplatePower: 0, capitalCost: 0, augmentationLimit: 0, levelizedCostOfStorage: 0,
        hasConverter: false,
        items: [],
        stateOfChargeTimeSeries: [],
        stateOfChargeByMonth: [],
        capacityBid: 0,
        annualEffectiveFullCycles: 0,
        dailyEffectiveFullCycles: 0,
        index: 0
    },
    wind: {
        status: 1,
        windTurbines: [],
    },
    solar: {
        status: 1,
        pvs: [],
    },
    revenueResult: {
        columns: {
            energyMarkets: [],
            capacityMarkets: [],
            contracts: [],
            regulationMarkets: []
        },
        items: [],
    },

    timeSeries: {
        groups: [],
        items: [],
    },
}


export type ResultDetailsSectionKind
    = 'summary'
    | 'wind'
    | 'solar'
    | 'storage'
    | 'augmentation'
    | 'revenue'
    | 'timeseries'
    | 'cashflow'
    | 'proforma'
    | 'regMarket'
    | 'compareEconomics'


export class ResultDetailsModel {
    constructor(public projectId: string, public resultId: string, public details: ResultDetails) {
        makeObservable(this)
        this.timeSeriesViewerModel = new TimeSeriesViewerModel(this.projectId, this.resultId, details.timeSeries)
    }

    @observable section: ResultDetailsSectionKind = 'summary'

    @observable timeSeriesViewerModel: TimeSeriesViewerModel

    @action selectSection(x: ResultDetailsSectionKind) {
        this.section = x
    }


    // export cash flow data
    @observable loadingCashFlowCsv: boolean = false

    @action
    async exportCashFlowCsv() {
        this.loadingCashFlowCsv = true
        const content = await api.exportCashFlow(this.projectId, this.resultId)
        runInAction(() => {
            this.loadingCashFlowCsv = false
            download(content, 'cashflow.csv', 'text/csv')
        })
    }
}
