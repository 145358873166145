import { OverlayTrigger, Popover, Table, Dropdown, Form } from 'react-bootstrap'
import { v4 } from 'uuid'
import 'components/helper/ContextHelp.scss'
import user from 'assets/images/default-user.svg'
import { observer } from 'mobx-react-lite'
import cookies from 'js-cookie'
import api, { UserData } from 'services/api'
import version from 'version.json'
import { DateFormatOptions } from 'Kendo-Intl-5'
import userReg from 'assets/images/user-regular.svg'
import { navigate } from '@reach/router'
import { allCurrencies, DateFormatLabel } from 'components/project/model'
import { useState } from 'react'

const LogOut = observer(() => {
    const [currency, setCurrency] = useState(api.defaultCurrency);
    const [dateFormat, setDateFormat] = useState(api.defaultDateFormat)
    const [decimalPoint, setDecimalPoint] = useState(api.defaultDecimalPoint)
    const [showBaseYearAsYearZero, setShowBaseYearAsYearZero] = useState(api.showBaseYearAsYearZero)

    const dateFormatArray = Object.values(DateFormatLabel)

    const dateTimeFormat: DateFormatOptions = {
        day: 'numeric', month: 'short', year: 'numeric',
        hour: 'numeric', minute: 'numeric', hour12: true,
    }
    const onLogOut = () => {
        window.localStorage.clear();
        window.sessionStorage.clear();
        Object.keys(cookies.get()).forEach(function (cookieName) {
            cookies.remove(cookieName);
        });
        api.clearToken();
        navigate(`/`);
        window.location.reload();
    }
    const updateDefaultCurrency = (defaultCurrency: string) => {
        api.updateUserDefaultCurrency(defaultCurrency);
        setCurrency(defaultCurrency)
    }
    const updateDateFormat = (defaultDateFormat: number) => {
        api.updateUserDefaultDateFormat(defaultDateFormat);
        setDateFormat(defaultDateFormat)
    }
    const updateDecimalPoint = (defaultDecimalPoint: boolean) => {
        api.updateUserDefaultDecimalPoint(defaultDecimalPoint);
        setDecimalPoint(defaultDecimalPoint)
    }
    const updateShowBaseYearAsYearZero = (showBaseYearAsYearZero: boolean) => {
        api.updateUserShowBaseYearAsYearZero(showBaseYearAsYearZero);
        setShowBaseYearAsYearZero(showBaseYearAsYearZero)
    }
    const popover = (
        <Popover id={v4()}>
            <Popover.Content className='mt-2 mb-2'>
                <div className='d-flex flex-column align-items-center justify-content-start'>
                    <img src={userReg} />
                    <div className='mt-1 mb-0'>
                        <p className='text-center mt-1 mb-0 pt-1 font-size-16'>{api.name}</p>
                    </div>
                    <div className='form-group row align-items-center justify-content-start ml-1 mt-0 mb-2'>
                        <label className='mt-2 mb-0 col-5'>Default Currency</label>
                        <div className='col-1' />
                        <div className='mt-3 mb-0 col-2'>
                            <Dropdown drop='down'>
                                <Dropdown.Toggle variant='outline-primary'>
                                    {currency}
                                </Dropdown.Toggle>
                                <Dropdown.Menu as={Table} min-width='15rem'>
                                    {allCurrencies.slice(0, 7).map(c =>
                                        <Dropdown.Item key={c.Code} onClick={() => updateDefaultCurrency(c.Code)}>{c.CountryAndCurrency + ' - ' + c.Code}</Dropdown.Item>)}
                                    <Dropdown.Divider />
                                    {allCurrencies.slice(-allCurrencies.length + 7).map(c =>
                                        <Dropdown.Item key={c.Code} onClick={() => updateDefaultCurrency(c.Code)}>{c.CountryAndCurrency + ' - ' + c.Code}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className='form-group row align-items-center justify-content-start ml-1'>
                        <label className='col-4'>Date Format</label>
                        {/*<div className='m-auto' />*/}
                        <div className='col-3'>
                            <Dropdown drop='down'>
                                <Dropdown.Toggle variant='outline-primary'>
                                    {dateFormatArray[dateFormat]}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {dateFormatArray.map((c, i) =>
                                        <Dropdown.Item key={i} onClick={() => updateDateFormat(i)}>{c}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className='form-group row align-items-center mb-0'>
                        <div className='form-group row mt-0'>
                            <Form.Check className='vertical-align-center' custom label='Use Comma as Decimal Point' id='useEnergyMarket'
                                checked={decimalPoint}
                                onChange={ev => updateDecimalPoint(ev.target.checked)} />
                        </div>
                    </div>
                    <div className='form-group row align-items-center mb-0'>
                        <div className='form-group row mt-0'>
                            <Form.Check className='vertical-align-center' custom label='Show Base Year as Year Zero' id='showBaseYearAsYearZero'
                                checked={showBaseYearAsYearZero}
                                onChange={ev => updateShowBaseYearAsYearZero(ev.target.checked)} />
                        </div>
                    </div>
                    <div className='mt-0'>
                        {/*<hr className='bg-dark mt-0' />*/}
                        <p className='text-center font-size-14'>Publish Date: {new Date(version[process.env.NODE_ENV].interface).toLocaleString('en-US', dateTimeFormat).replace(/,/g, '')}</p>                    </div>
                    <p><a className='text-center font-size-16' href="https://users.homerenergy.com/account/password/new" target='_blank'>Reset Password</a></p>
                    <div style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <button className='btn btn-sm btn-outline-primary font-size-18 pillButton' onClick={onLogOut}>
                            Sign Out
                        </button>
                    </div>
                </div>
            </Popover.Content>
        </Popover>
    )

    return (
        <OverlayTrigger trigger='click' rootClose placement='auto' overlay={popover}>
            <div className='d-flex align-items-center text-primary cursor-pointer'>
                <img src={userReg} />
            </div>
        </OverlayTrigger>
    )
})
export default LogOut
