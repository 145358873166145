import {useState} from 'react'
import {ColorResult, GithubPicker} from 'react-color'


interface ColorRectProps {
    color: string
    onColorChange?: (x: string) => void
    popOverMarginTop?: string
    timeseriesCount?: number
}

const SWATCH_STYLE = {
    minWidth: '16px',
    minHeight: '16px',
    border: 'solid black 1px',
    display: 'inline-block',
    cursor: 'pointer',
}

export const ColorRect = ({ color, onColorChange, popOverMarginTop, timeseriesCount = 40 }: ColorRectProps) => {
    const [show, setShow] = useState(false)
    const POPOVER_STYLE = {
        marginTop: popOverMarginTop ? popOverMarginTop : '90px',
        marginLeft: '-10px',
    }
    const handleClose = () => {setShow(false)}
    const handleClick = () => {setShow(!show)}
    const handleColorChange = (x: ColorResult) => {
        onColorChange?.(x.hex)
        setShow(false)
    }

    const colors = timeseriesCount <= 16 ? colors16 : timeseriesCount <= 24 ? colors24 : timeseriesCount <= 36 ? colors36 : timeseriesCount <= 48 ? colors48 : timeseriesCount <= 56 ? colors56 : colors72

    return (
        <>
            <div style={{...SWATCH_STYLE, background: color}} className='mr-2 rounded'
                 onClick={handleClick}/>
            {show && <div style={{...POPOVER_STYLE, position: 'absolute', zIndex: 2}}>
                <div style={{position: 'fixed', top: 0, right: 0, bottom: 0, left: 0}}
                     onClick={handleClose}/>
                <GithubPicker color={color} onChangeComplete={handleColorChange} colors={colors}
                              styles={{default: {card: {width: '212px'}}}}/>
            </div>}
        </>
    )
}

const colors16 = [
    "#2e4ffc", "#34ea32", "#ef5144", "#35f3f0", "#39675c", "#d932fc", "#acaf95", "#f58dee",
    "#8c58b1", "#7fa5fe", "#3bba9a", "#929030", "#8c2c47", "#fca342", "#9dffd0", "#98fe31",
]

const colors24 = [
    "#8f32ec", "#2df132", "#f2832c", "#2fdfff", "#386962", "#a89db3", "#e42b81", "#90302b",
    "#fb64e9", "#3883e2", "#65e69a", "#87ac30", "#a0dcf6", "#2b33ca", "#f8bc93", "#722f89",
    "#ac6469", "#f2b1fe", "#2eb15a", "#8a73fe", "#58949a", "#312d2d", "#80ec49", "#f7788a",
]

const colors36 = [
    "#753df3", "#2bf830", "#fc7f2d", "#34ecf7", "#4d7152", "#c7f257", "#f97ff8", "#b42b5c",
    "#8fa3be", "#2e81f5", "#df2cdb", "#a4fac6", "#3c3094", "#2ec287", "#f6bfa6", "#a1a02f",
    "#c86a99", "#72ff73", "#3a3331", "#c0c1fe", "#3eaf32", "#9bc176", "#a374f4", "#fbc13b",
    "#2d76a3", "#3afba9", "#7fe52b", "#f8352d", "#fa2f88", "#80548c", "#8b592d", "#34bad0",
    "#2e4bd8", "#dd9d6d", "#6fbdfe", "#ae34aa",
]

const colors48 = [
    "#6836fe", "#2fda3d", "#f64230", "#2fdcfe", "#575e63", "#f37bf1", "#9ec1a9", "#98a22b",
    "#b83098", "#3398b2", "#de867a", "#2ef19e", "#858afa", "#92f52f", "#93fbf9", "#dd2dff",
    "#2c39a9", "#eac0d6", "#93739e", "#913431", "#439944", "#2e68f0", "#7eff86", "#2e3237",
    "#fb912d", "#f956a7", "#60bc7e", "#a754f5", "#bc6f35", "#bed261", "#79c5f5", "#43a0f3",
    "#71318d", "#fdc377", "#bcf2bb", "#62f9c9", "#6d59cc", "#76752c", "#be95d3", "#7e9068",
    "#cffc43", "#fb3174", "#2e2ce4", "#2f672c", "#aa4d63", "#58c2bd", "#d1b232", "#bbc2fe",
]

const colors56 = [
    "#2bf82e", "#414bfc", "#f0453a", "#36f3ef", "#f26bf9", "#476b49", "#94b2a1", "#98349b",
    "#9afe6a", "#2b82aa", "#ff9289", "#89b330", "#ceb7fb", "#6da0fb", "#8b2b2f", "#2b2ea4",
    "#31d38e", "#a4fcd7", "#ab7762", "#a530fb", "#fa2cad", "#9e71da", "#382c42", "#34b8d0",
    "#f38b2b", "#33a43a", "#698882", "#5e58b6", "#cbac5f", "#62fea8", "#c85c9f", "#75f22b",
    "#70d264", "#fda2d3", "#5d4677", "#2d83f7", "#6fd2d8", "#b3435e", "#f92dfa", "#83632c",
    "#ca8faf", "#4dfb65", "#9ce2a1", "#b5e535", "#2fa176", "#6e8dc1", "#f870b5", "#702bc8",
    "#e26867", "#738e49", "#c34fda", "#33d158", "#b79230", "#e62d72", "#a0d0fb", "#6c70fc",
]

const colors72 = [
    "#5645fc", "#30f738", "#f73b31", "#36fff9", "#4b6957", "#f975fc", "#a0b6a8", "#bc2fa6",
    "#339cd6", "#8aae2f", "#fe917e", "#8f3233", "#56ee99", "#3233a0", "#9581fe", "#2db669",
    "#ab7273", "#ccb9f9", "#302c38", "#75c2fe", "#6970b1", "#da2ffe", "#cf8d2c", "#8a6d2c",
    "#719879", "#ee578b", "#bac75f", "#bd65c8", "#6f2e75", "#a6ef94", "#962ffb", "#f3adbf",
    "#49b9a5", "#fe2cbf", "#2c8691", "#2ea62b", "#70cb63", "#c53a62", "#f7b540", "#7d3cbf",
    "#335ed1", "#2be3c5", "#fc6d48", "#cb8c9c", "#739bcd", "#5b84ff", "#bf5a2d", "#69e22f",
    "#6cebd9", "#ec50dc", "#39c9fe", "#2de96c", "#2d2dd9", "#2d4b74", "#fa2b73", "#5f8d3d",
    "#ca8ae9", "#a3db2d", "#f97ebb", "#2d722d", "#7f5879", "#b89a5d", "#895af2", "#63fd6a",
    "#ffa7ff", "#e0be81", "#604441", "#7fd5a8", "#c4ba2b", "#54bed5", "#c055fc", "#b15695",
]
