import {
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    ChartValueAxis,
    ChartValueAxisItem,
} from '@progress/kendo-react-charts'
import {RouteComponentProps} from '@reach/router'
import {range} from 'utils'

interface chartsSeriesComponentProps extends RouteComponentProps {
    noOfYears: number
    chartData: number[]
    chartDataCumulative: number[]
    showBaseYearAsYearZero: boolean
    baseYear: number
    chartTitle?: string
}

const ProjectCashFlowChart = ({ noOfYears, chartData, chartDataCumulative, showBaseYearAsYearZero, baseYear, chartTitle = 'Project Cash Flow' }: chartsSeriesComponentProps) => {
    const years = range(0, noOfYears).map(i => showBaseYearAsYearZero ? baseYear + i : i )

    return (
        <Chart>
            <ChartTitle text={chartTitle} />
            <ChartLegend position='bottom' orientation='horizontal'/>
            <ChartCategoryAxis>
                <ChartCategoryAxisItem baseUnitStep={2} axisCrossingValue={[0, years.length]} categories={years}/>
            </ChartCategoryAxis>
            <ChartValueAxis>
                <ChartValueAxisItem key='annual' labels={{format: '{0:n}', rotation: 'auto'}} name='annual' title={{text: 'Annual Cash Flow'}}/>
                <ChartValueAxisItem key='cumulative' labels={{format: '{0:n}', rotation: 'auto'}} name='cumulative' title={{text: 'Cumulative Cash Flow'}}/>
            </ChartValueAxis>
            <ChartSeries>
                <ChartSeriesItem key='annual' type='column' data={chartData} name='Annual' color='#67B1E2' axis='annual'/>
                <ChartSeriesItem key='cumulative' type='line' data={chartDataCumulative} name='Cumulative' color='#ffa500' axis='cumulative'/>
            </ChartSeries>
        </Chart>
    )
}
export default ProjectCashFlowChart
