import {Dropdown} from 'react-bootstrap'
import React from 'react'

interface DropDownSelectorProps {
    max: number
    value: number
    onValueChange: (x: number) => void
    hidden?: boolean
    showBaseYearAsYearZero: boolean
    baseYear: number
}

const DropDownSelector = ({ max, value, onValueChange, hidden, showBaseYearAsYearZero, baseYear }: DropDownSelectorProps) => {
    const items = new Array(max).fill(null).map((_, index) => index)

    return (
        <div hidden={max < 2 || hidden}>
            <Dropdown>
                <Dropdown.Toggle className='pillButton' variant='outline-primary' size='sm'>
                    {showBaseYearAsYearZero ? baseYear + value + 1 : value + 1}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {items.map(item =>
                        <Dropdown.Item key={item} onClick={() => onValueChange(item)}>{showBaseYearAsYearZero ? baseYear + item + 1 : item + 1}</Dropdown.Item>)}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default DropDownSelector
