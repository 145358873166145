import {HTMLAttributes, PropsWithChildren, useState} from 'react'
import {Collapse} from 'react-bootstrap'
import './CollapsibleGroup.css'


interface CollapsibleGroupProps extends PropsWithChildren<{}>, HTMLAttributes<{}> {
    title: string
}


const CollapsibleGroup = ({title, children, ...props}: CollapsibleGroupProps) => {
    const [open, setOpen] = useState(true)

    return (
        <div {...props}>
            <div className={'collapsibleFormatting collapsible-group-header border ' + (open ? 'rounded-top' : 'rounded')}
                 onClick={() => setOpen(!open)}>
                <h5 className='text-dark m-2'>{title}</h5>
            </div>
            <Collapse className={'border border-top-0 ' + (open ? 'rounded-bottom' : '')}
                      in={open}>
                <div className='p-2'>{children}</div>
            </Collapse>
        </div>
    )
}


export default CollapsibleGroup
