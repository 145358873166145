import {TimeSeriesViewerModel} from './TimeSeriesViewerModel'
import './TimeSeriesViewer.scss'
import {observer} from 'mobx-react-lite'
import {BiChevronLeft, BiChevronRight, BiZoomIn, BiZoomOut} from 'react-icons/all'
import RangeSelector from 'components/project/pages/Results/time-series/RangeSelector'
import {Range} from 'components/project/pages/Results/time-series/Range'


interface PanZoomControlProps {
    model: TimeSeriesViewerModel
}

const PanZoomControl = observer(({model}: PanZoomControlProps) => {
    const handleRangeChange = (r: Range) => model.setRange(r)
    const handlePanLeft = () => model.panLeft()
    const handlePanRight = () => model.panRight()
    const handleZoomIn = () => model.zoomIn()
    const handleZoomOut = () => model.zoomOut()

    return (
        <div className='d-flex flex-grow-1 align-items-center my-3'>
            <button className='btn btn-sm btn-outline-primary btn-no-focus mr-1 pillButton' onClick={handlePanLeft}><BiChevronLeft/></button>
            <button className='btn btn-sm btn-outline-primary btn-no-focus mr-3 pillButton' onClick={handlePanRight}><BiChevronRight/></button>
            <RangeSelector range={model.range} bounds={model.bounds} onRangeChange={handleRangeChange}/>
            <button className='btn btn-sm btn-outline-primary btn-no-focus ml-3 pillButton' onClick={handleZoomIn}><BiZoomIn/></button>
            <button className='btn btn-sm btn-outline-primary btn-no-focus ml-1 pillButton' onClick={handleZoomOut}><BiZoomOut/></button>
        </div>
    )
})

export default PanZoomControl
