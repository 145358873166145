export type StrValidationFunc = (value: string) => string[]

export class StrValidation {

    static maxLen(max: number): StrValidationFunc {
        return (value: string) => {
            return value && value.length < max ? [] : [`The input must contain ${max} characters or less`]
        }
    }
}
