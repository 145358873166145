import { observer } from 'mobx-react-lite'
import { Wind } from 'components/project/model/Wind'
import { useState } from 'react'
import { IntlService } from 'Kendo-Intl-5'
import { useProjectStore } from 'components/project/ProjectProvider'
import { IoIosArrowForward, IoIosArrowDown } from 'react-icons/all'
import api from '../../../../services/api'


interface LossesTableViewProps {
    model: Wind
    intlCulture: string
}
interface RowProps {
    label: string
    value: string,
    isAccordian?: boolean,
    isMore?: boolean,
    onClick?: () => void
}
const Row = ({ label, value, isAccordian, isMore, onClick }: RowProps) => {
    return (
        <tr className='font-size-14' onClick={onClick}>
            <td> {isAccordian && <span>{!isMore ? <IoIosArrowDown></IoIosArrowDown> : <IoIosArrowForward></IoIosArrowForward>}</span>}{label}</td>
            <td>{value}</td>
        </tr>)
}
const WindLossesView = observer(({ model, intlCulture }: LossesTableViewProps) => {
    const [isShowMore, setIsShowMore] = useState<boolean>(true)
    const intl = new IntlService(intlCulture)

    const project = useProjectStore().project!
    return (<>
        <div className='row'>
            <table className='table table-bordered table-responsive-sm small'>
                <tbody>
                    <Row label='Annual Avarage Wind Speed (m/s)' value={intl.formatNumber(project.windResource.scale.singleValue, 'n')} />
                    <Row label='Altitude above sea level (m)' value={intl.formatNumber(project.wind[0].altitude, 'n')} />
                    <Row label='Anemometer Height (m)' value={`${intl.formatNumber(project.wind[0].hubHeight.singleValue ?? 0, 'n')}`} />
                    <Row label='Overall Loss Factor (%)' value={`${intl.formatNumber(model.totalLosses ?(model.totalLosses/100): 0, 'p')}`} isAccordian={true} onClick={() => { setIsShowMore(isShowMore ? false : true) }} isMore={isShowMore} />
                    {!isShowMore && <>
                        <Row label='Availability Losses (%)' value={`${intl.formatNumber(model.availabilityLosses ?(model.availabilityLosses/100): 0, 'p')}`} />
                        <Row label='Turbine Performance Losses (%)' value={`${intl.formatNumber(model.performanceLosses ?(model.performanceLosses/100): 0, 'p')}`} />
                        <Row label='Environmental Losses (%)' value={`${intl.formatNumber(model.environmentalLosses ?(model.environmentalLosses/100): 0, 'p')}`} />
                        <Row label='Other Losses (%)' value={`${intl.formatNumber(model.otherLosses ?(model.otherLosses/100): 0, 'p')}`} />
                        <Row label='Wake Effects Losses (%)' value={`${intl.formatNumber(model.wakeEffectLosses ?(model.wakeEffectLosses/100): 0, 'p')}`} />
                        <Row label='Electrical Losses (%)' value={`${intl.formatNumber(model.electricalLosses ?(model.electricalLosses/100): 0, 'p')}`} />
                        <Row label='Curtailment Losses (%)' value={`${intl.formatNumber(model.curtailmentLosses ?(model.curtailmentLosses/100): 0, 'p')}`} />
                    </>}
                </tbody>
            </table>

        </div>

    </>)
})

export default WindLossesView
