import StringInput from 'components/helper/StringInput'
import { StrValidationFunc } from 'components/helper/StringValidation'


interface StringFieldProps {
    value: string
    onChange: (x: string) => void
    label: string
    validator?: StrValidationFunc
    disabled?: boolean
    id?: string
    className?: string
}


const StringField = ({ label, value, onChange, id, disabled, className, validator }: StringFieldProps) => {
    return (
        <div className='form-group row mt-3 ml-2 align-items-center'>
            <div className='col-auto'>{label}</div>
            <div className='col-auto'>
                <StringInput value={value}
                             onChange={onChange}
                             validator={validator}
                             disabled={disabled}
                    id={id} className={className} />
            </div>
        </div>
    )
}

export default StringField
