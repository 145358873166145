import {ResultTimeSeries} from 'components/project/results-details'
import {action, computed, makeObservable, observable, runInAction} from 'mobx'
import {v4} from 'uuid'
import {ensureBounds, ensureMinLength, makeRange, Range, zoomIn, zoomOut, panLeft, panRight, round} from 'components/project/pages/Results/time-series/Range'
import api from 'services/api'
import download from 'downloadjs'


const MIN_RANGE = 12

export class TimeSeriesViewerModel {
    constructor(private projectId: string, private resultId: string, private ts: ResultTimeSeries) {
        makeObservable(this)
        this.initData(ts)
        this.initRange()
    }

    initData(ts: ResultTimeSeries, year?: number) {
        this.year = year ?? 0
        var prevSelectedTop: string[] = []
        var prevSelectedBottom: string[] = []
        var getSelection = this.items.length > 0
        if (getSelection) {
            this.items.forEach(x => {
                if (x.selectedTop)
                    prevSelectedTop.push(x.id)
            })
            this.items.forEach(x => {
                if (x.selectedBottom)
                    prevSelectedBottom.push(x.id)
            })
        }
            
        this.items = ts.items.map((x, i) => new TimeSeriesItem({
            id: x.id,
            name: x.name,
            units: x.units,
            color: x.color,
            data: x.data,
            selectedTop: prevSelectedTop.includes(x.id) ? true : false,
            selectedBottom: prevSelectedBottom.includes(x.id) ? true : false
        }))

        this.nameToItem = new Map<string, TimeSeriesItem>(this.items.map(x => [x.name, x]))

        this.groups = ts.groups.map(x => new TimeSeriesGroup({
            name: x.name,
            mainItems: x.mainNames.map(name => this.nameToItem.get(name)!),
            extraItems: x.extraNames.map(name => this.nameToItem.get(name)!),
        }))

        this.size = this.ts.items.length > 0 ? this.ts.items[0].data.length : 1
    }

    initRange() {
        this.range = makeRange(0, this.size)
        this.bounds = makeRange(0, this.size)
    }

    @observable loading: boolean = false

    @observable year: number = 0

    @action
    async setYear(year: number) {
        if (this.year === year) return
        this.year = year
        this.loading = true
        const rv = await api.getTimeSeries(this.projectId, this.resultId, this.year)
        runInAction(() => {
            this.initData(rv.data, year)
            //this.initRange()
            this.loading = false
        })
    }

    @observable items: TimeSeriesItem[] = []
    @observable groups: TimeSeriesGroup[] = []
    @observable nameToItem: Map<string, TimeSeriesItem> = new Map<string, TimeSeriesItem>()

    @observable size: number = 0
    @observable range: Range = makeRange(0, this.size)
    @observable bounds: Range = makeRange(0, this.size)

    @computed get itemsTop(): TimeSeriesItem[] {
        return this.items.filter(x => x.selectedTop)
    }

    @computed get itemsBottom(): TimeSeriesItem[] {
        return this.items.filter(x => x.selectedBottom)
    }

    @computed get axisTop(): string[] {
        const units = this.itemsTop.map(x => x.units)
        const unique = [...new Set(units)]
        return unique
    }

    @computed get axisBottom(): string[] {
        const units = this.itemsBottom.map(x => x.units)
        const unique = [...new Set(units)]
        return unique
    }

    @computed get hasTop(): boolean {
        return this.items.some(x => x.selectedTop)
    }

    @computed get hasBottom(): boolean {
        return this.items.some(x => x.selectedBottom)
    }

    @action selectTop(name: string, selected: boolean) {
        const item = this.nameToItem.get(name)
        if (!item) { return }
        item.selectedTop = selected
    }

    @action selectBottom(name: string, selected: boolean) {
        const item = this.nameToItem.get(name)
        if (!item) { return }
        item.selectedBottom = selected
    }

    @action setColor(name: string, color: string) {
        const item = this.nameToItem.get(name)
        if (!item) { return }
        item.color = color
    }


    @action setRange(r: Range) {
        let rv = round(r)
        rv = ensureMinLength(rv, MIN_RANGE)
        rv = ensureBounds(rv, this.bounds)
        this.range = rv
    }

    @action panLeft() {
        const r = panLeft(this.range)
        this.setRange(r)
    }

    @action panRight() {
        const r = panRight(this.range)
        this.setRange(r)
    }

    @action zoomIn() {
        const r = zoomIn(this.range)
        this.setRange(r)
    }

    @action zoomOut() {
        const r = zoomOut(this.range)
        this.setRange(r)
    }


    // export time series data
    @observable loadingCsv: boolean = false

    @action
    async exportCsv() {
        this.loadingCsv = true
        const content = await api.exportTimeSeries(this.projectId, this.resultId, this.year)
        runInAction(() => {
            this.loadingCsv = false
            download(content, 'timeseries.csv', 'text/csv')
        })
    }

    // export multi year time series data
    @observable loadingMultiYearCsv: boolean = false

    @action
    async exportMultiYearCsv() {
        this.loadingMultiYearCsv = true
        const content = await api.exportMultiYearTimeSeries(this.projectId, this.resultId)
        runInAction(() => {
            this.loadingMultiYearCsv = false
            download(content, 'timeseries.csv', 'text/csv')
        })
    }
}


export class TimeSeriesGroup {
    constructor(x: Partial<TimeSeriesGroup>) {
        Object.assign(this, x)
        this.items = this.mainItems.concat(...this.extraItems)
        makeObservable(this)
    }

    id: string = v4()
    name: string = ''
    mainItems: TimeSeriesItem[] = []
    extraItems: TimeSeriesItem[] = []
    items: TimeSeriesItem[] = []

    @observable showExtra: boolean = false
    @action toggleShowExtra() { this.showExtra = !this.showExtra }
}


export class TimeSeriesItem {
    constructor(x: Partial<TimeSeriesItem>) {
        Object.assign(this, x)
        this.points = this.data.map((x, index) => [index, x])
        makeObservable(this)
    }

    id: string = v4()
    name: string = ''
    units: string = ''
    data: number[] = []
    points: [number, number][] = []

    @observable color: string = '#000000'
    @observable selectedTop: boolean = false
    @observable selectedBottom: boolean = false
}
