import {BiPlusCircle} from 'react-icons/all'
import React from 'react'

interface ButtonAddProps {
    onClick?: () => void
}

const ButtonAdd = ({onClick}: ButtonAddProps) =>
    <button className='btn btn-sm btn-outline-primary border-0 pillButton' onClick={onClick}>
        <BiPlusCircle/>
    </button>

export default ButtonAdd
