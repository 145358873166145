import {action, makeObservable, observable} from 'mobx'
import {v4} from 'uuid'
import {SensitivityVar} from 'components/project/model/SensitivityVar'
import {TimeSeries} from 'components/project/model/TimeSeries'


export type ResourceKind = 'library' | 'timeseries'


export class Resource {
    constructor() { makeObservable(this) }
    id: string = v4()
    @observable kind: ResourceKind = 'library'
    @observable data: TimeSeries = new TimeSeries()
    @observable scale: SensitivityVar = new SensitivityVar()

    @action setKind(x: ResourceKind) { this.kind = x }

    @action setData(data: number[], year: number, daily: boolean = false) {
        this.data.setData(data, year)
        let average = this.data.calculateAverage()
        // daily vs. hourly average
        if (daily) average *= 24
        average = Math.round(average * 100) / 100
        this.scale.setSingleValue(average)
    }

    estimateSimulations(): number {
        switch (this.kind) {
            case 'library': { return 1 }
            case 'timeseries': { return this.scale.estimateSimulations() }
        }
    }
}
