import SchematicIcon from './SchematicIcon'
import windPanel from 'assets/images/wind-panel.svg'
const WindTechnology = () => {
    return (
        <div className='d-flex flex-row align-items-center justify-content-center'>
            <SchematicIcon src={windPanel} />
        </div>
    )
}

export default WindTechnology
