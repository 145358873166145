import { observer } from 'mobx-react-lite'
import { DatePicker, TimePicker } from '@progress/kendo-react-dateinputs'
import NumberInput from 'components/helper/NumberInput'
import TimeSeriesView from 'components/helper/TimeSeriesView'
import React, { useEffect, useState } from 'react'
import NumberField from 'components/helper/NumberField'
import { Validation } from 'components/helper/validation'
import ContextHelp from 'components/helper/ContextHelp'
import ButtonDelete from 'components/helper/ButtonDelete'
import TimeSeriesImport from 'components/helper/TimeSeriesImport'
import { useInternationalization } from 'Kendo-Intl-5'
import { useProjectStore } from 'components/project/ProjectProvider'
import { Button, Col, Dropdown, Form, Modal, ProgressBar, Row, Spinner } from 'react-bootstrap'
import { v4 } from 'uuid'
import { Contract, ContractObligationRequirement, DayKind, ResultState} from 'components/project/model/Contract'
import { TimeSeries } from '../../model/TimeSeries'
import { ProjectStore } from '../../ProjectStore'
import { delay } from 'utils'
import SystemSizerResultTableView from '../Results/SystemSizerResultTableView'
import { EnergyMarket, getEnergyMarketKindLabel } from 'components/project/model/EnergyMarket'
import MultiYearView from 'components/project/pages/SharedComponents/MultiYearView'

interface ContractExportObligationsProps {
    model: Contract
}

const ContractExportObligations = observer(({ model }: ContractExportObligationsProps) => {
    const store = useProjectStore()
    const project = store.project!
    const useCommaAsDecimalPoint = project.useCommaAsDecimalPoint
    const intlCulture = project.useCommaAsDecimalPoint ? 'es' : 'en'
    const baseYear = project.baseYear
    const [showSpinner, setShowSpinner] = useState(false)
    model.setUseSolar(store.project?.useSolar!)
    model.setUseWind(store.project?.useWind!)
    model.setUseStorage(store.project?.useStorage!)

    model.checkInputsChange()

    let solarMaxSize = 0
    let windRateSize = 0
    let windMaxSize = 0
    let storageMaxSize = 0

    const calculating = model.state === 'calculating'
    const currency = project.currencySymbol ?? "$"

    const handleImport = async (data: number[], year: number, fileName: string) => {
        model.setObligationData(data, year, fileName)
        await store.saveProject()
    }

    const handleSystemSizer = async (value: boolean) => {
        model.setUseSystemSizer(value)
    }

    const handleCalculate = async () => {
        let cancel = false
        const run = async () => {
            await store.saveProject()
            await model.calculatesystemsize()
            if (cancel) return
            let it = 0
            const MAX = 1000
            while (it < MAX) {
                if (cancel) return
                await model.checkStatus()
                await delay(5 * 1000)
                if (model.state === 'calculated') {
                    await model.fetchSensitivityTable();
                    break
                }
                else if( model.state === 'cancelled') {
                    break
                }
            }
        }
        run().then()
        return () => { cancel = true }
    }

    const onSelectMarket = () => {
        model.setShortagePenaltyKind('market')
        if (model.energyMarket === null || !project.energyMarkets.includes(model.energyMarket)) {
            model.setEnergyMarket(project.energyMarkets[0])
        }
    }

    const onSelectDelta = () => {
        model.setShortagePenaltyKind('delta')
        if (model.energyMarket === null || !project.energyMarkets.includes(model.energyMarket)) {
            model.setEnergyMarket(project.energyMarkets[0])
        }
    }

    const onSelectFlat = () => {
        model.setShortagePenaltyKind('flat')
        model.setEnergyMarket(null)
    }


    return (
        <div className='border rounded p-2 mb-2'>
            <div className='row'>
                <div className='col-12'>
                    <h5>Contractual Export Obligation</h5>
                </div>

                <div className='col-md-12 col-xl-3 mb-4'>
                    <Form.Check type='radio' id={`obligations-daily-${model.id}`} custom
                        checked={model.obligationKind === 'daily'}
                        onChange={ev => {
                            if (ev.target.checked) {
                                model.setObligationKind('daily');
                            }
                        }}
                        label='Define daily obligations requirements' />
                    <Form.Check type='radio' id={`obligations-import`} custom
                        checked={model.obligationKind === 'import'}
                        onChange={ev => {
                            if (ev.target.checked) {
                                model.setObligationKind('import');
                            }
                        }}
                        label='Import annual delivery profile' />
                    <div className='ml-4 mt-2 mb-2'>
                        <div className='d-flex mb-2'>
                            <TimeSeriesImport onSelect={handleImport} disabled={model.obligationKind !== 'import'} />
                            <div className='pl-1 mt-2'>
                                <ContextHelp helpId='ANNUALDELIVERYPROFILE' />
                            </div>
                        </div>
                    </div>
                    <Form.Check type='radio' id={`tod-obligations-none`} custom
                        checked={model.obligationKind === 'none'}
                        onChange={ev => {
                            if (ev.target.checked) {
                                model.setObligationKind('none');
                                handleSystemSizer(false);
                            }
                        }}
                        label='Percent contracted capacity' />
                    <div className='d-flex mb-4'>
                    </div>
                    {/*<div className='d-flex mb-2'>*/}
                    {/*    <button className='btn btn-primary ml-1 pillButton' id={`runsizer`}*/}
                    {/*        disabled={!(store.project?.useWind || store.project?.useSolar || store.project?.useStorage)}*/}
                    {/*        onClick={handleCalculate}>Run System Sizer</button>*/}
                    {/*    <div className='pl-1 mt-2'>*/}
                    {/*        <ContextHelp helpId='SYSTEMSIZER' />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>            

                <div className='col-md-12 col-xl-9'>
                    <div className='border rounded p-2 mb-2'>
                        {model.obligationKind === 'daily' &&
                            <ObligationsEditor model={model} store={store} useCommaAsDecimalPoint={useCommaAsDecimalPoint} baseYear={baseYear} intlCulture={intlCulture} />}
                        {model.obligationKind === 'import' &&
                            <TimeSeriesView model={model.obligationTimeSeries} yAxisTitle='kW' chartTitle='Delivery Profile' intlCulture={intlCulture} />}
                        {model.obligationKind === 'none' && <div className='w-50 mt-3'>
                            <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label='Contracted Solar Capacity (%)'
                                value={model.contractedSolarCapacity}
                                onChange={x => model.setContractedSolarCapacity(x)}
                                validator={Validation.percent} />
                            <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label='Contracted Storage Capacity (%)'
                                value={model.contractedStorageCapacity}
                                onChange={x => model.setContractedStorageCapacity(x)}
                                validator={Validation.percent} />
                            <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label='Contracted Wind Capacity (%)'
                                value={model.contractedWindCapacity}
                                onChange={x => model.setContractedWindCapacity(x)}
                                validator={Validation.percent} />
                        </div>}

                    </div>
                </div>                
            </div>            

            {<div className='border rounded p-2 mb-2'>
                <div className='d-flex mb-2 '>
                    <Form.Check type='switch' id={`obligation-shortage-penalty`} custom
                        checked={model.useShortagePenalty}
                        onChange={ev => {
                            model.setUseShortagePenalty(ev.target.checked);
                        }}
                        label='Obligation Shortage Penalty' />
                    <div className='pl-2 mt-1'>
                        <ContextHelp helpId='SHORTAGEPENALTY' />
                    </div>
                </div>
                {model.useShortagePenalty && <>
                    <div>
                        <div className='form-group row align-items-center'>
                            <div className='col-2'>
                                <Form.Check id='market'
                                    custom label='Energy Price' type='radio'
                                    disabled={project.energyMarkets.length === 0}
                                    checked={model.shortagePenaltyKind === 'market'}
                                    onChange={ev => ev.target.checked && onSelectMarket()} />
                            </div>
                            <div className='col-2'>
                                <Dropdown>
                                    <Dropdown.Toggle variant='outline-primary' id='dropdown-market' disabled={model.shortagePenaltyKind !== 'market'}>
                                        {model.energyMarket?.name ?? 'Select Energy Market'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {project.energyMarkets.map(x =>
                                            <Dropdown.Item key={x.id} onClick={() => { model.setEnergyMarket(x) }}>
                                                {getEnergyMarketKindLabel(x.kind)}
                                            </Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className='form-group row align-items-center'>
                            <div className='col-2'>
                                <Form.Check id='delta'
                                    custom label='Energy Price deducted by contract price' type='radio'
                                    disabled={project.energyMarkets.length === 0}
                                    checked={model.shortagePenaltyKind === 'delta'}
                                    onChange={ev => ev.target.checked && onSelectDelta()} />
                            </div>
                            <div className='col-2'>
                                <Dropdown>
                                    <Dropdown.Toggle variant='outline-primary' id='dropdown-delta' disabled={model.shortagePenaltyKind !== 'delta'}>
                                        {model.energyMarket?.name ?? 'Select Energy Market'}
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {project.energyMarkets.map(x =>
                                            <Dropdown.Item key={x.id} onClick={() => { model.setEnergyMarket(x) }}>
                                                {getEnergyMarketKindLabel(x.kind)}
                                            </Dropdown.Item>)}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className='form-group row align-items-center'>
                            <div className='col-2'>
                                <Form.Check id='price-flat-shortagePenalty'
                                    type='radio' label={`Flat rate (${currency}/MWh)`} custom
                                    checked={model.shortagePenaltyKind === 'flat'}
                                    onChange={ev => ev.target.checked && onSelectFlat()} />
                            </div>
                            <div className='col-1'>
                                <NumberInput disabled={model.shortagePenaltyKind !== 'flat'}
                                    value={model.flatShortagePenalty}
                                    onChange={x => model.setFlatShortagePenalty(x)} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                            </div>

                            {model.shortagePenaltyKind === 'flat' &&
                                <div className='col-5'>
                                    <MultiYearView model={model.shortagePenaltyPriceEscalator} label='Energy Price Escalator (%/yr)'
                                        id={`shortagePenaltyPriceEscalator-${model.id}`} />
                                </div>}
                        </div>
                    </div>
                </>}
            </div>}

            {(model.resultTableState !== 'ready') && <>
                <div className='border rounded p-2 mb-2'>
                    <LoadingView progress={model.progress} state={model.state} estimatedRemainingTime={model.estimatedRemainingTime} />
                </div>
            </>}

            {(model.resultTableState === 'ready') && <>
                {(model.resultTable.items.length > 0) && <>
                    <div className='border rounded p-2 mb-2' id={`results_border`}>
                        <Row>
                            <div className="col-sm">
                            </div>
                            <div className="col-sm">
                                <div className="table">
                                    <SystemSizerResultTableView items={model.resultTable.items} header={model.resultTable.header} />
                                </div>
                            </div>
                            <div className="col-sm">
                            </div>
                        </Row>
                    </div>
                </>}

                {(model.resultTable.items.length === 0) && (model.state === 'calculated' || model.state === 'error') && <>
                    <div className='border rounded p-2 mb-2'>
                        <div className="col-sm">
                            <div className="table">
                                <h6 className="text-center">System Sizer: No feasible solutions found.</h6>
                            </div>
                        </div>
                    </div>
                </>}
            </>}          
        </div>
    )
})
export default ContractExportObligations

interface ObligationsEditorProps {
    model: Contract
    store: ProjectStore
    useCommaAsDecimalPoint: boolean
    baseYear: number
    intlCulture: string
}


const defaultDate = () => new Date()

const ObligationsEditor = observer(({ model, store, useCommaAsDecimalPoint, baseYear, intlCulture }: ObligationsEditorProps) => {
    const [dayKind, setDayKind] = useState<DayKind>('all')
    const [startDate, setStartDate] = useState(defaultDate())
    const [endDate, setEndDate] = useState(defaultDate())
    const [startHour, setStartHour] = useState(0)
    const [endHour, setEndHour] = useState(1)
    const [value, setValue] = useState(0)
    const [id, setId] = useState(v4())
    const [showModal, setShowModal] = useState(false)
    const [showSpinner, setShowSpinner] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [obligationTimeSeries, setObligationTimeSeries] = useState<TimeSeries>(new TimeSeries())

    useEffect(() => {
        updateTimeseries()
    }, [dayKind, startDate, endDate, startHour, endHour, value, id])

    useEffect(() => {
        if ((document.getElementById("runsizer") as HTMLInputElement) && model.useSystemSizer) {
            if (model.obligations.length == 0 && model.obligationTimeSeries.state === 'blank' && model.energyPriceTimeSeries.state == 'blank') {
                (document.getElementById("runsizer") as HTMLInputElement).disabled = true;
            }
            else
                (document.getElementById("runsizer") as HTMLInputElement).disabled = false;
        }
    },)
    // days
    const period = (endDate.getTime() - startDate.getTime()) / (24 * 60 * 60 * 1000)
    const validKind = (period >= 7) || (dayKind === 'all')
    const minDate = new Date(baseYear, 0, 1)
    const maxDate = new Date(baseYear, 11, 31)
    const updateTimeseries = () => {//create timeseries simulating delivery to show on the graph
        let obligationTimeSeriesData: number[] = Array(365 * 24).fill(0)
        let firstOccurrenceDate = startDate
        firstOccurrenceDate.setHours(0)
        let firstOccurrenceHour = Math.trunc(((firstOccurrenceDate.getTime() - minDate.getTime()) / (1000 * 3600) ) + startHour)
        let firstOccurrenceWeekDay = firstOccurrenceDate.getDay()
        let lastOccurrenceDate = endDate
        lastOccurrenceDate.setHours(0)
        let lastOccurrenceHour = Math.trunc(((lastOccurrenceDate.getTime() - minDate.getTime()) / (1000 * 3600)) + startHour)
        let lastOccurrenceWeekDay = lastOccurrenceDate.getDay()
        if (value > 0) {
            for (let i = firstOccurrenceHour; i <= lastOccurrenceHour; i += 24) {
                obligationTimeSeriesData[i] = value
            }
            if (dayKind == 'weekday') {
                let firstWindowSaturday = new Date(firstOccurrenceDate.getFullYear(), firstOccurrenceDate.getMonth(), (firstOccurrenceDate.getDate() + (6 + 7 - firstOccurrenceDate.getDay()) % 7));
                let firstWindowSaturdayHour = Math.trunc(((firstWindowSaturday.getTime() - minDate.getTime()) / (1000 * 3600)) + startHour)
                let lastWindowSaturday = new Date(lastOccurrenceDate.getFullYear(), lastOccurrenceDate.getMonth(), (lastOccurrenceDate.getDate() - (lastOccurrenceDate.getDay() + 1) % 7));
                if (lastOccurrenceWeekDay == 6) {// if last day is Saturday, prevent iteration from going over the selected window
                    obligationTimeSeriesData[lastOccurrenceHour] = 0
                    lastWindowSaturday.setDate(lastWindowSaturday.getDate() - 7);
                }
                let lastWindowSaturdayHour = Math.trunc(((lastWindowSaturday.getTime() - minDate.getTime()) / (1000 * 3600)) + startHour)
                for (let i = firstWindowSaturdayHour; i <= lastWindowSaturdayHour; i += (24 * 7)) {
                    obligationTimeSeriesData[i] = 0
                    obligationTimeSeriesData[i + 24] = 0
                }
                if (firstOccurrenceWeekDay == 0)//if first day is Sunday, remove it from 
                    obligationTimeSeriesData[firstOccurrenceHour] = 0

            }
            else if (dayKind == 'weekend') {
                let firstWindowMonday = new Date(firstOccurrenceDate.getFullYear(), firstOccurrenceDate.getMonth(), (firstOccurrenceDate.getDate() + (1 + 7 - firstOccurrenceDate.getDay()) % 7));
                let firstWindowMondayHour = Math.trunc(((firstWindowMonday.getTime() - minDate.getTime()) / (1000 * 3600)) + startHour)
                let lastWindowMonday = new Date(lastOccurrenceDate.getFullYear(), lastOccurrenceDate.getMonth(), (lastOccurrenceDate.getDate() - (lastOccurrenceDate.getDay() + 6) % 7));
                if (lastOccurrenceWeekDay > 0 && lastOccurrenceWeekDay < 5) {
                    for (let i = lastOccurrenceHour - ((lastOccurrenceWeekDay - 1) * 24); i <= lastOccurrenceHour; i += 24)
                        obligationTimeSeriesData[i] = 0
                    lastWindowMonday.setDate(lastWindowMonday.getDate() - 7)
                }
                let lastWindowMondayHour = Math.trunc(((lastWindowMonday.getTime() - minDate.getTime()) / (1000 * 3600)) + startHour)
                for (let i = firstWindowMondayHour; i <= lastWindowMondayHour; i += (24 * 7)) {
                    obligationTimeSeriesData[i] = 0
                    obligationTimeSeriesData[i + (1 * 24)] = 0
                    obligationTimeSeriesData[i + (2 * 24)] = 0
                    obligationTimeSeriesData[i + (3 * 24)] = 0
                    obligationTimeSeriesData[i + (4 * 24)] = 0
                }
                if (firstOccurrenceWeekDay > 1 && firstOccurrenceWeekDay < 6) {
                    let firstWeekCorrection = 6 - firstOccurrenceWeekDay
                    for (let i = firstOccurrenceHour; i < firstOccurrenceHour + (firstWeekCorrection * 24); i += 24)
                        obligationTimeSeriesData[i] = 0
                }
            }
            let timeSeries = new TimeSeries()
            timeSeries.setData(obligationTimeSeriesData, baseYear)
            setObligationTimeSeries(timeSeries)
        }
    }

    const handleAdd = () => { setShowModal(true); setIsEdit(false) }    
    const onCancel = () => { setShowModal(false); setIsEdit(false) }
    const handleRemove = async (id: string) => {
        model.removeObligation(id)
        await updateDailyObligationsTimeseries()
    }
    const onEdit = (x: ContractObligationRequirement) => {
        setIsEdit(true)
        setDayKind(x.dayKind);
        setStartDate(new Date(x.startDate));
        setEndDate(new Date(x.endDate));
        setStartHour(x.startHour);
        setEndHour(x.endHour);
        setValue(x.value);
        setShowModal(true);
        setId(x.id)
        setObligationTimeSeries(x.obligationTimeSeries)
    }
    const handleConfirmAdd = async () => {
        setShowSpinner(true)
        model.addNewObligation(isEdit ? { id, dayKind, startDate, endDate, startHour, endHour, value, obligationTimeSeries } : { dayKind, startDate, endDate, startHour, endHour, value, obligationTimeSeries }, isEdit);
        await updateDailyObligationsTimeseries()
        setShowSpinner(false)
        setShowModal(false)
    }
    const updateDailyObligationsTimeseries = async () => {
        let dailyObligationsSum = Array(365 * 24).fill(0)
        model.obligations.map(x => dailyObligationsSum = sumObligationTimeseries(dailyObligationsSum, x.obligationTimeSeries.data))   // = sumObligationTimeseries(obligationTimeseriesList)
        model.dailyObligationsTimeSeries.setData(dailyObligationsSum, baseYear)
        await store.saveProject();
    }
    return (
        <div>
            <div className='d-flex'>
                <h6>Daily Obligations Requirements</h6>
                <button className='btn btn-primary ml-1 pillButton' onClick={handleAdd} disabled={!validKind && model.obligations.length > 0}>Add</button>
            </div>
            <div className='row'>
                <div className='col-5'>

                {model.obligations.map(item => <>
                        <RequirementView key={item.id} model={item}
                        onDelete={() => handleRemove(item.id)} onEdit={onEdit} />
                </>)}
                </div>
                {model.obligations && model.obligations.length > 0 && <div className='col-7'>
                    <TimeSeriesView intlCulture={intlCulture} model={model.dailyObligationsTimeSeries} chartTitle='Obligations Preview' yAxisTitle='MW' />
                </div>}
                
            </div>

            <Modal show={showModal} onHide={onCancel} size="xl" enforceFocus={false}>
                <Modal.Header closeButton>Contractual Export Daily Obligation</Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-8'>
                        <div className='border rounded ml-1 p-2'>
                        <div className='form-group row align-items-center no-gutters'>
                            <Form.Check type='radio' id='day-kind-all' custom className='mr-2'
                                checked={dayKind === 'all'}
                                onChange={ev => ev.target.checked && setDayKind('all')}
                                label='All Week' />
                            <Form.Check type='radio' id='day-kind-weekdays' custom className='mr-2'
                                checked={dayKind === 'weekday'}
                                onChange={ev => ev.target.checked && setDayKind('weekday')}
                                label='Weekdays' />
                            <Form.Check type='radio' id='day-kind-weekends' custom className='mr-2'
                                checked={dayKind === 'weekend'}
                                onChange={ev => ev.target.checked && setDayKind('weekend')}
                                label='Weekends' />

                        </div>

                        <div className='form-group row align-items-center'>
                            <div className='col-2'>Start Date</div>
                            <div className='col-4'>
                                <DatePicker format='dd MMM' min={minDate} max={maxDate}
                                    value={startDate}
                                    onChange={ev => ev.target.value && setStartDate(ev.target.value)} />
                            </div>
                            <div className='col-2'>End Date</div>
                            <div className='col-4'>
                                <DatePicker format='dd MMM' min={minDate} max={maxDate}
                                    value={endDate}
                                    onChange={ev => ev.target.value && setEndDate(ev.target.value)} />
                            </div>
                        </div>

                        <div className='form-group row align-items-center'>
                            <div className='col-2'>Start Hour</div>
                            <div className='col-4'>
                                <HourPicker value={startHour}
                                    onValueChange={setStartHour} />
                            </div>
                            <div className='col-2'>End Hour</div>
                            <div className='col-4'>
                                <HourPicker value={endHour}
                                    onValueChange={setEndHour} />
                            </div>
                        </div>

                        <div className='form-group row align-items-center'>
                            <div className='col-2'>Energy export obligation (MWh/window)</div>
                            <div className='col-4'>
                                <NumberInput useCommaAsDecimalPoint={useCommaAsDecimalPoint} value={value} onChange={setValue} />
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className='col-4'>
                        <TimeSeriesView intlCulture={intlCulture} model={obligationTimeSeries} chartTitle='Obligation Preview' yAxisTitle='MW' />
                    </div>
                    </div>                    
                    
                </Modal.Body>
                <Modal.Footer>
                    <Spinner hidden={!showSpinner} animation='border' size='sm' className='text-primary' />
                    <Button className='pillButton' onClick={onCancel} variant='outline-primary'>Cancel</Button>
                    <Button className='pillButton' onClick={() => {handleConfirmAdd()}}
                    >Save</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
})


interface RequirementViewProps {
    model: ContractObligationRequirement
    onDelete?: () => void
    onEdit?: (model: ContractObligationRequirement) => void
}


const getDayKindLabel = (x: DayKind) => {
    switch (x) {
        case 'all': { return 'All' }
        case 'weekday': { return 'Weekday' }
        case 'weekend': { return 'Weekend' }
    }
}

const RequirementView = observer(({ model, onDelete, onEdit }: RequirementViewProps) => {
    const intl = useInternationalization()
    const timeFormatter = new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'GMT' })
    const { startDate, startHour, endDate, endHour, dayKind, value, obligationTimeSeries } = model
    const start = new Date(startDate)
    const end = new Date(endDate)
    const label = intl.format('{0:m} – {1:m}, {2}, {3:HH:mm} – {4:HH:mm}: {5:n2} MWh',
        start, end, getDayKindLabel(dayKind),
        timeFormatter.format(startHour * 60 * 60 * 1000), timeFormatter.format(endHour * 60 * 60 * 1000), value)

    return (
        <div className='d-flex flex-row align-items-center mt-1 p-1'>
            <ButtonDelete onClick={onDelete} />
            <div>{label}</div>
            <Button className='ml-1 pillButton' onClick={() => onEdit?.(model)} variant='outline-primary'>Edit</Button>
        </div>
    )
})

// utility view to pick hour in a day
interface HourPickerProps {
    value: number
    onValueChange?: (x: number) => void
}

const convertHourToDate = (hour: number) => new Date(2007, 0, 1, Math.trunc(hour))
const convertDateToHour = (date: Date) => date.getHours() + date.getMinutes() / 60
const hourPickerSteps = { minute: 60 }

const HourPicker = ({ value, onValueChange }: HourPickerProps) => {
    const handleChange = (x: Date | null) => {
        if (!x) { return }
        setDate(x)
        onValueChange?.(convertDateToHour(x))
    }

    const [date, setDate] = useState(convertHourToDate(value))

    return (<TimePicker value={date} onChange={ev => handleChange(ev.value)} steps={hourPickerSteps} />)
}

const sumObligationTimeseries = (array1: number[], array2: number[]) => array1.map(function (num, idx) {
    return num + array2[idx];
});

interface LoadingViewProps {
    progress: number
    state: ResultState
    estimatedRemainingTime: string
}

const LoadingView = ({ progress, state }: LoadingViewProps) => {
    const [show, setShow] = useState(false)

    const onCancel = () => {
        setShow(false)
    }

    return (
        <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
            {state !== 'ready' &&
                <React.Fragment>
                    <Spinner animation='border' className='text-primary' />
                    {progress >= 0 && <p className='mt-3'>Running System Sizer, please wait</p>}
                </React.Fragment>
            }
        </div>
    )
}
