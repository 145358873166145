import { RouteComponentProps } from "@reach/router"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { UserStorage } from "./UserStorage"
import UserStorageLibraryComponentEditView from "./UserStorageLibraryComponentEditView"
import UserStorageProvider, { useUserLibraryStore } from "./UserStorageProvider"

interface UserLibraryViewProps extends RouteComponentProps {
    id?: string
}

const UserStorageLibraryComponentView = observer(({ id }: UserLibraryViewProps) => {
    var storageId = id
    var store = useUserLibraryStore()
    var ready = store.specs !== null

    useEffect(() => {
        store.getUserStorage(storageId!).then()
    }, [store])

    return (<>
        {!ready ?
            <h1>Loading...</h1> :
            <UserStorageLibraryComponentEditView id={id} />
        }
    </>
    )
})

export default UserStorageLibraryComponentView
