import {OverlayTrigger, Popover} from 'react-bootstrap'
import {Placement} from 'react-bootstrap/Overlay'
import ReactMarkdown from 'react-markdown'
import {BsQuestionCircleFill} from 'react-icons/all'
import content from '../../context-help.yaml'
import './ContextHelp.scss'

interface ContextHelpProps {
    helpId: string
    placement?: Placement
}

const ContextHelp = ({helpId, placement}: ContextHelpProps) => {
    const markdownContent = content[helpId]?.content ?? ''
    const markdownFooter = content[helpId]?.footer ?? ''

    const popover = (
        <Popover id={helpId}>
            <Popover.Title style={{height: '32px'}}>
                <div className='d-flex justify-content-between' style={{padding: 'auto'}}>
                    <div>Help</div>
                    <div>
                        <button type='button' className='close pillButton' onClick={() => document.body.click()} style={{margin: '-2px'}}><span aria-hidden='true'>×</span></button>
                    </div>
                </div>
            </Popover.Title>
            <Popover.Content className='mt-2'>
                <ReactMarkdown>{markdownContent}</ReactMarkdown>
            </Popover.Content>
            <footer className='popover-footer'>
                <ReactMarkdown linkTarget='_blank'>{markdownFooter}</ReactMarkdown>
            </footer>
        </Popover>
    )

    return (
        <OverlayTrigger trigger='click' rootClose placement={placement ?? 'auto'} overlay={popover}>
            <div className='d-flex align-items-center text-primary cursor-pointer' title='Click to see help tip'>
                <BsQuestionCircleFill fill='black' />
            </div>
        </OverlayTrigger>
    )
}

export default ContextHelp
