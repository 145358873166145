import {action, computed, makeObservable, observable} from 'mobx'
import {eq} from 'utils'
import {v4} from 'uuid'
import { SizingKind } from '../../../services/inputs'

export class SizeItem {
    id: string = v4()
    constructor(init: Partial<SizeItem> = {}) {
        Object.assign(this, init)
        makeObservable(this)
    }

    @observable value: number = 0

    @action setValue(x: number) { this.value = x }
}


export class Sizing {
    constructor() { makeObservable(this) }

    @observable kind: SizingKind = SizingKind.absolute

    @observable absoluteValues: SizeItem[] = observable([new SizeItem()])
    @observable relativeValues: SizeItem[] = observable([new SizeItem()])

    @computed get values(): SizeItem[] {
        switch (this.kind) {
            case SizingKind.absolute: { return this.absoluteValues }
            case SizingKind.relative: { return this.relativeValues }
            default: { return [] } // unreachable
        }
    }

    @action setKind(x: SizingKind) { this.kind = x }

    @action addNewValue() {
        this.values.push(new SizeItem())
    }
    @action setFirstValue(x: number) {
        if (this.values.length === 0)
            this.addNewValue()
        this.values[0].value = x
    }
    @action removeValue(id: string) {
        const i = this.values.findIndex(x => x.id === id)
        this.values.splice(i, 1)
    }

    estimateSimulations(): number { return this.values.length }

    get onlyZero(): boolean {
        const xs = this.values
        return this.kind === SizingKind.absolute && xs.every(x => eq(x.value, 0))
    }
}
