import {createContext, PropsWithChildren, useContext} from 'react'
import {ProjectStore} from './ProjectStore'


const ProjectContext = createContext<ProjectStore>({} as ProjectStore)

interface ProjectProviderProps extends PropsWithChildren<{}> {
    value: ProjectStore
}

const ProjectProvider = ({children, value}: ProjectProviderProps) => {
    return (
        <ProjectContext.Provider value={value}>
            {children}
        </ProjectContext.Provider>
    )
}

export const useProjectStore = () => useContext(ProjectContext)

export default ProjectProvider
