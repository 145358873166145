import {
    Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend, ChartSeries, ChartSeriesItem, ChartTitle, ChartTooltip, ChartValueAxis, ChartValueAxisItem, ChartXAxis, ChartXAxisItem, ChartYAxis, ChartYAxisItem
} from '@progress/kendo-react-charts'
import "hammerjs";

// import ChartValueAxisTitle from '@progress/kendo-react-charts/dist/es/components/value-axis-item/Title'
import { observer } from 'mobx-react-lite'
import { useState } from 'react';

import { StorageCurveItem } from '../../services/api'

interface CapacityChartProps {
    model: StorageCurveItem[]
}

export const CapacityChart = observer(({ model }: CapacityChartProps) => {

    const capacityItems = model
    /*const revenues = data.revenues.map((x, index) => getSeries(chart.revenues, index, x))*/

    return (<ChartView data={model} xAxisTitle="Discharge Power (W)" yAxisTitle="Capacity (Wh)" />)
})

export const TemperatureCapacityChart = observer(({ model }: CapacityChartProps) => {

    const capacityItems = model
    /*const revenues = data.revenues.map((x, index) => getSeries(chart.revenues, index, x))*/

    return (<ChartView data={model} xAxisTitle="Capacity (% of Nominal)" yAxisTitle="Temperature (C)" />)
})

export const CycleLifetimeChart = observer(({ model }: CapacityChartProps) => {

    const capacityItems = model
    /*const revenues = data.revenues.map((x, index) => getSeries(chart.revenues, index, x))*/

    return (<ChartView data={model} xAxisTitle="Depth of Discharge (%)" yAxisTitle="Cycles to Failure" />)
})

export const TemperatureLifetimeChart = observer(({ model }: CapacityChartProps) => {

    const capacityItems = model
    /*const revenues = data.revenues.map((x, index) => getSeries(chart.revenues, index, x))*/

    return (<ChartView data={model} xAxisTitle="Lifetime (years)" yAxisTitle="Temperature (C)" />)
})

interface ChartSeriesInfo {
    name: string
    color: string
    data: number[]
}

interface ChartViewProps {
    data: StorageCurveItem[]
    xAxisTitle?: string
    yAxisTitle?: string
}

const ChartView = observer(({ data, xAxisTitle = "", yAxisTitle = "" }: ChartViewProps) => {
    let dataX: string[] = []
    let dataY: number[] = []
    dataX = data.map(x => x.x.toString())
    dataY = data.map(x => x.y)
    //todo remove test data
    if (dataX.length == 0) {
        dataX.push('0')
        dataY.push(0)
        dataX.push('1')
        dataY.push(1)
        dataX.push('2')
        dataY.push(2)
        data.push({ x: 0, y: 0 })
        data.push({ x: 1, y: 1 })
        data.push({ x: 2, y: 2 })
    }
    return (
        <div>
            <div className='d-flex flex-row'>
                <Chart renderAs='svg' transitions={false} style={{ width: 650, height: 400 }}>
                    <ChartSeries>
                        <ChartSeriesItem
                            type="scatter"
                            data={data}
                            xField="x"
                            yField="y"
                        />
                    </ChartSeries>
                    <ChartXAxis>
                        <ChartXAxisItem title={{ text: xAxisTitle }} />
                    </ChartXAxis>
                    <ChartYAxis>
                        <ChartYAxisItem title={{ text: yAxisTitle }} />
                    </ChartYAxis>
                </Chart>
            </div>
        </div>

    )
})
