import {action, makeObservable, observable} from 'mobx'
import {v4} from 'uuid'
import { MultiYearKind } from '../../../services/inputs'


export class MultiYearVar {
    constructor() { makeObservable(this) }

    @observable enabled: boolean = false
    @observable multiYearKind: MultiYearKind = MultiYearKind.linear
    @observable linearValue: number = 1.0
    @observable customValues: MultiYearItem[] = observable([])

    @action setEnabled(x: boolean) { this.enabled = x }
    @action setType(x: MultiYearKind, lifetime: number) {
        this.multiYearKind = x
        this.updateLifetime(lifetime)
    }
    @action setLinearValue(x: number) { this.linearValue = x }

    @action updateLifetime(x: number) {
        if (x === this.customValues.length) return

        // remove items
        if (x < this.customValues.length) {
            this.customValues.splice(x)
        }

        // add items (fill using 1 or last value)
        if (x > this.customValues.length) {
            const k = this.customValues.length
            const value = k === 0 ? 1.0 : this.customValues[k - 1].value
            for (let year = k; year < x; year++) {
                const item = new MultiYearItem({year, value})
                this.customValues.push(item)
            }
        }
    }
}


export class MultiYearItem {
    constructor(init: Partial<MultiYearItem> = {}) {
        Object.assign(this, init)
        makeObservable(this)
    }

    id: string = v4()

    @observable year: number = 0
    @observable value: number = 1.0

    @action setYear(x: number) { this.year = x }
    @action setValue(x: number) { this.value = x }
}
