import { RouteComponentProps } from '@reach/router'
import ResultTableView from './ResultTableView'
import Col from 'react-bootstrap/Col'
import React, { useCallback, useState } from 'react'
import { Button, Form, Modal, Spinner } from 'react-bootstrap'
import Row from 'react-bootstrap/Row'
import { useProjectStore } from 'components/project/ProjectProvider'
import { observer } from 'mobx-react-lite'
import { OptimizationTableKind } from 'components/project/results'
import ContextHelp from 'components/helper/ContextHelp'


interface OptimizationResultsProps extends RouteComponentProps {
    calculate: () => void
}


const OptimizationResults = observer(({calculate}: OptimizationResultsProps) => {
    const dbProjectStatus = useProjectStore().dbProjectStatus

    const results = useProjectStore().project!.results
    const handleSelect = useCallback((index: number) => {
        results.selectOptimization(index).then()
    }, [results])

    const handleSelectKind = useCallback((kind: string) => {
        results.selectOptimizationKind(kind as OptimizationTableKind).then()
    }, [results])

    const loading = results.optimizationTableState === 'loading'
    const kind = results.selection.kind === 'sensitivity' || results.selection.kind === 'optimization' ?
        results.selection.tableKind : 'all'
    return loading ? (
        <div className='d-flex flex-column h-100 align-items-center justify-content-center'>
            <Spinner animation='border' className='text-primary' />
        </div>
    ) : (
        <>
            <div className='ml-4'>
                <Row className='ml-0'>
                    <div className='form-group d-flex mt-2 mb-2 ml-2'>
                        <div className='mr-3'>
                            <Form.Check name='optimization_result_table_option' custom label='Group By System Architecture'
                                id='group_sys_arch' type='radio'
                                checked={kind === 'group'}
                                onChange={ev => ev.target.checked && handleSelectKind('group')} />
                        </div>
                        <div className='mr-3'>
                            <Form.Check name='optimization_result_table_option' custom label='Show All'
                                id='show_all' type='radio'
                                checked={kind === 'all'}
                                onChange={ev => ev.target.checked && handleSelectKind('all')} />
                        </div>
                        </div>
                        <div className='mr-2' />
                        <ContextHelp helpId='SENSITIVITY' />
                    <div>
                    </div>
                </Row>
                <Row>
                    {dbProjectStatus !== 1 && < div className='mt-2 ml-4' style={{ fontSize: '12px' }}>Double click on a row or select row and click "Get Detailed Results" button to see simulations details</div>}
                </Row>
                <Row>
                    <ResultTableView
                            onSelect={handleSelect} calculate={calculate}
                        intlCulture='en-US' resultType='optimization' />
                </Row>
            </div>
        </>
    )
})

export default OptimizationResults
