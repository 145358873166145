import {v4} from 'uuid'
import {action, computed, makeObservable, observable} from 'mobx'
import {WindTurbineSpec} from 'services/api'
import {Sizing} from 'components/project/model/Sizing'
import {CostTable} from 'components/project/model/CostTable'
import {SensitivityVar} from 'components/project/model/SensitivityVar'
import { TimeSeries } from 'components/project/model/TimeSeries'
import { MultiYearVar } from './MultiYearVar'


const DEFAULT_WIND: string = '1 MW Turbine'

export type WindTurbineKind = 'library' | 'custom' | 'production'


export class Wind {
    id: string = v4()
    constructor() {
        makeObservable(this)
    }

    @observable kind: WindTurbineKind = 'library'
    @observable specs?: WindTurbineSpec

    // library
    @observable model: string = DEFAULT_WIND

    // custom
    @observable customName: string = ''
    @observable customData: string = ''

    @observable sizing: Sizing = new Sizing()
    @observable customSizing: Sizing = new Sizing(); //kW
    @observable cost: CostTable = new CostTable()
    @observable omEscalator: MultiYearVar = new MultiYearVar()
    @observable hubHeight: SensitivityVar = new SensitivityVar()
    @observable lifetime: SensitivityVar = new SensitivityVar(20)
    @observable data: TimeSeries = new TimeSeries()
    @observable dataName: string = 'Imported Wind Production'

    @action setSpecs(x: WindTurbineSpec) { this.specs = x }
    @action setModel(x: string) { this.model = x }
    @action setKind(x: WindTurbineKind) { this.kind = x }

    @action setCustomName(x: string) { this.customName = x }
    @action setCustomData(x: string) { this.customData = x }
    @action setDataName(x: string) { this.dataName = x }

    @observable availabilityLosses: number = 0 // %
    @observable performanceLosses: number = 0 // %
    @observable environmentalLosses: number = 0 // %
    @observable wakeEffectLosses: number = 0 // %
    @observable electricalLosses: number = 0 // %
    @observable curtailmentLosses: number = 0 // %
    @observable otherLosses: number = 0 // %

    @action setAvailabilityLosses(x: number) { this.availabilityLosses = x }
    @action setPerformanceLosses(x: number) { this.performanceLosses = x }
    @action setEnvironmentalLosses(x: number) { this.environmentalLosses = x }
    @action setWakeEffectLosses(x: number) { this.wakeEffectLosses = x }
    @action setElectricalLosses(x: number) { this.electricalLosses = x }
    @action setCurtailmentLosses(x: number) { this.curtailmentLosses = x }
    @action setOtherLosses(x: number) { this.otherLosses = x }

    @computed get totalLosses(): number {
        const factors = [
            this.availabilityLosses,
            this.performanceLosses,
            this.environmentalLosses,
            this.wakeEffectLosses,
            this.electricalLosses,
            this.curtailmentLosses,
            this.otherLosses,
        ]
        // combine loss factors multiplicatively (% -> *)
        const value = factors.reduce((acc, f) => (1.0 - f / 100.0) * acc, 1)
        return (1.0 - value) * 100.0
    }

    // wind resource details
    @observable altitude: number = 0 // m
    @observable anemometerHeight: number = 10 // m

    @action setAltitude(x: number) { this.altitude = x }
    @action setAnemometerHeight(x: number) { this.anemometerHeight = x }
    @action setCost(x: any) {
        this.cost.simple.items[0].capital = x[0].capitalCostDetail[0].unitCost;
        this.cost.simple.items[0].operating = x[0].operating;

    }
    @action setLifeTime(x: number) {
        this.lifetime.values[0].value = x;
    }

    @action setHubHeight(x: number) {
        this.hubHeight.values[0].value = x
    }
    @computed get isZero(): boolean { return this.sizing.onlyZero }

    estimateSimulations(): number {
        const sens =
            this.hubHeight.estimateSimulations() *
            this.lifetime.estimateSimulations()
        const size = this.sizing.estimateSimulations()
        const cost = this.cost.estimateSimulations()
        return sens * size * cost
    }
}

//TODO add defaults similar to what we have for Storage and PV
//function defaultStorageComplexCostTable(): ComplexCostTable {
//    const rv = new ComplexCostTable()
//    rv.directCapital.items.splice(0, 0, ...[
//        new ComplexCostItem({ name: 'Module', unit: 'CostPerKwh' }),
//        new ComplexCostItem({ name: 'Power Conversion System', unit: 'CostPerKw' }),
//        new ComplexCostItem({ name: 'Balance of System', unit: 'CostPerKw' }),
//        new ComplexCostItem({ name: 'Installation Labor', unit: 'CostPerKw' }),
//        new ComplexCostItem({ name: 'Installer Overhead and Margin', unit: 'CostPerKw' }),
//        new ComplexCostItem({ name: 'Contingency', unit: 'PercentOfDirectCapital' }),
//    ])
//    rv.indirectCapital.items.splice(0, 0, ...[
//        new ComplexCostItem({ name: 'Permitting and Environmental', unit: 'CostPerKw' }),
//        new ComplexCostItem({ name: 'Engineering and Developer Overhead', unit: 'CostPerKw' }),
//        new ComplexCostItem({ name: 'Land Purchase, Preparation and Transmissions', unit: 'CostPerKw' }),
//        new ComplexCostItem({ name: 'Working Capital Reserve Account', unit: 'Cost' }),
//        new ComplexCostItem({ name: 'Sales Tax Rate', unit: 'PercentOfDirectCapital' }),
//    ])
//    rv.operating.items.splice(0, 0, ...[
//        new ComplexCostItem({ name: 'Fixed Annual Cost', unit: 'CostPerYear' }),
//        new ComplexCostItem({ name: 'Fixed Cost by Capacity', unit: 'CostPerKwhYear' }),
//        new ComplexCostItem({ name: 'Variable Cost', unit: 'CostPerMwhThroughput' }),
//        new ComplexCostItem({ name: 'Insurance', unit: 'PercentOfDirectCapitalPerYear' }),
//        new ComplexCostItem({ name: 'Audit & Program Management Cost', unit: 'CostPerYear' }),
//        new ComplexCostItem({ name: 'Site Lease Cost', unit: 'CostPerYear' }),
//        new ComplexCostItem({ name: 'Property Tax Rate', unit: 'PercentOfDirectCapitalPerYear' }),
//        new ComplexCostItem({ name: 'Station Power Cost', unit: 'CostPerYear' }),
//        new ComplexCostItem({ name: 'Interest on All Reserves', unit: 'CostPerYear' }),
//        new ComplexCostItem({ name: 'Storage Decommissioning Reserve', unit: 'CostPerKwhYear' }),
//    ])
//    rv.replacement.items.splice(0, 0, ...[
//        new ComplexCostItem({ name: 'Replacement Cost', unit: 'CostPerKwh' }),
//    ])
//    return rv
//}
