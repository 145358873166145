import React, { useState } from 'react'
import ResourceView from 'components/project/pages/equipment/ResourceView'
import {observer} from 'mobx-react-lite'
import {useProjectStore} from 'components/project/ProjectProvider'
import SolarView from 'components/project/pages/equipment/SolarView'
import { MultiImportState } from 'components/helper/MultiTimeSeriesImport'
import { ComponentKind } from '../../../../services/inputs'
import { DeleteComponentState } from './shared/DeleteConfirmationModal'

interface solarGroupViewProps {
    onDelete: (componentToDelete: DeleteComponentState) => void
}

const SolarGroupView = observer(({ onDelete }: solarGroupViewProps) => {
    const store = useProjectStore()
    const project = store.project!

    const handleAdd = () => project.addNewSolar()

    const hasHomerPv = project.solar.some(x => x.kind === ComponentKind.homer)
    const [state, setState] = useState<MultiImportState>({ kind: 'blank' })
    const [fileData, setFileData] = useState<any[]>([])
    const handleDeleteFile = (id: number) => {
        var newFileData = fileData
        newFileData.splice(id, 1)
        setFileData(newFileData)
    }
    const handleSelect = async (sourceList: string[], customName: string[], customSize: number[], data: number[][], year: number, index: number) => {
        project.solar[index].customData.setData(data[0], year)
        project.solar[index].setCustomName(customName[0])
        project.solar[index].setCustomSize(customSize[0] / 1000)
        project.solar[index].customSizeArray.setFirstValue(customSize[0] / 1000)

        if (data.length > 1) {
            var pvCount = project.solar.length
            for (let i = 1; i < data.length; i++) {
                await project.addNewSolar()
                var pvIndex = i + pvCount - 1
                project.solar[pvIndex].setKind(ComponentKind.custom)
                project.solar[pvIndex].customData.setData(data[i], year)
                project.solar[pvIndex].setCustomName(customName[i])
                project.solar[pvIndex].setCustomSize(customSize[i] / 1000)
            }
        }

        await store.saveProject()
    }
    return (
        <>
            {project.solar.map((x, index) => <React.Fragment key={x.id}>
                <div className='my-4'>
                    <SolarView model={x} index={index} state={state} setState={setState} fileData={fileData} setFileData={setFileData} onDelete={() => onDelete({ kind: 'PV', id:x.id })} handleSelect={handleSelect} handleDeleteFile={handleDeleteFile} />
                </div>
                <hr className='bg-dark'/>
            </React.Fragment>)}

            <div className='d-flex flex-column align-items-center my-4'>
                <button className='btn btn-outline-primary pillButton' onClick={handleAdd}>Add another solar technology</button>
            </div>

            {hasHomerPv && <>
                <hr className='bg-dark'/>
                <div className='my-4'>
                    <ResourceView/>
                </div>
            </>}
        </>
    )
})


export default SolarGroupView
