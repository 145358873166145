import {observer} from 'mobx-react-lite'
import Collapse from 'react-bootstrap/Collapse'
import React, {useState} from 'react'
import {BiCaretDown, BiCaretRight, BiPlusCircle} from 'react-icons/all'
import FormControl from 'react-bootstrap/FormControl'
import {CostTable, ComplexCostItem, CostUnit, ComplexCostItems} from 'components/project/model/CostTable'
import NumberInput from 'components/helper/NumberInput'
import {Dropdown} from 'react-bootstrap'
import ButtonDelete from 'components/helper/ButtonDelete'


interface ComplexCostItemProps {
    model: CostTable
    unitsSet: UnitOptionSet
    showReplacement: boolean
    useCommaAsDecimalPoint: boolean
}


const DetailCostBreakup = observer(({ model, unitsSet, showReplacement, useCommaAsDecimalPoint }: ComplexCostItemProps) => {
    const table = model.complex

    return (
        <>
            <ComplexCostTableGroup label='Direct Capital'
                model={table.directCapital} units={unitsSet.capital} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            <ComplexCostTableGroup label='Indirect Capital'
                model={table.indirectCapital} units={unitsSet.capital} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            <ComplexCostTableGroup label='Annual Operations and Maintenance Costs (Operating Expenses)'
                model={table.operating} units={unitsSet.operating} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            {showReplacement &&
                <ComplexCostTableGroup label='Major Replacement Costs'
                model={table.replacement} units={unitsSet.replacement} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />}
        </>
    )
})

export default DetailCostBreakup


interface ComplexCostTableGroupProps {
    label: string
    model: ComplexCostItems
    units: UnitOption[]
    useCommaAsDecimalPoint: boolean
}


const ComplexCostTableGroup = observer(({ label, model, units, useCommaAsDecimalPoint }: ComplexCostTableGroupProps) => {
    const [open, setOpen] = useState(false)
    const handleAdd = () => {
        model.addNewItem()
    }
    const handleDelete = (id: string) => model.removeItem(id)

    return (
        <div className='mb-2'>
            <div id='clickIsHere' className={'bg-light collapsible-group-header border border-light ' + (open ? 'rounded-top' : 'rounded')}
                onClick={() => setOpen(!open)}>
                <div id='addCLickIsHere' className='d-flex flex-row align-items-center'>
                    {open ? <BiCaretDown className='mx-1'/> : <BiCaretRight className='mx-1'/>}
                    <div className='my-2'>{label}</div>
                    <div className='mx-auto' />
                    {open && <div className='mx-1'><button className='btn btn-sm btn-outline-primary border-0 pillButton' onClick={ev => { ev.stopPropagation(); handleAdd() }}>
                        <BiPlusCircle />
                    </button></div>}
                </div>
            </div>
            <Collapse className={'border border-light border-top-0 ' + (open ? 'rounded-bottom' : '')}
                      in={open}>
                <div>
                    <CostGroupTable items={model.items} units={units} onDelete={handleDelete} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
                </div>
            </Collapse>
        </div>
    )
})


export interface CostGroupTableProps {
    items: ComplexCostItem[]
    units: UnitOption[]
    onDelete?: (id: string) => void
    useCommaAsDecimalPoint: boolean
}

const CostGroupTable = observer(({ items, units, onDelete, useCommaAsDecimalPoint }: CostGroupTableProps) =>
    <table className='table-sm w-100 m-0'>
        <tbody>
            {items.map(item =>
                <tr key={item.id}>
                    <td>
                        <FormControl value={item.name}
                                     onChange={ev => item.setName(ev.target.value)}/>
                    </td>
                    <td className='position-relative'>
                        <NumberInput useCommaAsDecimalPoint={useCommaAsDecimalPoint} value={item.value}
                                     onChange={v => item.setValue(v)}/>
                    </td>
                    <td>
                        <UnitsSelector options={units}
                                       value={item.unit}
                                       onValueChanged={v => item.setUnit(v)}/>
                    </td>
                    <td className='align-middle'>
                        <ButtonDelete onClick={() => onDelete?.(item.id)}/>
                    </td>
                </tr>)}
        </tbody>
    </table>)


export interface UnitOption {
    units: CostUnit
    label: string
}


export interface UnitOptionSet {
    capital: UnitOption[]
    operating: UnitOption[]
    replacement: UnitOption[]
}


export var defaultUnits: UnitOptionSet = {
    capital: [{label: '$', units: 'Cost'}],
    operating: [{label: '$/yr', units: 'CostPerYear'}],
    replacement: [{label: '$', units: 'Cost'}],
}


export var storageUnits: UnitOptionSet = {
    capital: [
        {label: '$', units: 'Cost'},
        {label: '% direct', units: 'PercentOfDirectCapital'},
        {label: '$/kW', units: 'CostPerKw'},
        {label: '$/kWh', units: 'CostPerKwh'},
        {label: '$/unit', units: 'CostPerUnit'},
    ],
    operating: [
        {label: '$/yr', units: 'CostPerYear'},
        {label: '% direct/yr', units: 'PercentOfDirectCapitalPerYear'},
        {label: '$/kW capacity-yr', units: 'CostPerKwYear'},
        {label: '$/MWh throughput', units: 'CostPerMwhThroughput'},
        {label: '$/kWh throughput', units: 'CostPerKwhThroughput'},
        {label: '$/kWh capacity-yr', units: 'CostPerKwhYear'},

    ],
    replacement: [
        {label: '$', units: 'Cost'},
        {label: '$/kWh', units: 'CostPerKwh'},
    ],
}


export var solarUnits: UnitOptionSet = {
    capital: [
        {label: '$', units: 'Cost'},
        {label: '% direct', units: 'PercentOfDirectCapital'},
        {label: '$/Wdc', units: 'CostPerWattDc'},
    ],
    operating: [
        {label: '$/yr', units: 'CostPerYear'},
        {label: '% direct/yr', units: 'PercentOfDirectCapitalPerYear'},
        {label: '$/kWdc-yr', units: 'CostPerKwDcYear'},
        {label: '$/MWh-ac', units: 'CostPerMwhAc'},
        {label: '$/kWh-ac', units: 'CostPerKwhAc'},
    ],
    replacement: [
        {label: '$', units: 'Cost'},
        {label: '$/Wdc', units: 'CostPerWattDc'},
        {label: '% direct', units: 'PercentOfDirectCapital'},
    ],
}

export var windUnits: UnitOptionSet = {
    capital: [
        { label: '$', units: 'Cost' },
        { label: '$/unit', units: 'CostPerUnit' },
        { label: '% direct', units: 'PercentOfDirectCapital' },
        { label: '$/kW', units: 'CostPerKw' },
    ],
    operating: [
        { label: '$/yr', units: 'CostPerYear' },
        { label: '$/unit-yr', units: 'CostPerUnitYear' },
        { label: '% direct/yr', units: 'PercentOfDirectCapitalPerYear' },
        { label: '$/kW capacity-yr', units: 'CostPerKwYear' },
        { label: '$/kWh-yr', units: 'CostPerKwhYear' },
    ],
    replacement: [
        { label: '$', units: 'Cost' },
        { label: '$/unit', units: 'CostPerUnit' },
        { label: '% direct', units: 'PercentOfDirectCapital' },
        { label: '$/kW', units: 'CostPerKw' },
    ],
}

interface UnitsSelectorProps {
    options: UnitOption[]
    value: CostUnit
    onValueChanged: (x: CostUnit) => void
}

const UnitsSelector = ({options, value, onValueChanged}: UnitsSelectorProps) => {
    const label = options.find(x => x.units === value)?.label
    const error = label === undefined
    return (
        <Dropdown>
            <Dropdown.Toggle variant={!error ? 'outline-primary' : 'outline-danger'}
                className='dropdown-selector-toggle d-flex align-items-center justify-content-between pillButton' style={{width: '120px'}}>
                <span className='dropdown-selector-label text-truncate'>{label ?? '?'}</span>
            </Dropdown.Toggle>
            <Dropdown.Menu className='dropdown-selector-menu'>
                {options.map(option =>
                    <Dropdown.Item key={option.units}
                                   className={option.units === value ? 'active' : ''}
                                   onClick={() => onValueChanged(option.units)}>
                        {option.label}
                    </Dropdown.Item>)}
            </Dropdown.Menu>
        </Dropdown>
    )
}

export function ChangeUnitOptionSetCurrency({ ...unitOptionSet }: UnitOptionSet, currency: string): UnitOptionSet{
    for (let [key, value] of Object.entries(unitOptionSet.capital)) {
        if (value != undefined) {
            var unitOptionAux: UnitOption = value;
            value.label = unitOptionAux.label.replace("$", currency)
        }
    }
    for (let [key, value] of Object.entries(unitOptionSet.operating)) {
        if (value != undefined) {
            var unitOptionAux: UnitOption = value;
            value.label = unitOptionAux.label.replace("$", currency)
        }
    }
    for (let [key, value] of Object.entries(unitOptionSet.replacement)) {
        if (value != undefined) {
            var unitOptionAux: UnitOption = value;
            value.label = unitOptionAux.label.replace("$", currency)
        }
    }
    return unitOptionSet
}
