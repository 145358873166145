import api, { ComponentOperationResponse, StorageSpec } from 'services/api'
import { GoPlus } from 'react-icons/all'
import { RouteComponentProps } from '@reach/router'
import './UserLibraryView.scss'
import { Button, Modal, Spinner } from 'react-bootstrap'
import ContextHelp from 'components/helper/ContextHelp'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import envVariables from 'env-variables.json'
import { IntlProvider, LocalizationProvider } from 'Kendo-Intl-5'
import UserStorageLibraryTableListView from './UserStorageLibraryTableListView'
import { ChangeEvent, useEffect, useState } from 'react'

interface UserLibraryViewProps extends RouteComponentProps { }


const UserStorageLibraryView = (_: UserLibraryViewProps) => {
    const intlCulture = api.defaultDecimalPoint ? 'es' : 'en'
    const [operationResult, setOperationResult] = useState<ComponentOperationResponse | null>(null)

    const refresh = async () => {
        const rv = await api.listUserStorageSpecs()
        setUserStorageList(rv)
    }

    const handleCopy = async (id: string) => {
        setOperation(Operation.Copy)
        var response = await api.duplicateUserStorage(id) as ComponentOperationResponse
        setOperationResult(response)
        setShowFeedback(true)
    }

    const handleDownload = async (id: string) => await api.downloadUserStorage(id)

    const handleClose = async () => {
        setShowFeedback(false)
        refresh()
    }
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
    const [projectIdToDelete, setProjectIdToDelete] = useState<string | null>(null)
    const handleDelete = async (id: string) => {
        setOperation(Operation.Delete)
        setProjectIdToDelete(id)
        setShowDeleteConfirmation(true)
    }
    const handleConfirmDelete = async () => {
        setShowDeleteConfirmation(false)
        if (!projectIdToDelete) { return }
        const response = await api.deleteUserStorage(projectIdToDelete) as ComponentOperationResponse
        setOperationResult(response)
        setShowFeedback(true)
    }
    const handleCancelDelete = () => setShowDeleteConfirmation(false)
    const [showFeedback, setShowFeedback] = useState(false)
    const handleConfirmSuccess = () => {
        setShowFeedback(false)
        refresh()
    }
    const [operation, setOperation] = useState(Operation.Copy)

    const appInsights = new ApplicationInsights({
        config: {
            connectionString: envVariables[process.env.NODE_ENV].appInsightsConnString
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();

    const [userStorageList, setUserStorageList] = useState<StorageSpec[]>([])
    useEffect(() => {
        refresh()
    }, [])

    const handleImportStorage = async (data: string) => {
        setOperation(Operation.Import)
        const response = await api.importStorageFromFile(data) as ComponentOperationResponse
        setOperationResult(response)
        setShowFeedback(true)
    }

    const hanleAddFromSample = async () => {
        setOperation(Operation.Import)
        const response = await api.addStorageFromSample() as ComponentOperationResponse
        setOperationResult(response)
        setShowFeedback(true)
    }

    return (
        <LocalizationProvider language={intlCulture} >
            <IntlProvider locale={intlCulture}>
                <div className='d-flex flex-column h-100'>
                    <ActionBar handleAddFromSample={hanleAddFromSample} handleImportStorage={handleImportStorage} />
                    <div className='py-2' />
                    <div className='bg-white h-100 rounded-lg shadow-page overflow-auto'>
                        <UserStorageLibraryTableListView model={userStorageList} onDelete={handleDelete} onCopy={handleCopy} onDownload={handleDownload} />
                    </div>
                </div>
                <DeleteConfirmationModal show={showDeleteConfirmation} onConfirm={handleConfirmDelete} onCancel={handleCancelDelete} />
                <FeedbackModal show={showFeedback} operation={operation} onConfirm={handleConfirmSuccess} onClose={handleClose} result={operationResult} />
            </IntlProvider>
        </LocalizationProvider>
    )
}

export default UserStorageLibraryView

interface ActionBarListProps extends RouteComponentProps {
    handleImportStorage: (data: string) => void
    handleAddFromSample: () => void
}

const ActionBar = ({ handleImportStorage, handleAddFromSample }: ActionBarListProps) => {
    const [show, setShow] = useState<boolean>(false)

    const appInsights = new ApplicationInsights({
        config: {
            connectionString: envVariables[process.env.NODE_ENV].appInsightsConnString
        }
    });
    appInsights.loadAppInsights();

    const onSelectFile = async (ev: ChangeEvent<HTMLInputElement>) => {
        const files = ev.target.files
        const file = files![0]
        const data = await readFile(file)
        handleImportStorage(data)
    }

    const readFile = (file: File): Promise<string> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onerror = reject
            reader.onload = () => {
                const result = reader.result as string
                resolve(result)
            }
            reader.readAsText(file)
        })
    }

    return (
        <div className='d-flex flex-row justify-content-between'>
            <div className='d-flex flex-row align-content-center'>
                <span className='page-title mr-2'>Storage Library Listing</span>
                <ContextHelp helpId='STORAGEUSERLIBRARY' />
            </div>

            <div className='d-flex flex-row align-content-center'>
                <Spinner hidden={!show} animation='border' className='text-primary loader-spinner mr-3' />
                <button id='addStorageFromSample' className='btn btn-primary d-flex align-items-center mr-2 pillButton' onClick={handleAddFromSample}>
                    <GoPlus className='mr-2' />
                    Add From Sample
                </button>
                <button id='importStorageFromFile' className='btn btn-primary btn-file d-flex align-items-center mr-2 pillButton' type='button'>
                    <GoPlus className='mr-2' />
                    Import Storage File
                    <input type='file' onChange={onSelectFile} />
                </button>
            </div>
        </div>
    )
}

interface DeleteConfirmationModalProps {
    show: boolean
    onCancel?: () => void
    onConfirm?: () => void
}

const DeleteConfirmationModal = ({ show, onCancel, onConfirm }: DeleteConfirmationModalProps) => {
    const [visible, setVisible] = useState(show)
    useEffect(() => { setVisible(show) }, [show])

    const handleCancel = () => {
        setVisible(false)
        onCancel?.()
    }

    const handleConfirm = () => {
        setVisible(false)
        onConfirm?.()
    }

    return (
        <Modal show={visible} onHide={handleCancel} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title>Delete</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure? Storage Items that are deleted cannot be restored.</Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' variant='secondary' onClick={handleCancel}>Cancel</Button>
                <Button className='pillButton' variant='primary' onClick={handleConfirm}>Continue</Button>
            </Modal.Footer>
        </Modal>
    )
}

export enum Operation {
    Copy,
    Delete,
    Download,
    Import,
    Update
}

export enum Item {
    Project,
    UserStorage,
}

interface ImportFeedbackModalProps {
    show: boolean
    operation: Operation
    onConfirm?: () => void
    onClose: () => void
    result: ComponentOperationResponse | null
}

const FeedbackModal = ({ show, operation, onConfirm, onClose, result }: ImportFeedbackModalProps) => {
    var className = result && result.success ? 'alert alert-success' : 'alert alert-danger'
    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header>
                    <Modal.Title>{Operation[operation]}</Modal.Title>
                </Modal.Header>
                <Modal.Body className={className} >
                    <div className='m-2'>
                        {result && <>
                            <span>{result.success ? 'Operation succeeded!' : 'Operation failed.'}</span>
                            {!result.success && <span><br />Error: <strong>{result.message}</strong></span>}
                            {result.component && result.component.name && operation === Operation.Import && <div>New component was created: <strong>{result.component.name}</strong></div>}
                            {result.component && result.component.name && operation === Operation.Update && <div>Existing component was updated: <strong>{result.component.name}</strong></div>}
                        </>}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='pillButton' variant='secondary' onClick={onClose}>OK</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

