import { BusKind, DateFormat, Project } from 'components/project/model'
import { WindTurbineResult } from 'components/project/results-details'
import { IntlService } from 'Kendo-Intl-5'
import ContextHelp from 'components/helper/ContextHelp'
import { useProjectStore } from 'components/project/ProjectProvider'

interface ResultWindMetricTableProps {
    result: WindTurbineResult
    model: Project
    intlCulture: string
    dateFormat: number
}

interface RowProps {
    label: string
    value: string
}

const Row = ({ label, value }: RowProps) => {
    return (
        <tr className='font-size-14'>
            <td>{label}</td>
            <td>{value}</td>
        </tr>)
}

const ResultWindMetricTable = ({ result, model, intlCulture, dateFormat }: ResultWindMetricTableProps) => {
    const intl = new IntlService(intlCulture)
    const dateFormatArray = Object.values(DateFormat)
    const currency = useProjectStore().project!.currencySymbol ?? "$"

    return (<>
        <div className="d-flex align-items-center justify-content-between">
            <h5 className='font-size-16 font-weight-bold'>Project Metrics</h5>
            <div className='ml-1 mb-1'>
                <ContextHelp helpId='WINDMETRICS' />
            </div>
        </div>
        <table className='table table-bordered table-responsive-sm small'>
            <tbody>
                <Row label='Notice to Proceed' value={intl.formatDate(model.noticeToProceed, dateFormatArray[dateFormat])} />
                <Row label='Expected Commercial Operational Date' value={intl.formatDate(model.expectedDate, dateFormatArray[dateFormat])} />
                <Row label='Generator Nameplate' value={`${intl.formatNumber(result?.nameplateCapacity ?? 0, 'n')} MW`} />

                <Row label='Capital Expenses' value={(currency) + intl.formatNumber((result?.capitalExpenses ?? 0),'n')} />
                <Row label='Useful Life' value={intl.formatNumber(model.lifetime ?? 0, 'n')} />
            </tbody>
        </table>
    </>)
}

export default ResultWindMetricTable
