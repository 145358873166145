import {useState} from 'react'
import './ScheduleGridView.scss'
import {fillSchedule} from 'components/project/model/Schedule'


export type EditMode = 'all' | 'weekday' | 'weekend'


interface ScheduleTimeGridProps {
    value: number[][]
    onChange: (x: number[][]) => void

    colors: string[]
    mode: EditMode
    marker: number
}


const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
const HOURS = Array(24).fill(true).map((x, index) => index)


const ScheduleTimeGrid = ({value, onChange, colors, mode, marker}: ScheduleTimeGridProps) => {
    const [isDragging, setIsDragging] = useState(false)
    const [startPos, setStartPos] = useState<Pos>({month: 0, hour: 0})
    const [data, setData] = useState<number[][]>(value)

    const updateData = (startPos: Pos, currentPos: Pos) => {
        const weekday = mode === 'all' || mode === 'weekday'
        const weekend = mode === 'all' || mode === 'weekend'
        const clone = value.map(x => [...x])
        fillSchedule(clone, marker,
            startPos.month, currentPos.month,
            startPos.hour, currentPos.hour,
            weekday, weekend)
        setData(clone)
    }

    const handleMouseDown = (p: Pos) => {
        setIsDragging(true)
        setStartPos(p)
        updateData(p, p)
    }

    const handleMouseMove = (p: Pos) => {
        if (!isDragging) return
        updateData(startPos, p)
    }

    const handleMouseUp = (p: Pos) => {
        if (!isDragging) return
        setIsDragging(false)
        onChange(data)
    }

    const activeValue = isDragging ? data : value

    return (
        <div>
            <svg width={(SCALE_W * 7 + BORDER_SIZE) * MONTHS.length + 2 + HOUR_AXIS_SIZE} height={(SCALE_H + BORDER_SIZE) * HOURS.length + 2 + MONTH_AXIS_SIZE}>
                <g style={{transform: `translate(${1 + HOUR_AXIS_SIZE}px,${1}px)`}}>
                    <MonthAxis/>
                </g>
                <g style={{transform: `translate(${1}px,${MONTH_AXIS_SIZE + SCALE_H - 1}px)`}}>
                    <HourAxis/>
                </g>
                <g style={{transform: `translate(${1 + HOUR_AXIS_SIZE}px,${1 + MONTH_AXIS_SIZE}px)`}} onMouseLeave={() => handleMouseUp({month: 0, hour: 0})}>
                    <rect width={(SCALE_W * 7 + BORDER_SIZE) * MONTHS.length} height={(SCALE_H + BORDER_SIZE) * HOURS.length}
                          style={{fill: 'white'}}/>
                    {MONTHS.map((monthName, month) => {
                        return HOURS.map(hour => {
                            const weekday = activeValue[2 * month][hour]
                            const weekend = activeValue[2 * month + 1][hour]
                            return (
                                <Cell key={`${month}-${hour}`}
                                      month={month} hour={hour}
                                      weekday={weekday} weekend={weekend}
                                      colors={colors} mode={mode}
                                      onDown={handleMouseDown} onUp={handleMouseUp} onMove={handleMouseMove}/>)
                        })
                    })}
                </g>
            </svg>
        </div>
    )
}


export default ScheduleTimeGrid


const SCALE_W = 5
const SCALE_H = 9
const BORDER_SIZE = 1


const MONTH_AXIS_SIZE = 15
const HOUR_AXIS_SIZE = 35


interface Pos {
    month: number,
    hour: number
}

interface CellProps {
    month: number
    hour: number

    colors: string[]
    mode: EditMode
    weekday: number
    weekend: number

    onDown?: (x: Pos) => void
    onUp?: (x: Pos) => void
    onMove?: (x: Pos) => void
}


const getWidthByMode = (mode: EditMode) => {
    switch (mode) {
        case 'all':
            return SCALE_W * 7 + 2 * BORDER_SIZE
        case 'weekday':
            return SCALE_W * 5 + 2 * BORDER_SIZE
        case 'weekend':
            return SCALE_W * 2 + 2 * BORDER_SIZE
    }
}

const getPosByMode = (mode: EditMode) => {
    switch (mode) {
        case 'all':
            return -1
        case 'weekday':
            return -1
        case 'weekend':
            return SCALE_W * 5 - 1
    }
}

const Cell = ({month, hour, colors, mode, weekday, weekend, onDown, onUp, onMove}: CellProps) => {
    const [over, setOver] = useState(false)

    const w = SCALE_W * 7
    const h = SCALE_H
    const x = (w + BORDER_SIZE) * month
    const y = (h + BORDER_SIZE) * hour

    return (
        <g style={{transform: `translate(${x}px,${y}px)`}}
           onMouseEnter={() => setOver(true)}
           onMouseLeave={() => setOver(false)}
           onMouseDown={() => onDown?.({month, hour})}
           onMouseUp={() => onUp?.({month, hour})}
           onMouseMove={() => onMove?.({month, hour})}>
            <title>
                {MONTHS[month]} {hour + `:00`}
            </title>
            {over && <rect width={getWidthByMode(mode)} height={SCALE_H + 2 * BORDER_SIZE} x={getPosByMode(mode)} y={-1}
                           style={{fill: 'black'}}/>}
            <rect width={SCALE_W * 5 - 1} height={SCALE_H} x={0}
                  style={{fill: colors[weekday]}}/>
            <rect width={SCALE_W * 2} height={SCALE_H} x={SCALE_W * 5}
                  style={{fill: colors[weekend]}}/>
        </g>
    )
}


const MonthAxis = () => {
    return (
        <g>
            {MONTHS.map((monthName, month) => {
                const x = (SCALE_W * 7 + BORDER_SIZE) * month + 1
                return (<text key={monthName} x={x} y={MONTH_AXIS_SIZE - 3} width={SCALE_W * 7 + 1} className='text-monospace month-axis-label'>{monthName}</text>)
            })}
        </g>
    )
}


const HourAxis = () => {
    return (
        <g>
            {HOURS.map((hour) => {
                const y = (SCALE_H + BORDER_SIZE) * hour + 1
                const t = String(hour).padStart(2, '0') + ':00'
                return (<text key={hour} x={SCALE_W * 7 - 3} y={y} width={1} className='text-monospace hour-axis-label'>{t}</text>)
            })}
        </g>
    )
}
