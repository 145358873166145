import { observer } from 'mobx-react-lite'
import { useProjectStore } from '../ProjectProvider'
import { Button, Dropdown, Form, Modal } from 'react-bootstrap'
import { RouteComponentProps } from '@reach/router'
import { DatePicker } from '@progress/kendo-react-dateinputs'
import { allElectricityMarkets, allCurrencies, DateFormatLabel, DateFormat, Project } from 'components/project/model'
import { useEffect, useState } from 'react'
import NumberInput from 'components/helper/NumberInput'
import { Validation } from 'components/helper/validation'
import ContextHelp from 'components/helper/ContextHelp'
import LocationMapView from 'components/project/pages/SharedComponents/LocationMapView'
import LocationAddressInput from 'components/project/pages/SharedComponents/LocationAddressInput'
import { roundToDay } from 'utils'
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import variables from 'env-variables.json';
import { Schedule } from '../model/Schedule'
import ScheduleEditor from '../capacityMarket/ScheduleEditor'
import { UnlockProjectModal } from '../ProjectView'
interface SetupPageProps extends RouteComponentProps { }

const SetupPage = (_: SetupPageProps) => {
    const store = useProjectStore()
    const project = store.project!
    const dateFormatLabelArray = Object.values(DateFormatLabel)
    const dateFormatArray = Object.values(DateFormat)

    useEffect(() => {
        const appInsights = new ApplicationInsights({
            config: {
                connectionString: variables[process.env.NODE_ENV].appInsightsConnString
            }
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView();
    }, [])

    const onSelectEnergyMarket = (value: boolean) => { project.setUseEnergyMarket(value) }
    const onSelectRegulationMarket = (value: boolean) => { project.setUseRegulationMarket(value) }
    const onSelectCapacityMarket = (value: boolean) => { project.setUseCapacityMarket(value) }
    const onSelectContract = (value: boolean) => { project.setUseContract(value) }

    const isValidDateRange = roundToDay(project.noticeToProceed) <= roundToDay(project.expectedDate)

    const handleLatChange = async (newLat: number) => {
        const oldLng = project.location.pos.lng
        await project.location.updatePos({ lat: newLat, lng: oldLng })
    }

    const handleLngChange = async (newLng: number) => {
        const oldLat = project.location.pos.lat
        await project.location.updatePos({ lat: oldLat, lng: newLng })
    }

    const handleSelectSolar = async (value: boolean) => await project.setUseSolar(value)
    const handleSelectWind = async (value: boolean) => await project.setUseWind(value)
    const handleSelectStorage = async (value: boolean) => await project.setUseStorage(value)
    const handleUpdateAdress = async () => {
        await project.location.updateCountry()
    }
    const handleCloseReadOnlyPopup = async () => {
        await store.updateShowReadOnlyPopup(false)
    }
    return (<>
        <div className='d-flex h-100'>
            <div className='w-50'>
                <LocationMapView location={project.location} />
            </div>
            <div className='w-50 p-4 overflow-auto' style={{ height: 'calc(100vh - 173px)' }}>
                <div className='d-flex mb-3'>
                    <h4 className='mr-2'>Project</h4>
                    <ContextHelp helpId='SETUP' />
                </div>
                <div className='form-group row align-items-center'>
                    <label className='col-3'>Name</label>
                    <div className='col-9'>
                        <input className='form-control' type='text'
                            value={project.name}
                            onChange={ev => project.setName(ev.target.value)} />
                    </div>
                </div>
                <div className='form-group row align-items-center'>
                    <label className='col-3'>Author</label>
                    <div className='col-9'>
                        <input className='form-control' type='text'
                            value={project.author}
                            onChange={ev => project.setAuthor(ev.target.value)} />
                    </div>
                </div>
                <div className='form-group row align-items-center'>
                    <label className='col-3'>Notes</label>
                    <div className='col-9'>
                        <textarea className='form-control'
                            value={project.notes}
                            onChange={ev => project.setNotes(ev.target.value)} />
                    </div>
                </div>
                <div className='form-group row align-items-center'>
                    <label className='col-3'>Location</label>
                    <div className='col-9'>
                        <LocationAddressInput location={project.location} updateCountry={handleUpdateAdress} />
                    </div>
                </div>
                <div className='form-group row align-items-center'>
                    <label className='col-3'>Coordinates</label>
                    <div className='col-9'>
                        <div className='row'>
                            <div className='col-4'>Latitude</div>
                            <div className='col-3'>Longitude</div>
                            <div className='col-4' />
                            <div className='col-4'>
                                <NumberInput value={project.location.pos.lat} onChange={handleLatChange}
                                    validator={Validation.range(-90, 90)}
                                    useCommaAsDecimalPoint={project.useCommaAsDecimalPoint} id='latitudeInput' />
                            </div>
                            <div className='col-4'>
                                <NumberInput value={project.location.pos.lng} onChange={handleLngChange}
                                    validator={Validation.range(-180, 180)}
                                    useCommaAsDecimalPoint={project.useCommaAsDecimalPoint} id='longitudeInput' />
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                </div>
             
                    <div className='form-group row align-items-center'>
                        <label className='col-3'>Currency</label>
                        <div className='col-9'>
                            <Dropdown drop='down'>
                                <Dropdown.Toggle variant='outline-primary'>
                                    {project.currency}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {allCurrencies.slice(0, 7).map(c =>
                                        <Dropdown.Item key={c.Code} onClick={() => project.setCurrency(c.Code, c.Symbol)}>{c.CountryAndCurrency + ' - ' + c.Code}</Dropdown.Item>)}
                                    <Dropdown.Divider />
                                    {allCurrencies.slice(-allCurrencies.length + 7).map(c =>
                                        <Dropdown.Item key={c.Code} onClick={() => project.setCurrency(c.Code, c.Symbol)}>{c.CountryAndCurrency + ' - ' + c.Code}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <div className='form-group row align-items-center'>
                        <label className='col-3'>Date Format</label>
                        <div className='col-3'>
                            <Dropdown drop='down'>
                                <Dropdown.Toggle variant='outline-primary'>
                                    {dateFormatLabelArray[project.dateFormat]}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {dateFormatLabelArray.map((c, i) =>
                                        <Dropdown.Item key={i} onClick={() => project.setDateFormat(i)}>{c}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <Form.Check className='col-4' custom label='Use Comma as Decimal Point' id='commaAsDecimalPoint'
                            checked={project.useCommaAsDecimalPoint}
                            onChange={ev => project.setUseCommaAsDecimalPoint(ev.target.checked)} />
                    </div>
                <div className='form-group row align-items-center'>
                    <label className='col-3'>Notice to Proceed (optional)</label>
                    <div className='col-9'>
                        <DatePicker format={dateFormatArray[project.dateFormat]} valid={isValidDateRange}
                            value={project.noticeToProceed}
                            onChange={dateVal => dateVal.target.value != null && project.setNoticeToProceed(dateVal.target.value)} />
                    </div>
                </div>
                <div className='form-group row align-items-center'>
                    <label className='col-3'>Expected Commercial Operational Date</label>
                    <div className='col-3'>
                        <DatePicker format={dateFormatArray[project.dateFormat]} valid={isValidDateRange}
                            value={project.expectedDate} onChange={dateVal => dateVal.target.value != null && project.setExpectedDate(dateVal.target.value)} />                        
                    </div>
                    <div className='col-3'>Date is informational only</div>
                </div>
                <div className='form-group row align-items-center'>
                    <label className='col-3'>Project Lifetime (years)</label>
                    <div className='col-3'>
                        <NumberInput value={project.lifetime} onChange={x => project.setLifetime(x)}
                            validator={Validation.range(1, 40, true)}
                            useCommaAsDecimalPoint={project.useCommaAsDecimalPoint} id='lifetimeInput' />
                    </div>
                </div>
                {!project.location.isInternational &&
                    <div className='form-group row align-items-center'>
                        <label className='col-3'>Electricity Market</label>
                        <div className='col-9'>
                            <Dropdown drop='down'>
                                <Dropdown.Toggle variant='outline-primary'>
                                    {project.electricityMarket}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    {allElectricityMarkets.map(v =>
                                        <Dropdown.Item key={v} onClick={() => project.setElectricityMarket(v)}>{v}</Dropdown.Item>)}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>}
                <div className='form-group row align-items-center'>
                    <div className='col-4'>
                        <Form.Check custom label='Apply shift of days in leap years' id='applyShiftDays'
                            checked={project.applyShiftDays}
                            onChange={ev => project.setApplyShiftDays(ev.target.checked)} />
                    </div>
                    <ContextHelp helpId='SHIFTDAYS' />
                </div>
                <div className='py-4' />

                <div className='d-flex mb-3 justify-content-start'>
                    <h5 className='mr-2'>Application</h5>
                    <ContextHelp helpId='APPLICATION' />
                </div>
                <div className='form-group row align-items-center'>
                    <div className='col-4'>
                        <Form.Check custom label='Early-stage analysis' id='early' type='radio'
                            checked={project.earlyAnalysis}
                            onChange={ev => { project.setEarlyAnalysis(ev.target.checked ? true : false) }} />
                    </div>
                    <div className='col-4'>
                        <Form.Check custom label='Late-stage analysis' id='late' type='radio'
                            checked={!project.earlyAnalysis}
                            onChange={ev => { project.setEarlyAnalysis(ev.target.checked ? false : true) }} />
                    </div>
                </div>
                <div className='py-1' />
                <div className='form-group row'>
                    <div className='col-3'>
                        <Form.Check custom label='Energy Market' id='useEnergyMarket'
                            checked={project.useEnergyMarket}
                            onChange={ev => onSelectEnergyMarket(ev.target.checked)} />
                    </div>
                    <div className='col-3'>
                        <Form.Check custom label='Regulation Market' id='useRegulationMarket'
                            checked={project.useRegulationMarket}
                            onChange={ev => onSelectRegulationMarket(ev.target.checked)} />
                    </div>
                    <div className='col-3'>
                        <Form.Check custom label='Capacity Market' id='useCapacityMarket'
                            checked={project.useCapacityMarket}
                            onChange={ev => onSelectCapacityMarket(ev.target.checked)} />
                    </div>
                    <div className='col-3'>
                        <Form.Check custom label='TOD Contract' id='useContract'
                            checked={project.useContract}
                            onChange={ev => onSelectContract(ev.target.checked)} />
                    </div>
                </div>
                <div className='d-flex mb-3 justify-content-start'>
                    <h5 className='mr-2'>Equipment</h5>
                    <ContextHelp helpId='EQUIPMENT' />
                </div>
                <div className='form-group row'>
                    <div className='col-4'>
                        <Form.Check custom label='Solar' id='useSolar'
                            checked={project.useSolar}
                            onChange={ev => handleSelectSolar(ev.target.checked)} />
                    </div>
                    <div className='col-4'>
                        <Form.Check custom label='Wind' id='useWind'
                            checked={project.useWind}
                            onChange={ev => handleSelectWind(ev.target.checked)} />
                    </div>
                    <div className='col-4'>
                        <Form.Check custom label='Storage' id='useStorage'
                            checked={project.useStorage}
                            onChange={ev => handleSelectStorage(ev.target.checked)} />
                    </div>
                </div>
            </div>
        </div>
        <UnlockProjectModal show={store.showReadOnlyPopup} onClose={handleCloseReadOnlyPopup} />
        </>
        
    )
}

export default observer(SetupPage)

interface ShareProjectModalProps {
    show: boolean
    project: Project
    handleScheduleChange: (x: Schedule) => void
    hideError: boolean
    setHideError?: (show: boolean) => void
    onConfirm?: (email: string, message: string) => void
    onCancel?: () => void
}

const SchedulePopup = ({ show, project, handleScheduleChange, onConfirm, onCancel }: ShareProjectModalProps) => {
    const [email, setEmail] = useState('')
    useEffect(() => { setEmail('') }, [show])

    const [description, setDescription] = useState('')
    useEffect(() => { setDescription('') }, [show])

    const [hideAlert, setHideAlert] = useState(true)

    const onCancelShare = () => {
        setHideAlert(true)
        //setHideError?.(true)
        onCancel?.()
    }

    const onConfirmShare = (email: string, description: string) => {
        if (email == undefined || email == '')
            setHideAlert(false)
        else {
            setHideAlert(true)
            onConfirm?.(email, description)
        }
    }

    return (
        <Modal show={show} onHide={onCancelShare}>
            <Modal.Header closeButton onHide={onCancelShare}>
                Share
            </Modal.Header>
            <Modal.Body>
                <div className='form-group row align-items-center'>
                    <ScheduleEditor value={project.exportLimitSchedule.schedule} onChange={handleScheduleChange} id={project.id} radioLabel1="Aaaa" radioLabel2="Aaaaa" />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={onCancelShare} variant='outline-primary'>Cancel</Button>
                <Button className='pillButton' onClick={() => onConfirmShare(email, description)}>OK</Button>
            </Modal.Footer>
        </Modal>
    )
}
