import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import React, {useState} from 'react'
import DetailCostBreakup, {defaultUnits, UnitOptionSet} from './DetailCostBreakup'
import {CostTable, CostTableType} from 'components/project/model/CostTable'
import {observer} from 'mobx-react-lite'
import Modal from 'react-bootstrap/Modal'
import SensitivityView from './SensitivityView'
import Button from 'react-bootstrap/Button'
import NumberField from 'components/helper/NumberField'
import SensitivityVarIcon from 'components/project/pages/EnergyMarket/SensitivityVarIcon'
import ContextHelp from 'components/helper/ContextHelp'
import { useProjectStore } from 'components/project/ProjectProvider'


interface CostTableProps {
    cost: CostTable
    id: string

    simpleCostLabel: string
    complexCostLabel: string

    showDetailedCostTable?: boolean
    unitsSet?: UnitOptionSet

    showCapital?: boolean // default `true`
    showOperating?: boolean // default `true`
    showReplacement?: boolean // default `false`
    showAugmentation?: boolean // default `false`

    capitalLabel?: string // default `Capital Expenses`
    operatingLabel?: string // default `Operating Expenses`
    replacementLabel?: string // default `Replacement`
    augmentationLabel?: string // default `Augmentation`

    capitalUnits?: string // default `$/kW`
    operatingUnits?: string // default `$/kW-yr`
    replacementUnits?: string // default `$/kW`
    augmentationUnits?: string // default `$/kW`

    showDetailedReplacement?: boolean
    isStorage?: boolean
    isWind?: boolean
    type?: string

    useCommaAsDecimalPoint: boolean

    hideSensitivity?: boolean
    hideHelp?: boolean
}


const CostTableView = observer(({
                                    id, cost, simpleCostLabel, complexCostLabel, showDetailedCostTable, unitsSet,
                                    showCapital, showOperating, showReplacement, showAugmentation,
                                    capitalLabel, operatingLabel, replacementLabel, augmentationLabel,
                                    capitalUnits, operatingUnits, replacementUnits, augmentationUnits,
                                    showDetailedReplacement, isStorage,
                                    isWind, type, useCommaAsDecimalPoint, hideSensitivity = false, hideHelp = false
}: CostTableProps) => {

    const [show, setShow] = useState(false)
    const handleOpen = () => setShow(true)
    const handleClose = () => setShow(false)
    const project = useProjectStore().project
    const currency = useProjectStore().project?.currencySymbol ?? "$"

    return (<>
        <div className='pb-3'>
            <div className='d-flex mb-2 justify-content-start'>
                <h6 className='mt-2 mr-2'>Cost</h6>
                {!hideHelp && <ContextHelp helpId={isWind ? 'WINDCOST' : (isStorage ? 'STCOST' : 'COST')} />}
            </div>

            {showDetailedCostTable &&
                <Form.Row>
                    <Form.Group as={Col} sm={5} controlId='simple-cost'>
                        <Form.Check
                            name={`cost_breakdown_options_${id}`}
                            id={`simple_cost_${id}`}
                            type='radio' custom
                            label={simpleCostLabel}
                            checked={cost.costTableType === CostTableType.simple}
                            onChange={ev => ev.target.value && cost.setType(CostTableType.simple)}/>
                    </Form.Group>

                    <Form.Group as={Col} sm={4} controlId='complex-cost'>
                        <Form.Check
                            name={`cost_breakdown_options_${id}`}
                            id={`complex_cost_${id}`}
                            type='radio'
                            label={complexCostLabel}
                            onChange={ev => ev.target.value && cost.setType(CostTableType.complex)}
                            checked={cost.costTableType === CostTableType.complex}
                            custom/>
                    </Form.Group>
                </Form.Row>}

            {cost.costTableType === CostTableType.simple && <>
                {(showCapital ?? true) &&
                    <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label={`${capitalLabel ?? 'Capital Expenses'} (${capitalUnits ?? currency + '/kW'})`}
                                 value={cost.simple.base.capital}
                                 onChange={x => {cost.simple.base.setCapital(x);if(type){project?.setIsNewSystemConverter(false)}}}/>}
                {(showOperating ?? true) &&
                    <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label={`${operatingLabel ?? 'Operating Expenses'} (${operatingUnits ?? currency + '/kW-yr'})`}
                                 value={cost.simple.base.operating}
                                 onChange={x => {cost.simple.base.setOperating(x);if(type){project?.setIsNewSystemConverter(false)}}}/>}
                {(showReplacement ?? false) &&
                    <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label={`${replacementLabel ?? 'Replacement'} (${replacementUnits ?? currency + '/kW'})`}
                                 value={cost.simple.base.replacement}
                                 onChange={x => {cost.simple.base.setReplacement(x);if(type){project?.setIsNewSystemConverter(false)}}}/>}
                {(showAugmentation ?? false) &&
                    <NumberField useCommaAsDecimalPoint={useCommaAsDecimalPoint} label={`${augmentationLabel ?? 'Augmentation'} (${augmentationUnits ?? currency + '/kW'})`}
                                 value={cost.simple.base.augmentation}
                                 onChange={x => cost.simple.base.setAugmentation(x)}/>}

                <div className='form-group row align-items-center'>
                    <div className='offset-5 col-3 text-center'>
                        <SensitivityVarIcon hideSensitivity={hideSensitivity}
                                            count={cost.simple.items.length}
                                            onClick={handleOpen}/>
                    </div>
                </div>
            </>}

            {cost.costTableType === CostTableType.complex &&
                <DetailCostBreakup model={cost} unitsSet={unitsSet ?? defaultUnits}
                showReplacement={showDetailedReplacement ?? true} useCommaAsDecimalPoint={useCommaAsDecimalPoint} />}
        </div>

        <Modal show={show} onHide={handleClose} centered size='lg'>
            <Modal.Header>
                <Modal.Title>Cost Sensitivity</Modal.Title>
            </Modal.Header>
            <Modal.Body className='k-overflow-x-auto'>
                <SensitivityView model={cost.simple}
                    showCapital={showCapital} showOperating={showOperating} showReplacement={showReplacement} showAugmentation={showAugmentation}
                    capitalLabel={capitalLabel} operatingLabel={operatingLabel} replacementLabel={replacementLabel} augmentationLabel={augmentationLabel}
                    capitalUnits={capitalUnits} operatingUnits={operatingUnits} replacementUnits={replacementUnits} augmentationUnits={augmentationUnits}
                    useCommaAsDecimalPoint={useCommaAsDecimalPoint} />
            </Modal.Body>
            <Modal.Footer>
                <Button className='pillButton' onClick={handleClose}>Save &amp; Close</Button>
            </Modal.Footer>
        </Modal>
    </>)
})


export default CostTableView
